import React from 'react'
import { Select, SelectProps, Typography, Flex } from 'antd'

const Option = Select.Option
interface TagSelectProps extends SelectProps {
  dropdownItems: SelectProps['options']
  width?: string
  mode?: 'tags' | 'multiple' | undefined
  allowClear?: boolean
  filterBy?: 'label' | 'value'
  placeholder: string
}

export const TagSelect: React.FC<TagSelectProps> = ({
  dropdownItems,
  placeholder,
  width = '200px',
  mode = undefined, // single selection
  allowClear = true,
  filterBy = 'value',
  ...rest
}) => {
  return (
    <>
      <Flex gap='small' vertical>
        <Select
          placeholder={placeholder}
          style={{ width: width }}
          showSearch={false}
          mode={mode}
          optionFilterProp={filterBy}
          allowClear={allowClear}
          maxTagCount={'responsive'}
          {...rest}
        >
          {/* {selectAll ? (
          <Option key='all' value='all'>
            ---SELECT ALL---
          </Option>
        ) : null} */}

          {dropdownItems?.map((item: any, index: number) => (
            <Option key={item.key} value={item.value} label={item.label} data-testid={`${index}-option`}>
              {item.desc ? item.desc : item.label}
            </Option>
          ))}
        </Select>
      </Flex>
    </>
  )
}
