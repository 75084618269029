import { RedoOutlined, UploadOutlined, VideoCameraAddOutlined } from '@ant-design/icons'
import { useGetGettyImageVideos } from '../../../../api/gettyImages/getGettyImageVideo'
import { useGetGettyImages } from '../../../../api/gettyImages/getGettyImages'
import { useGetPexelsVideos } from '../../../../api/pexels/getPexelsVideo'
import { useGetPixabayVideos } from '../../../../api/pixaby/getPixabayVideo'
import { useGetStoryblocksVideos } from '../../../../api/storyblocks/useGetStoryblocksVideo'
import { useGetAssets } from '../../../../api/useGetAssets'
import { PEXELS_KEY, PIXABY_KEY, IS_FREE_PLATFORM } from '../../../../config'
import { qaBackendApiInstance, getDomainID } from '../../../../config/config'
import { useGlobalContext } from '../../../../context/globalContext'
import { Button, Input, Tabs, message, Collapse } from 'antd'
import TabPane from 'antd/es/tabs/TabPane'
import { observer } from 'mobx-react-lite'
import { SectionTab } from 'polotno/side-panel'
import { useEffect, useState } from 'react'
import { VideoSearch } from '../videoSearchResult'
import { v4 as uuidv4 } from 'uuid';
import { useGetAiImages } from '../../../../api/useAiImageAssets'
import { generateUniqueId } from '../../../../shared/utils/core'
import { useGetDalleAiImages } from '@src/api/useDalleAssets'
import { Alignment, Button as BlueprintButton, Navbar } from '@blueprintjs/core';
import { aiImagesAPI } from '@src/shared/utils/VideoGeneration/helperFunctions/mediaSearch'
import TextArea from 'antd/es/input/TextArea'
import useUploadLargeAsset from '@src/api/useUploadLargeAsset'
import useGetAssetStatus, { getAssetStatus } from '@src/api/useGetUploadStatus'
import { useFileUploadServer } from '@src/api/videoUpload/useFileUploadServer'

const { Panel } = Collapse;

interface VideoPanelProps {
  store: any
  elementID: string
  index: number
  isNotPanel?: boolean
  searchQueries: string[]
}

function getType(file: any) {
  const { type } = file
  if (type.indexOf('svg') >= 0) {
    return 'svg'
  }
  if (type.indexOf('image') >= 0) {
    return 'image'
  }
  if (type.indexOf('video') >= 0) {
    return 'video'
  }
  if (type.indexOf('audio') >= 0) {
    return 'audio'
  }
  return 'image'
}

export const VideoPanel = observer(({ store, elementID, index, isNotPanel, searchQueries }: VideoPanelProps) => {
  const {
    ScriptDetails: [scriptDetails],
    UserDetail: [userDetails, setUserDetails],
  } = useGlobalContext()
  // console.log(elementID, index, isNotPanel, searchQueries)
  const [searchQueryIndex, setSearchQueryIndex] = useState(0)
  const [imagePage, setImagePage] = useState(1)
  const [videoPage, setVideoPage] = useState(1)
  const [isUploading, setUploading] = useState(false)
  const [images, setImages] = useState<any>([])
  const [videos, setVideos] = useState<any>([])
  const [activeTabKey, setActiveTabKey] = useState('0');
  const [aiImageGenerating, setAiImageGenerating] = useState(false)
  const [taskId, setTaskId] = useState("");
  const [isPollingEnabled, setIsPollingEnabled] = useState(false);
  const {
    uploadImage: uploadToServer,
    loading: upload,
    data:serverData,
    error:uploadError,
  } = useFileUploadServer();
  const [selectedOrientationValue, setSelectedOrientationValue] = useState(
    userDetails?.purpose?.size === '9:16' ? 'vertical' : 'horizontal'
  )
  const [uploadingVideo, setUploadingVideo] = useState(false)

  // const { data:assetStatus, isLoading:isAsseStatusLoading, error:assetStatusError } = useGetAssetStatus(taskId || "",
  //   isPollingEnabled ? 5000 : undefined );

  const { mutate:uploadLargeAsset, isLoading:uploadLargeAssetLoading } = useUploadLargeAsset();
  const [transformedAIImage, setTransformedAIImage] = useState<{ id: string; image_url: any; video_url: any; type: string }[]>([]);
  
  const handleTabChange = (key:any) => {
    
    setActiveTabKey(key);
  };

  const generateAiImage=async()=>{
    setAiImageGenerating(true)
    try{
      const aiImage= await aiImagesAPI(debouncedSearchQuery, selectedOrientationValue, userDetails?.purpose?.aiImagePrefix || '')
      const newImage = {id: generateUniqueId(), image_url:aiImage, video_url:aiImage, type:'image'}
      // transformedAIImage = [{id: generateUniqueId(), image_url:aiImage, video_url:aiImage, type:'image'}]
      // const addImage = (newImage: { id: string; image_url: any; video_url: any; type: string }) => {
        setTransformedAIImage((prevImages) => [...prevImages, newImage]);
      // };
      console.log(aiImage)
    }catch(error){
        console.log(error)
    }finally{
      setAiImageGenerating(false)

    }
   
  }
  async function uploadFile(file: File): Promise<any> {
    const type = getType(file)

    const formData = new FormData()
    formData.append('file', file)
    formData.append('asset_type', type)

    try {
      console.log(type)
      if(type === 'video'){
        setUploadingVideo(true)
        const result = await uploadToServer(file)
       
        setVideos([result, ...videos])
        setUploadingVideo(false)

    
      }else{
        setUploading(true)
        const response = await qaBackendApiInstance.post(
          `/asset/upload?asset_type=${type}&domain_id=${getDomainID()}`,
          formData
        )
        if (response.status === 200) {
          console.log('File uploaded successfully', response.data)
          // setVideos(...videos, response.data)
          setVideos([ ...videos, response.data, ])
        } else {
          message.error('Upload failed with status: ' + response.status)
        }
      }
     
    } catch (error) {
      console.error('Error uploading file:', error)
      message.error('Upload failed.')


    } finally {
      type === 'image' && refetchImages()
      type === 'video' && refetchVideos()
      setUploading(false)
    }
  }

  function getExtension(filename:any) {
    return filename.split('.').pop()
  }
  const handleFileInput = async (e: any) => {

    const { target } = e
    // const files = target.files
    // if (files.length > 0) {
    //   const file = files[0];
    //   const videoUrl = URL.createObjectURL(file);
    //   const image_extensions = [
    //     'jpg', 'jpeg', 'jpe', 'jfif', 
    //     'png',                        
    //     'gif',                        
    //     'bmp', 'dib',                
    //     'tiff', 'tif',                
    //     'webp',                        
    //     'ico',                        
    //     'ppm',                       
    //     'pgm',                       
    //     'pbm',                        
    //     'pnm', 
    //     'svg',                       
       
    // ]
    //   const fileType=getExtension(file.name).toLowerCase()
    //   if (image_extensions.includes(fileType)){
        
    //       const newObj={
    //     id:uuidv4(),
    //     image_url:videoUrl,
    //     video_url: videoUrl,
    //     type: 'image'
    //   }
    //   setVideos((prevVideos:any) => [...prevVideos, newObj]);
    //   } else{
    //   const newObj={
    //     id:uuidv4(),
    //     image_url:videoUrl,
    //     video_url: videoUrl,
    //     type: 'video'
    //   }
    //   setVideos((prevVideos:any) => [...prevVideos, newObj]);
    //   }
      
    // }

    setUploading(true)
    for (const file of target.files) {
      await uploadFile(file)
    }
    setUploading(false)
    target.value = null
  }

  const [searchQuery, setSearchQuery] = useState(
    searchQueries ? searchQueries[0] : scriptDetails.data[0].searchQueries[searchQueryIndex]
  )
  const debouncedSearchQuery = useDebounce<string>(searchQuery, 500) // Debounce search input to reduce API calls

  const { data: pexelsVideos, isLoading } = useGetPexelsVideos(
    PEXELS_KEY,
    debouncedSearchQuery, // Use the debounced query value
    'landscape',
    'medium'
  )

  const {
    data: imageData,
    isLoading: isImagesLoading,
    isError,
    isSuccess: isImagesSuccess,
    error,
    refetch: refetchImages,
  } = useGetAssets(imagePage, 'image')

  const handleImageLoadMore = () => {
    setImagePage(imagePage + 1)
  }

  const handleVideoLoadMore = () => {
    setVideoPage(videoPage + 1)
  }

  // Transform video data to match what ImagesGrid expects
  const transformedPexelVideos = pexelsVideos?.videos.map(
    (video: { id: any; image: any; url: any; video_files: any; }) => {
      const videos = video.video_files.filter((file: { quality: string }) => file.quality === 'sd')
      return {
        id: video.id,
        image_url: video.image,
        video_url: videos[videos.length - 1]?.link,
        type: 'video',
      }
    }
  )

  const { data: pixabyVideo, isLoading: isPixbyLoading } = useGetPixabayVideos(PIXABY_KEY, debouncedSearchQuery)

  const transformedPixabayVideos = pixabyVideo?.hits.flatMap(
    (video: { id: any; videos: { large: { thumbnail: any; url: any } } }) => [
      {
        id: video.id,
        image_url: video.videos.large.thumbnail,
        video_url: video.videos.large.url,
        type: 'video',
      },
    ]
  )

  // use this for our Ai image api
  // const {data: aiImage, isLoading: isAiLoading} = useGetAiImages(debouncedSearchQuery)
  // const transformedAIImage = [{id: generateUniqueId(), image_url: aiImage?.background_image, video_url:aiImage?.background_image, type:'image'}]

  // use this for dalle ai image generation
  // const {data: aiImage, isLoading: isAiLoading} = useGetDalleAiImages(debouncedSearchQuery, selectedOrientationValue)
  
  // const transformedAIImage = [{id: generateUniqueId(), image_url:aiImage, video_url:aiImage, type:'image'}]

  const { data: storyBlocksVideos, isLoading: isSbLoading } = useGetStoryblocksVideos(debouncedSearchQuery)

  const transformedStoryBlocksVideos = storyBlocksVideos?.results.map((video: any) => {
    return {
      id: video.id,
      image_url: video.thumbnail_url,
      video_url: video.preview_urls._480p,
      type: 'video',
    }
  })

  const {
    data: gettyImageVideos,
    isLoading: isGettyImageVideoLoading,
    isSuccess: isGettyImageVideosSuccess,
    page: gettyVideoPage,
    setPage: setGettyVideoPage
  } = useGetGettyImageVideos(debouncedSearchQuery, userDetails?.purpose?.size, selectedOrientationValue, 30, 1, userDetails?.purpose?.videotechniques || [])

  const transformedGettyImageVideos =
    isGettyImageVideosSuccess && gettyImageVideos?.videos
      ? gettyImageVideos.videos.map((video: any) => {
          const videoPreview = video.display_sizes.find((displaySize: { name: string }) => displaySize.name === 'comp')
          const imagePreview = video.display_sizes.find((displaySize: { name: string }) => displaySize.name === 'thumb')

          // Handle cases where find might return undefined
          return {
            id: video.id,
            image_url: imagePreview,
            video_url: videoPreview.uri,
            type: 'video',
          }
        })
      : []

  const {
    data: gettyImages,
    isLoading: isGettyImagesLoading,
    isSuccess: isGettyImagesSuccess,
    page:gettypageNumber,
    setPage:setGettyPageNumber,
   
  } = useGetGettyImages(debouncedSearchQuery, selectedOrientationValue, false, 30, 1)
const handleGettyImageLoadMore=()=>{
  setGettyPageNumber(gettypageNumber+1)
}
const handleGettyImagePrev=()=>{
  setGettyPageNumber(gettypageNumber-1)
}

const handleGettyVideoLoadMore=()=>{
  console.log(gettyVideoPage)
  setGettyVideoPage(gettyVideoPage+1)
}
const handleGettyVideoPrev=()=>{
  setGettyVideoPage(gettyVideoPage-1)
}
const transformedGettyImages = isGettyImagesSuccess && gettyImages
? [
    ...gettyImages.map((image: any) => {
      const videoPreview = image.display_sizes.find((displaySize: { name: string }) => displaySize.name === 'comp');
      const imagePreview = image.display_sizes.find((displaySize: { name: string }) => displaySize.name === 'thumb');

      // Handle cases where find might return undefined
      return {
        id: image.id,
        image_url: imagePreview?.uri || '',
        video_url: videoPreview?.uri || '',
        type: 'image',
      };
    }),
    // Additional transformations if needed
  ]
: [];

  const { data: videosData, refetch: refetchVideos } = useGetAssets(videoPage, 'video')

  useEffect(() => {
    if (imageData && imageData.items && imageData.items.length > 0) {
      setImages([...images, ...imageData.items])
    }
  }, [imageData])
  useEffect(() => {

    if (videosData && videosData.items && videosData.items.length > 0) {
      const imageVideoData: any[] = videosData.items.filter((obj: any) => obj.type !== 'audio');
      
      setVideos([...videos, ...imageVideoData])
    }
  }, [videosData])

  let transformedVideos = videos?.map((video: any) => {
    return {
      id: video.id,
      image_url: video.image_url ? video.image_url : undefined,
      video_url: video.video_url? video.video_url : undefined,
      type: video.type,
    }
  })
useEffect(()=>{

  transformedVideos = videos?.map((video: any) => {
    return {
      id: video.id,
      image_url: video.image_url,
      video_url: video.video_url,
      type: video.type,
    }
  })
}, [videos])

  const transformedImages = images?.map((image: any) => {
    return {
      id: image.id,
      image_url: image.image_url,
      video_url: undefined,
      type: 'image',
    }
  })


  // useEffect(() => {
    
  //   if (assetStatus) {
  //     console.log(assetStatus.status);
  //     if (assetStatus.status === "Done") {
  //       // reFetchAssetsVideoAssets()
  //       setVideos([assetStatus.data, ...videos])
  //       setUploadingVideo(false)
  //       setIsPollingEnabled(false); // Disable polling
  //       setTaskId('')
  //     } else if (assetStatus.status === "Error") {
  //       // Stop polling and show error if the upload failed
  //       message.warning("Failed to upload video");
  //       setIsPollingEnabled(false); // Disable polling
  //       setUploadingVideo(false)
  //       setTaskId('')
  //     }
  //   }
  // }, [assetStatus, videos]);

//   useEffect(() => {
//     let intervalId: NodeJS.Timeout;
//     if (isPollingEnabled) {
//         intervalId = setInterval(async () => {
//             try {
//                 const data = await getAssetStatus(taskId);
//                 console.log("Updated Asset Status:", data);

//                 // Stop polling if task is completed
//                 if (data.status === "Done") {
//                   setVideos([data.data, ...videos])
//                   setUploadingVideo(false)
//                   setIsPollingEnabled(false) // Optionally stop polling when task is done
//                     clearInterval(intervalId);
//                     console.log("Polling stopped as task is completed.");
//                 }
//             } catch (error) {
//                 console.error("Polling error:", error);
//             }
//         }, 5000);
//     }

//     // Clean up on component unmount or if polling stops
//     return () => clearInterval(intervalId);
// }, [isPollingEnabled, taskId]);

//   // Cleanup effect when the taskId changes or the component unmounts
//   useEffect(() => {
//     // Reset polling when taskId changes
//     if (taskId) {
//       setIsPollingEnabled(true); // Start polling
//     } else {
//       setIsPollingEnabled(false); // Stop polling if no taskId
//     }
//     // Cleanup function to stop polling if the component unmounts
//     return () => {
//       setIsPollingEnabled(false);
//       // setUploadingVideo(false)
//       // setTaskId('')

//     };
//   }, [taskId]);



  return (
    <div style={{ height: '90%' }}>
      <div
      style={{
        display:'flex',
        justifyContent:'end',
        position:'relative',
        bottom:'37px',
        right:'17px'
      }}
      >
         {isNotPanel === true && (
            <BlueprintButton
            style={{
              border:'none',
              boxShadow:'none'
            }}
            icon='undo'
              onClick={() => {
                if (searchQueryIndex === searchQueries.length - 1) {
                  setSearchQueryIndex(0)
                } else {
                  setSearchQueryIndex(searchQueryIndex + 1)
                }
                setSearchQuery(searchQueries[searchQueryIndex])
              }}
            />
          )}
      </div>
     
     {activeTabKey == '3' ? (
      <>
     
     
      <div style={{display:'flex', justifyContent:'space-between',}}>
        <Input
        placeholder='Search for AI Image'
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{ marginBottom: 16, marginRight:'4px' }}
       disabled={aiImageGenerating}
       
      />
      <Button
          onClick={()=>generateAiImage()}
          disabled={aiImageGenerating}
          loading={aiImageGenerating}
          style={{ background: 'transparent', borderColor: 'var(--color-primary-500)', color: 'var(--color-primary-500)' }}
        >Generate</Button>
      </div>
      <Collapse ghost style={{padding:'0px', color:'var(--color-primary-500)',}}>
    <Panel header="Prefix for AI Image generation" key="1" style={{ padding:'0px',fontWeight:'bold'}} >
    <TextArea
      placeholder='Enter prefix for AI image generation'
      value={userDetails?.purpose?.aiImagePrefix}
      onChange={(e) => {
        setUserDetails((prevDetails:any) => ({
          ...prevDetails, // spread the previous userDetails
          purpose: {
            ...prevDetails?.purpose, // spread the previous purpose object
            aiImagePrefix: e.target.value, // update the aiImagePrefix property
          },
        }));
      }}
      style={{ marginBottom: 16, marginRight:'4px' }}
     disabled={aiImageGenerating}
     
    />
    </Panel>
    </Collapse>
     
      </>
      ) : (<><Input
        placeholder='Search for videos'
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{ marginBottom: 16 }}
        // suffix={
          // isNotPanel === true && (
          //   <BlueprintButton
          //   icon='undo'
          //     onClick={() => {
          //       if (searchQueryIndex === searchQueries.length - 1) {
          //         setSearchQueryIndex(0)
          //       } else {
          //         setSearchQueryIndex(searchQueryIndex + 1)
          //       }
          //       setSearchQuery(searchQueries[searchQueryIndex])
          //     }}
          //   />
          // )
        // }
      /></>)}
      
      <>
        <Tabs defaultActiveKey={'0'} onChange={handleTabChange}>
          {IS_FREE_PLATFORM === 'true' && (
            <TabPane tab='Getty Images' key='0'>
              <VideoSearch
                transformedElementsArray={transformedGettyImages}
                isLoading={isGettyImagesLoading}
                store={store}
                title={''}
                elementID={elementID}
                isNotPanel={isNotPanel}
                isGettyImage={true}
                dropDownValue={selectedOrientationValue}
                setSelectedValue={setSelectedOrientationValue}
              />
            </TabPane>
          )}
          {IS_FREE_PLATFORM === 'false' && (
            <>
              <TabPane tab='Getty Videos' key='0'>
                <VideoSearch
                  transformedElementsArray={transformedGettyImageVideos}
                  isLoading={isGettyImageVideoLoading}
                  store={store}
                  title={''}
                  elementID={elementID}
                  isGettyImage={true}
                  dropDownValue={selectedOrientationValue}
                  setSelectedValue={setSelectedOrientationValue}
                  isNotPanel={isNotPanel}
                  index={index}
                />
               <div style={{display:'flex', justifyContent:'space-around', gap:'5px' }}>
                  
                  <Button
                  style={{ width: '100%', marginTop: 20 }}
                  onClick={handleGettyVideoPrev}
                  disabled={gettyVideoPage<2}
                  loading={isLoading}
                >
                  Prev
                </Button>
                <Button
                  style={{ width: '100%', marginTop: 20, backgroundColor:'--color-primary-200' }}
                  onClick={handleGettyVideoLoadMore}
                  disabled={isLoading}
                  loading={isLoading}
                >
                  Next
                </Button>
                </div>
              </TabPane>
              
              <TabPane tab='Getty Images' key='1'>
                <VideoSearch
                  transformedElementsArray={transformedGettyImages}
                  isLoading={isGettyImagesLoading}
                  store={store}
                  title={''}
                  elementID={elementID}
                  isNotPanel={isNotPanel}
                  isGettyImage={true}
                  dropDownValue={selectedOrientationValue}
                  setSelectedValue={setSelectedOrientationValue}
                />
                <div style={{display:'flex', justifyContent:'space-around', gap:'5px' }}>
                  
                <Button
                style={{ width: '100%', marginTop: 20 }}
                onClick={handleGettyImagePrev}
                disabled={gettypageNumber<2}
                loading={isLoading}
              >
                Prev
              </Button>
              <Button
                style={{ width: '100%', marginTop: 20, backgroundColor:'--color-primary-200' }}
                onClick={handleGettyImageLoadMore}
                disabled={isLoading}
                loading={isLoading}
              >
                Next
              </Button>
                </div>
                
              </TabPane>
             
              {/* <TabPane tab='Pexel' key='2'>
                <VideoSearch
                  transformedElementsArray={transformedPexelVideos}
                  isLoading={isLoading}
                  store={store}
                  title={''}
                  elementID={elementID}
                  isNotPanel={isNotPanel}
                  index={index}
                />
              </TabPane> */}
             <TabPane tab='AI Images' key='3'> 
             
              <VideoSearch
                  transformedElementsArray={transformedAIImage}
                  isLoading={aiImageGenerating}
                  store={store}
                  title={''}
                  elementID={elementID}
                  isNotPanel={isNotPanel}
                  index={index}
                />
              </TabPane> 
              {/* <TabPane tab='Storyblocks' key='3'>
                <VideoSearch
                  transformedElementsArray={transformedStoryBlocksVideos}
                  isLoading={isSbLoading}
                  store={store}
                  title={''}
                  elementID={elementID}
                  isNotPanel={isNotPanel}
                  index={index}
                />
              </TabPane>
              <TabPane tab='Pixaby' key='4'>
                <VideoSearch
                  transformedElementsArray={transformedPixabayVideos}
                  isLoading={isPixbyLoading}
                  store={store}
                  title={''}
                  elementID={elementID}
                  isNotPanel={isNotPanel}
                  index={index}
                />
              </TabPane> */}
            </>
          )}

          {/* {isNotPanel === true && IS_FREE_PLATFORM === 'false' && ( */}
            <TabPane tab='My Assets' key='5'>
              <Button
                icon={<UploadOutlined />}
                style={{ width: '100%', paddingBottom: 20, marginBottom: 20 }}
                loading={isUploading || uploadingVideo}
                onClick={() => {
                  document.getElementById('input-file')?.click()
                }}
              >
                Upload Asset
              </Button>

              <input
                type='file'
                id='input-file'
                style={{ display: 'none' }}
                onChange={handleFileInput}
                accept='image/*, video/*, .mkv'
                multiple
              />
              <VideoSearch
                transformedElementsArray={transformedVideos}
                isLoading={isImagesLoading}
                store={store}
                title={''}
                elementID={elementID}
                index={index}
              />
              <Button
                style={{ width: '100%', marginTop: 20 }}
                onClick={handleVideoLoadMore}
                disabled={isLoading}
                loading={isLoading}
              >
                Load More
              </Button>
            </TabPane>
          {/* )} */}
        </Tabs>
      </>
    </div>
  )
})

// define the new custom section
export const CustomVideoSearch = {
  name: 'video',
  Tab: (props: any) => {
    return (
      <SectionTab name={IS_FREE_PLATFORM === 'true' ? 'Image Search' : 'Video Search'} {...props}>
        <VideoCameraAddOutlined />
      </SectionTab>
    )
  },
  Panel: VideoPanel,
}

export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value)

  useEffect(() => {
    // Set debouncedValue to value after the specified delay
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    // Cleanup function to clear the timeout if value or delay changes
    return () => {
      clearTimeout(handler)
    }
  }, [value, delay]) // Only re-call effect if value or delay changes

  return debouncedValue
}
