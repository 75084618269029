export const bultCreateArray = [
  {
    id: 'YouTube Long Form (16:9)',
    value: '16:9',
    width: 2000,
    height: 1125,
  },
  {
    id: 'Instagram Post (1:1)',
    value: '1:1',
    width: 1000,
    height: 1000,
  },
  {
    id: 'Instagram Ad (1:1)',
    value: '1:1',
    width: 1000,
    height: 1000,
  },
  {
    id: 'Linkedln (1:1)',
    value: '1:1',
    width: 1000,
    height: 1000,
  },
  {
    id: 'Twitter (1:1)',
    value: '1:1',
    width: 1000,
    height: 1000,
  },
  {
    id: 'TikTok (9:16)',
    value: '9:16',
    width: 1125,
    height: 2000,
  },
  {
    id: 'Instagram Story (9:16)',
    value: '9:16',
    width: 1125,
    height: 2000,
  },
  {
    id: 'YouTube Short (9:16)',
    value: '9:16',
    width: 1125,
    height: 2000,
  },
]
