import { qaBackendApiInstance } from '../config/config'
import { useQuery } from 'react-query'

// for staff names
export const useGetBrandDetails = (brandId?: string | undefined) => {
  return useQuery(
    ['useGetBrandDetails', brandId],
    async () => {
      const assets = await qaBackendApiInstance.get(`/brand/${brandId}`).catch((error) => {
        throw error
      })
      return assets?.data
    },
    { enabled: !!brandId, retry: false }
  )
}
