/* eslint-disable react/prop-types */
import { IS_FREE_PLATFORM } from '../../../../../src/config'
import { useGlobalContext } from '../../../../../src/context/globalContext'
import { ScriptToCanvasBurst } from '../../../../../src/shared/utils/VideoGeneration/BurstScriptToCanvas'
import { ScriptToCanvas } from '../../../../../src/shared/utils/VideoGeneration/ScriptToCanvas'
import { Modal } from 'antd'
import { getCookie } from 'cookies-next'
import { useState } from 'react'
import { RegneratedScriptContainer } from './regeneratedScriptContainer'
import { resizeDesign } from '../../../../../src/shared/utils/polotno/resizing'

interface RegeneratedScriptContainerProps {
  store: any
  regeneratedScript: any
  setProgressBarPercentage: any
  openNewScriptModal: any
  setOpenNewScriptModal: any
}

// This componet shows the new script after the user has pressed the regnerate script
export const RegeneratedScriptModal: React.FC<RegeneratedScriptContainerProps> = ({
  store,
  regeneratedScript,
  setProgressBarPercentage,
  openNewScriptModal,
  setOpenNewScriptModal,
}) => {
  const {
    UserDetail: [userDetails, setUserDetails],
    ScriptDetails: [, setScriptDetails],
    BackgroundMusic: [, setBackgroundMusic],
    IsLoading: [, setIsLoading],
    RegeneratedScriptDetails: [, setRegeneratedScriptDetails],
    VideoStyle: [videoStyle, setVideoStyle],
    VideoMediaType: [videoMediaType, setVideoMediaType],
    Story:[editedStory, setEditedStory]
  } = useGlobalContext()

  // this is the Regenerate Video button that will regenerate a new video depending on the new script
  const handleOk = async () => {
    setIsLoading(true)
    setScriptDetails(regeneratedScript)
    setEditedStory('')
    // conditons set depending on the IS_FREE_PLATFORM
    const size =
      IS_FREE_PLATFORM === 'true'
        ? getCookie('projectSize')
          ? getCookie('projectSize')
          : '9:16'
        : userDetails.purpose.size
    const industry =
      IS_FREE_PLATFORM === 'true'
        ? getCookie('projectIndustry')
          ? getCookie('projectIndustry')
          : 'Fashion'
        : userDetails.purpose.industry

    const logo = IS_FREE_PLATFORM === 'true' ? '' : userDetails.logo

    // weather to call ScriptToCanvas funtion or ScriptToCanvasBurst depending on videoStyle
    if (videoStyle === 'Simple') {
      await ScriptToCanvas(
        store,
        regeneratedScript,
        industry ? industry.toString() : 'Fashion',
        setBackgroundMusic,
        setScriptDetails,
        setProgressBarPercentage,
        logo,
        size,
        videoMediaType
      ).then(() => {
        const oldJSON = JSON.parse(JSON.stringify(store.toJSON()))
        resizeDesign(oldJSON, store, userDetails, setUserDetails, false, undefined, size ? size.toString() : '9:16')
      })
      setIsLoading(false)
    }
    if (videoStyle === 'Burst') {
      await ScriptToCanvasBurst(
        store,
        regeneratedScript,
        industry ? industry.toString() : 'Fashion',
        setBackgroundMusic,
        setScriptDetails,
        setProgressBarPercentage,
        logo,
        size,
        videoStyle,
        videoMediaType,
        '',
        userDetails
      ).then(() => {
        const oldJSON = JSON.parse(JSON.stringify(store.toJSON()))

        resizeDesign(oldJSON, store, userDetails, setUserDetails, false, undefined, size ? size.toString() : '9:16')
      })
      setIsLoading(false)
    }

    setIsLoading(false)
  }

  const handleCancel = () => {
    setOpenNewScriptModal(false)
  }

  const [width, setWidth] = useState<number>(500)

  const modalTitle = (
    <div
      style={{ width: '100%' }}
      className='draggable-modal-title' // This class will be used as a handle
    >
      New Script
    </div>
  )

  return (
    <>
      <Modal
        title={modalTitle}
        open={openNewScriptModal}
        onOk={handleOk}
        okText={'Regenerate Video'}
        onCancel={handleCancel}
        width={width} // Set the width of the Modal to match the GifPlayer's width
        footer={(_, {OkBtn, CancelBtn }) => (
          <>
          <p>*You can edit the scenes once you regenerate the video</p>
          <OkBtn />
          <CancelBtn />
          </>
        )}
        style={{
          top: '50%',
          transform: 'translateY(-50%)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        destroyOnClose={true}
      >
        <RegneratedScriptContainer store={store} regeneratedScript={regeneratedScript} />
      </Modal>
    </>
  )
}
