import { PreviewButton } from "@src/features/polotno/component/previewContainer";
import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { Transition } from "react-transition-group";
import ScenesTab from "./sceneTabs/scenes";
import { observer } from "mobx-react-lite";
import CaptionsTab from "./sceneTabs/captions";
import VideoDurationSetting from "../sceneSettings/videoTrimmerSetting";
import {
  EditOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import {
  ArrowDown01Icon,
  ArrowUp01Icon,
  AudioBook01Icon,
  Clock01Icon,
  Message01Icon,
  TextSmallcapsIcon,
  ViewIcon,
  ViewOffSlashIcon,
} from "@src/features/polotno/component/hugeIcons/hugeIcons";
import { useGlobalContext } from "@src/context/globalContext";
import TextOverlayTab from "./sceneTabs/textOverlayTab";
const { TabPane } = Tabs;

const Settings = ({ store }: any) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [activeKey, setActiveKey] = useState<string | null>(null);
  const [pageduration, setPageDuration] = useState(
    store?.activePage?.duration || 2000
  );

  const {
    ScriptDetails: [scriptDetails, setScriptDetails],
    ShowTextOverlay: [showTextOverlay, setShowTextOverlay],
  } = useGlobalContext();
  const handleTabClick = (key: string) => {
    setActiveKey((prevKey) => (prevKey === key ? null : key));
  };
  const handleShowHideClick = () => {
    if (activeKey) {
      setActiveKey(null);
    } else {
      setActiveKey("0");
    }
  };

  useEffect(() => {
    setPageDuration(store?.activePage?.duration);
  }, [store?.activePage?.duration]);
  // Styles for the transition
  const defaultStyle = {
    transition: `opacity 300ms ease-in-out, transform 300ms ease-in-out`,
    opacity: 0,
    transform: "translateY(-10px)",
  };

  const transitionStyles: any = {
    entering: { opacity: 1, transform: "translateY(0)" },
    entered: { opacity: 1, transform: "translateY(0)" },
    exiting: { opacity: 0, transform: "translateY(-10px)" },
    exited: { opacity: 0, transform: "translateY(-10px)" },
  };
  const handleToggleSubtitle = (e: any) => {
    e.stopPropagation();
    if (scriptDetails.showSubtitles) {
      setScriptDetails((prevScript: { data: any }) => {
        const updatedScript = { ...prevScript };

        return { ...updatedScript, showSubtitles: false };
      });
      store.pages.map((page: any) => {
        const elementID = "captionText" + page.id;
        page.set({ custom: { ...page.custom, showSubtitles: false } });
        if (store.getElementById(elementID)) {
          store.getElementById(elementID).set({
            opacity: 0,
            backgroundEnabled: false,
          });
        }
      });
    } else {
      setScriptDetails((prevScript: { data: any }) => {
        const updatedScript = { ...prevScript };

        return { ...updatedScript, showSubtitles: true };
      });
      store.pages.map((page: any) => {
        const elementID = "captionText" + page.id;
        page.set({ custom: { ...page.custom, showSubtitles: true } });
        if (store.getElementById(elementID)) {
          store.getElementById(elementID).set({
            opacity: 1,
            backgroundEnabled: true,
          });
        }
      });
    }
  };
  const handleTextOverlayToggle = (status: boolean) => {
    setShowTextOverlay(status);

    store.pages.map((page: any) => {
      page.children.map((child: any) => {
        if (child.name === "h1-notlinked") {
          const elementID = child.id;
          if (store.getElementById(elementID)) {
            store.getElementById(elementID).set({
              visible: status,
              opacity: status ? 1 : 0,
            });
          }
        }
      });
    });
    // }
  };
  const pageDurationIncrementDecrement = (type: string) => {
    if (type === "Increment") {
      // const currentPage = store.getElementById(pageID);
      store.activePage.set({
        duration: store.activePage.duration + 100,
      });
      setPageDuration(store.activePage.duration);
    } else if (type === "Decrement") {
      if (pageduration > 500) {
        // const currentPage = store.getElementById(pageID);
        store.activePage.set({
          duration: store.activePage.duration - 100,
        });
        setPageDuration(store.activePage.duration);
      }
    }
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Row with Preview Button, Vertical Line, and Tabs */}
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          <PreviewButton
            store={store}
            previewOpen={previewOpen}
            setPreviewOpen={setPreviewOpen}
          />

          {/* Vertical line separator */}
          <div
            style={{
              width: "1px",
              height: "30px",
              backgroundColor: "#e0e0e0",
            }}
          />

          <Tabs activeKey={activeKey ?? undefined} onTabClick={handleTabClick}>
            <TabPane
              tab={
                <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <AudioBook01Icon color={"var(--color-primary-900)"}/>
                  <h4
                    style={{ margin: 0, fontSize: "12px" }}
                  >
                    Scenes
                  </h4>
                
                </div>
              </div>
              }
              key="0"
            />
            <TabPane
              tab={
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <Message01Icon color={"var(--color-primary-900)"} fontSize={'10px'}/>
                    <h4
                      style={{ margin: 0, fontSize: "12px" }}
                    >
                      Captions
                    </h4>
                    {scriptDetails?.showSubtitles ? (
                      <ViewOffSlashIcon
                        color={"var(--color-primary-900)"}
                        style={{ cursor: "pointer" }}
                        onClick={handleToggleSubtitle}
                      />
                    ) : (
                      <ViewIcon
                        color={"var(--color-primary-900)"}
                        style={{ cursor: "pointer" }}
                        onClick={handleToggleSubtitle}
                      />
                    )}
                  </div>
                </div>
              }
              key="1"
            />
            <TabPane
              tab={
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <TextSmallcapsIcon color={"var(--color-primary-900)"} />
                    <h4
                      style={{ margin: 0, fontSize: "12px" }}
                    >
                      Scene Text
                    </h4>
                    {showTextOverlay ? (
                      <ViewOffSlashIcon
                        color={"var(--color-primary-900)"}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleTextOverlayToggle(false);
                        }}
                      />
                    ) : (
                      <ViewIcon
                        color={"var(--color-primary-900)"}
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleTextOverlayToggle(true);
                        }}
                      />
                    )}
                  </div>
                </div>
              }
              key="2"
            />

            <TabPane
              tab={
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <Clock01Icon color={"var(--color-primary-900)"} />

                    <h4
                      style={{ margin: 0, fontSize: "12px" }}
                    >
                      Duration
                    </h4>
                    <h4
                      style={{ margin: 0, marginLeft: "4px", fontSize: "10px" }}
                    >
                      {`${(pageduration / 1000).toFixed(1)}sec`}
                    </h4>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <CaretUpOutlined
                        style={{ cursor: "default", outline: "none" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          pageDurationIncrementDecrement("Increment");
                        }}
                      />
                      <CaretDownOutlined
                        style={{
                          cursor: pageduration <= 500 ? "no-drop" : "default",
                          outline: "none",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();

                          pageDurationIncrementDecrement("Decrement");
                        }}
                      />
                    </div>
                  </div>
                </div>
              }
              key="3"
            />
          </Tabs>
        </div>
        <div
          style={{
            padding: "5px",
            border: "2px solid var(--color-primary-500)",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {activeKey ? (
            <ArrowUp01Icon
              style={{ cursor: "pointer" }}
              onClick={handleShowHideClick}
            />
          ) : (
            <ArrowDown01Icon
              style={{ cursor: "pointer" }}
              onClick={handleShowHideClick}
            />
          )}
        </div>
      </div>
      {/* Partition line */}
      <div
        style={{
          borderTop: "1px solid #e0e0e0", // Horizontal partition line
          marginTop: "2px", // Small gap between tabs and content
        }}
      />

      {/* Smooth transition for Tab content */}
      <Transition in={!!activeKey} timeout={300} unmountOnExit>
        {(state: any) => (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
              // padding: "10px",
            }}
          >
            {activeKey === "0" && (
              <div>
                <ScenesTab store={store} />
              </div>
            )}
            {activeKey === "1" && (
              <div>
                <CaptionsTab store={store} />
              </div>
            )}
            {activeKey === "2" && (
              <div>
                <TextOverlayTab store={store} />
              </div>
            )}

            {activeKey === "3" && (
              <div>
                <VideoDurationSetting store={store} />
              </div>
            )}
          </div>
        )}
      </Transition>
    </div>
  );
};

export default observer(Settings);

// export default Settings;
