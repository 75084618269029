import React, { createContext, useState, useEffect } from 'react';
import { Button, Modal, Space } from 'antd';

const ReachableContext = createContext<string | null>(null);
const UnreachableContext = createContext<string | null>(null);
const config = {
    title: 'Alert!',
    content: (
      <>
        <ReachableContext.Consumer>{(name) => `${name}`}</ReachableContext.Consumer>
        <br />
        {/* <UnreachableContext.Consumer>{(name) => `Unreachable: ${name}!`}</UnreachableContext.Consumer> */}
      </>
    ),
  };
const IsMobileModal=()=>{
    const [open, setOpen] = useState(false);
    const [modal, contextHolder] = Modal.useModal();
    useEffect(()=>{
      setOpen(true);
      modal.warning({ ...config, footer: null })
    },[])
    return (
        <ReachableContext.Provider value="We currently don't support mobile. Please try from your laptop or desktop.">

{contextHolder}

{/* Can not access this context since `contextHolder` is not in it */}
{/* <UnreachableContext.Provider value="Bamboo" /> */}
</ReachableContext.Provider>
  
      );
}



export default IsMobileModal;