/* eslint-disable react/prop-types */
import { LoadingOutlined, SnippetsOutlined } from '@ant-design/icons'
import { NODE_BACKEND_URL, IS_FREE_PLATFORM } from '../../../../src/config'
import { useGlobalContext } from '../../../../src/context/globalContext'
import { RegeneratedScriptToCanvas } from '../../../../src/shared/utils/VideoGeneration/RegenerateScriptToCanvas'
import { Button, Modal } from 'antd'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { RegeneratedScriptModal } from '../../../../src/features/polotno/component/script/regeneratedScriptModal'
import { ScriptContainer } from './scriptContainer'
import { getCookie } from 'cookies-next'
import { decodeAndParseCookie } from '../../../../src/App'
import { ScriptToCanvas } from '../../../../src/shared/utils/VideoGeneration/ScriptToCanvas'
import { ScriptToCanvasBurst } from '../../../../src/shared/utils/VideoGeneration/BurstScriptToCanvas'
import { resizeDesign } from '../../../../src/shared/utils/polotno/resizing'
import { RandomScriptToCanvas } from '../../../../src/shared/utils/VideoGeneration/RandomScriptToCanvas'
import { PromptScriptToCanvas } from '../../../../src/shared/utils/VideoGeneration/PromptScriptToCanvas'

// import { ScriptContainer } from '@src/features/polotno/component/script/scriptContainer'
interface ScriptButtonProps {
  store: any
  alternateNewScript:any
  open: boolean
  setOpen: any
  variants: boolean
}

export const ScriptButton: React.FC<ScriptButtonProps> = ({ store, alternateNewScript, open, setOpen, variants=false }) => {
  console.log(true)
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 })
  const {
    ScriptDetails: [scriptDetails, setScriptDetails],
    UserDetail: [userDetails, setUserDetails],
    BackgroundMusic: [, setBackgroundMusic],
    IsLoading: [isLoading, setIsLoading],
    VideoStyle: [videoStyle, setVideoStyle],
    VideoMediaType: [videoMediaType, setVideoMediaType],
    ProgressPercent: [, setProgressBarPercent]

  } = useGlobalContext()

//   const [open, setOpen] = useState(false)

  const [updatedScriptDetails, setUpdatedScriptDetails] = useState<any>(scriptDetails)

  const [regeneratedScriptDetails, setRegeneratedScriptDetails] = useState<any>(scriptDetails ? scriptDetails.data : '')

  const [isGifRendering, setIsGifRendering] = useState<boolean>(false)

  const [regnerateLoading, setRegenerateLoading] = useState<boolean>(false)

  const [confirmLoading, setConfirmLoading] = useState(false)

  const [isRegenerateButton, setIsRegenerateButton] = useState<boolean>(true)

  const [newScript, setNewScript] = useState()

  const [openNewScriptModal, setOpenNewScriptModal] = useState(false)

  useEffect(() => {
    if (scriptDetails && regeneratedScriptDetails) {
      const regeneratedItems = regeneratedScriptDetails?.filter((item: { isUpdated: any }) => item?.isUpdated)
      setIsRegenerateButton(regeneratedItems.length === 0)
    }
  }, [regeneratedScriptDetails])

  const showModal = () => {
    setOpen(true)
  }

  const showNewScriptModal = () => {
    setOpen(false)
    setOpenNewScriptModal(true)
  }

  const handleRegenerateScript = async () => {
    const previousScript = [...scriptDetails.data]

    const postBody = {
      previousScript: previousScript,
    }

    const localEndPoint = axios.create({
      baseURL: `${NODE_BACKEND_URL}`,
    })

    try {
      setRegenerateLoading(true)
      let response
      if (videoStyle === 'Simple') {
        response = await localEndPoint.post('/api/v1/openAi/regenerateSimpleScript', postBody)
      }
      if (videoStyle === 'Burst') {
        response = await localEndPoint.post('/api/v1/openAi/regenerateBurstScript', postBody)
      }

      if (response?.data) {
        setNewScript(response.data)
        setRegenerateLoading(false)
        setOpenNewScriptModal(true)
        setOpen(false)
      }
    } catch (error) {
      console.error('Failed to regenerate script:', error)
    }
  }

  const handleOk = async () => {
    setOpen(false)
    setIsLoading(true)
    setScriptDetails((prev:any)=>({
      ...prev,
      data: alternateNewScript.slice(0, -1)
    }))
    const alternateScript={data: alternateNewScript.slice(0, -1)}
    if (IS_FREE_PLATFORM === 'true') {
      const script = getCookie('scriptData')
      const size = getCookie('projectSize')
      const industry = getCookie('projectIndustry')

      const data = decodeAndParseCookie(script)
      localStorage.setItem('scriptData', JSON.stringify(data))
      if (videoStyle === 'Simple') {
        await RandomScriptToCanvas(
          store,
          alternateScript,
          industry ? industry.toString() : 'Fashion',
          setBackgroundMusic,
          setScriptDetails,
          setProgressBarPercent,
          '',
          userDetails.purpose.size,
          videoMediaType,
          userDetails?.colors?.secondary || '',
          userDetails
        ).then(() => {
          const oldJSON = JSON.parse(JSON.stringify(store.toJSON()))

          resizeDesign(oldJSON, store, userDetails, setUserDetails, false, undefined, size ? size.toString() : '9:16')
        })
        setIsLoading(false)
      }
      if (videoStyle === 'Burst') {
        await ScriptToCanvasBurst(
          store,
          alternateScript,
          industry ? industry.toString() : 'Fashion',
          setBackgroundMusic,
          setScriptDetails,
          setProgressBarPercent,
          '',
          userDetails.purpose.size,
          videoStyle,
          videoMediaType,
          userDetails?.colors?.secondary || '',
          userDetails
        ).then(() => {
          const oldJSON = JSON.parse(JSON.stringify(store.toJSON()))

          resizeDesign(oldJSON, store, userDetails, setUserDetails, false, undefined, size ? size.toString() : '9:16')
        })
        setIsLoading(false)
      }
    
    }  else {
      // have to handle burstImage
      if(videoStyle === 'Burst'){
        await ScriptToCanvasBurst(
          store,
          alternateScript,
          userDetails.purpose.industry || 'Others',
          setBackgroundMusic,
          setScriptDetails,
          setProgressBarPercent,
          userDetails.logo,
          userDetails.purpose.size,
          videoStyle,
          videoMediaType,
          userDetails?.colors?.secondary || '',
          userDetails
        ).then(() => {
          const oldJSON = JSON.parse(JSON.stringify(store.toJSON()))
  
          resizeDesign(oldJSON, store, userDetails, setUserDetails, false, undefined, userDetails.purpose.size.toString() || '9:16')
        })
        setIsLoading(false)
      }else{
        if(!userDetails.purpose?.promptToVideo){
          await RandomScriptToCanvas(
            store,
            alternateScript,
            userDetails.purpose.industry || 'Others',
            setBackgroundMusic,
            setScriptDetails,
            setProgressBarPercent,
            userDetails.logo,
            userDetails.purpose.size,
            videoMediaType,
            userDetails?.colors?.secondary || '',
            userDetails
          ).then(() => {
            const oldJSON = JSON.parse(JSON.stringify(store.toJSON()))
            resizeDesign(oldJSON, store, userDetails, setUserDetails, false, undefined, userDetails.purpose.size)
          })
        } else{
          await PromptScriptToCanvas(
            store,
            alternateScript,
            userDetails.purpose.industry || 'Others',
            setBackgroundMusic,
            setScriptDetails,
            setProgressBarPercent,
            userDetails.logo,
            userDetails.purpose.size,
            videoMediaType,
            userDetails?.colors?.secondary || '',
            userDetails
          ).then(() => {
            const oldJSON = JSON.parse(JSON.stringify(store.toJSON()))
            resizeDesign(oldJSON, store, userDetails, setUserDetails, false, undefined, userDetails.purpose.size)
          })
        }
       
      }
      setIsLoading(false)
    }
    // // setConfirmLoading(true)
    // const regeneratedIndexes: number[] = []
    // const regeneratedItems: { isUpdated: any }[] = []

    // regeneratedScriptDetails.forEach((item: { isUpdated: any }, index: number) => {
    //   if (item.isUpdated) {
    //     regeneratedItems.push(item)
    //     regeneratedIndexes.push(index)
    //   }
    // })

    // await setScriptDetails((prevScript: { data: any }) => {
    //   const updatedScript = { ...prevScript }

    //   return { ...updatedScript, data: regeneratedScriptDetails }
    // })

    // setIsLoading(true)
    // await RegeneratedScriptToCanvas(
    //   store,
    //   regeneratedItems,
    //   regeneratedIndexes,
    //   setScriptDetails,
    //   userDetails?.purpose?.size,
    //   setProgressBarPercentage,
    //   videoMediaType
    // )

    // setIsLoading(false)
  }

  const handleCancel = async () => {
    
    setOpen(false)
    console.log(open)
  }

  const [width, setWidth] = useState<number>(500)
  const [height, setHeight] = useState<number>(600)

  const modalTitle = (
    <div style={{ display: 'flex', flexDirection: 'row', background: 'white' }}>
      <div
        style={{ width: '100%' }}
        className='draggable-modal-title' // This class will be used as a handle
      >
        Script
      </div>
      {/* <Button
        disabled={regnerateLoading}
        style={{
          background: 'linear-gradient(90deg, rgba(108, 61, 240, 1) 0%, rgba(140, 103, 244, 1) 80%)',
          borderColor: 'transparent',
          color: 'white',
          marginRight: '20px',
        }}
        icon={regnerateLoading ? <LoadingOutlined /> : null}
        onClick={() => {
          handleRegenerateScript()
        }}
      >
        Regenerate Script
      </Button> */}
    </div>
  )

  return (
    <>
    {!variants && (
 <Button
 onClick={() => {
   showModal()
 }}
 style={{ background: 'transparent', borderColor: 'var(--color-primary-500)', marginBottom: '10px', color: 'var(--color-primary-500)' }}
 icon={isGifRendering ? <LoadingOutlined /> : <SnippetsOutlined />}
>
 Script
</Button>
    )}
      <Modal
        title={modalTitle}
        open={open}
        okText={'Generate video'}
        onOk={handleOk}
        okButtonProps={{
          disabled: false, // isRegenerateButton
          style: {
            background: 'linear-gradient(90deg, rgba(108, 61, 240, 1) 0%, rgba(140, 103, 244, 1) 80%)',
            borderColor: 'transparent',
            color: 'white',
            marginBottom: '10px',
          },
        }}
        confirmLoading={confirmLoading}
        cancelText={'Close'}
        onCancel={handleCancel}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            {videoStyle === 'Simple' || videoStyle === 'Burst' ? <OkBtn /> : null}

            {!regnerateLoading && newScript && (
              <Button
                onClick={async () => {
                  showNewScriptModal()
                }}
                style={{ background: 'transparent', borderColor: 'var(--color-primary-500)', marginBottom: '10px', color: 'var(--color-primary-500)' }}
              >
                New Script
              </Button>
            )}
            <CancelBtn />
          </>
        )}
        width={width} // Set the width of the Modal to match the GifPlayer's width
        style={{
          top: '50%',
          transform: 'translateY(-50%)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        destroyOnClose={true}
      >
        <ScriptContainer
          store={store}
          alternateNewScript={alternateNewScript}
          setUpdatedScriptDetails={setUpdatedScriptDetails}
          setRegeneratedScriptDetails={setRegeneratedScriptDetails}
          setConfirmLoading={setConfirmLoading}
        />
      </Modal>
      {openNewScriptModal && (
        <RegeneratedScriptModal
          store={store}
          regeneratedScript={newScript}
          openNewScriptModal={openNewScriptModal}
          setOpenNewScriptModal={setOpenNewScriptModal}
          setProgressBarPercentage={setProgressBarPercent}
        />
      )}
    </>
  )
}
