// import React, { useState, useEffect } from 'react';
// import { Input } from 'antd';
// import { useGlobalContext } from '../../../../../context/globalContext';
// const { TextArea } = Input;

// interface StoryInputProps {
//   story: string;
// }

// const UNIQUE_IDENTIFIER = '@@@';

// const StoryInput: React.FC<StoryInputProps> = ({ story }) => {
//   const {
//     ScriptDetails: [scriptDetails, setScriptDetails],
//   } = useGlobalContext();

//   const [originalSentence, setOriginalSentence] = useState(
//     scriptDetails.data.map((data: { voiceOver: string }) => data.voiceOver).join(UNIQUE_IDENTIFIER)
//   );
//   const [voiceOverString, setVoiceOverString] = useState(originalSentence);

//   // Function to check changes and get indices of changed sentences
//   const getChangedSentences = (
//     original: string,
//     modified: string
//   ): { index: number, original: string, modified: string }[] => {
//     const originalSentences = original.split(UNIQUE_IDENTIFIER);
//     const modifiedSentences = modified.split(UNIQUE_IDENTIFIER);

//     const changes: { index: number, original: string, modified: string }[] = [];
//     for (let i = 0; i < Math.max(originalSentences.length, modifiedSentences.length); i++) {
//       if (originalSentences[i] !== modifiedSentences[i]) {
//         changes.push({
//           index: i,
//           original: originalSentences[i] || '',
//           modified: modifiedSentences[i] || ''
//         });
//       }
//     }
//     return changes;
//   };

//   // Handler for text area changes
//   const contextChange = (e: any) => {
//     const updatedText = e.target.value;
//     setVoiceOverString(updatedText);
//     const changes = getChangedSentences(originalSentence, updatedText);
//     console.log('Changes:', changes);

//     // Update the script details with the modified sentences
//     const updatedScriptDetails = scriptDetails.data.map((item: any, index: number) => {
//       const change = changes.find(change => change.index === index);
//       if (change) {
//         return {
//           ...item,
//           voiceOver: change.modified
//         };
//       }
//       return item;
//     });
//     // setScriptDetails({ ...scriptDetails, data: updatedScriptDetails });
//   };

//   return (
//     <div>
//       <TextArea rows={8} value={voiceOverString} onChange={contextChange} />
//     </div>
//   );
// };

// export default StoryInput;

import React, { useEffect, useState } from 'react';
import { Input, message } from 'antd';
import { useGlobalContext } from '../../../../../context/globalContext'
const { TextArea } = Input;

interface StoryInputProps {
    story: string;
    setStoryChanged: any
    setNewContext: any
  }
const StoryInput: React.FC<StoryInputProps> = ({ story, setStoryChanged, setNewContext }) => {
    const {
        ScriptDetails: [scriptDetails, setScriptDetails],
        Story:[editedStory,]
        // StoryChange:[storyChanged, setStoryChanged]
      } = useGlobalContext()
      const [originalSentance, setOriginalSentence]=useState(scriptDetails.data.map((data: { voiceOver: string }) => data.voiceOver).join(' '))
      const [voiceOverString , SetVoiceOverString]=useState(scriptDetails.data.map((data: { voiceOver: string }) => data.voiceOver).join(' '))
    // const voiceOverString = scriptDetails.data.map((data: { voiceOver: string }) => data.voiceOver).join(' ');
    const sentencesWithIndices = scriptDetails.data.map((data:any, index:number) => ({
        sentence: data.voiceOver,
        index: index,
        is_changed: false
    }));  
    // const [voiceOverObjects, setVoiceOverObjects] = useState(sentencesWithIndices)
    // console.log(voiceOverObjects)
    const contextChange=(e:any)=>{
        if(originalSentance === e.target.value){
            message.info("No Changes detected.")
            setStoryChanged(false)
        }else{
        //   const updatedVoiceOverObjects = voiceOverObjects.map((obj:any) => ({
        //     ...obj,
        //     is_changed: !voiceOverString.includes(obj.sentence)
        // }));
        // setVoiceOverObjects(updatedVoiceOverObjects);

            if(e.target.value.length<30){
                // message.info("Please enter alteast 30 characters.")
                setStoryChanged(true)
            }else{
                console.log("Changed")
                setNewContext(e.target.value)
                setStoryChanged(true)
            }
        }
        SetVoiceOverString(e.target.value)
        // setEditedStory(e.target.value)
      }
      // useEffect(()=>{
      //   if(editedStory.length>30)
      //   SetVoiceOverString(editedStory)
      // },[editedStory])
  return (
    <div>
      <TextArea rows={8} defaultValue={voiceOverString} value={voiceOverString} onChange={contextChange}/>
      {/* Your input elements or other UI components */}
    </div>
  );
};

export default StoryInput;