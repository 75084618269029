import React, { useState } from 'react';
import { Card, Tooltip } from 'antd';


interface VariationButtonProps {
    store:any
  text: string;
  open: boolean;
  setOpen: any;
  onClick?: () => void;
  previewIcon:any;
  toolTip: string
}

const VariationsButton: React.FC<VariationButtonProps> = ({ store,text, open, setOpen, onClick, previewIcon, toolTip }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);  
  return (
      <Tooltip
      title={toolTip}
      style={{backgroundColor:'purple', color:'white'}}
      overlayStyle={{
        color: 'white',
        backgroundColor: 'purple',
      }}
    >
    <Card
    size='small'
    hoverable={true}
    onClick={onClick}
    style={{ cursor: 'pointer', boxShadow: `${isHovered ? '0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 12px 40px 0 rgba(0, 0, 0, 0.19)' : '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}`, padding:'0px' }}
    onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
  >
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}> 
      <img
      src={previewIcon}
      alt={text}
      style={{width:'100%', height:'auto', objectFit:'contain'}}
      height='auto'
      width='100%'
      />
    </div>
    <p style={{ textAlign: 'center', marginTop:'2px', marginBottom:'0px', fontFamily:'DM Sans', color:'var(--color-primary-500)'}}>{text}</p>
    {/* <ScriptButton store={store} open={open} setOpen={setOpen} setProgressBarPercentage={setProgressBarPercentage} variants={true}/> */}
  </Card>
  </Tooltip>

  );
};

export default VariationsButton;
