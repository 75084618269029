/* eslint-disable react/jsx-no-undef */
import { resizeDesign } from '../../shared/utils/polotno/resizing'
import { Select } from 'antd'
import { useEffect, useState } from 'react'
import { useGlobalContext } from '../../context/globalContext'
import { resizeList } from '../../data/resize'
import './resizeDropdown.less'
const { Option } = Select

interface ResizeDropdownProps {
  store: any
}

export const ResizeDropdown = ({ store }: ResizeDropdownProps) => {
  const {
    UserDetail: [userDetails, setUserDetails],
  } = useGlobalContext()

  const [selectedValue, setSelectedValue] = useState(userDetails?.purpose?.size || '16:9')

  useEffect(() => {
    if (!userDetails?.purpose?.size) return
    setSelectedValue(userDetails?.purpose?.size || '16:9')
  }, [userDetails])

  // dimensions set

  const handleResizeValueChange = async (value: string) => {
    setSelectedValue(value)

    // Find the selected aspect ratio details from resizeList
    const selectedRatio = resizeList.find((item) => item.value === value)

    if (!selectedRatio) return // Exit if no matching ratio found

    // Current dimensions from the store
    const currentWidth = store.toJSON().width
    const currentHeight = store.toJSON().height

    // Calculate new dimensions to maintain the selected aspect ratio
    // Assuming we are adjusting height to keep the design's width consistent
    const newWidth = currentWidth // Keep current width

    const oldJSON = JSON.parse(JSON.stringify(store.toJSON()))

    resizeDesign(oldJSON, store, userDetails, setUserDetails, false, undefined, value)
  }
  return (
    <div
      style={{
        width: '15%',
        marginTop: '8px',
        marginLeft: '20px',
        marginBottom: '8px',
        // background: 'white',
      }}
    >
      <Select
        style={{ width: '50%', border: '2px solid rgb(235, 233, 254)', borderRadius: '5px' }}
        placeholder='Select Background Music'
        optionFilterProp='children'
        onChange={handleResizeValueChange}
        value={selectedValue}
        dropdownRender={(menu) => <div>{menu}</div>}
      >
        {resizeList.map((data, index) => (
          <Option key={index} value={data.value}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>{data.value}</div>
          </Option>
        ))}
      </Select>
    </div>
  )
}
