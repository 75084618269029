import React, { useMemo } from 'react'
import { AbsoluteFill, Freeze, interpolate, useCurrentFrame } from 'remotion'
import type { TransitionPresentation, TransitionPresentationComponentProps } from '../../types.js'
// import { CameraMotionBlur } from './cameraMotionBlur.js'
// import { CameraMotionBlur } from './cameraMotionBlur.js'
// import { CameraMotionBlur } from '@remotion/motion-blur'

export type SlideDirection = 'from-left' | 'from-top' | 'from-right' | 'from-bottom'

const epsilon = 0.01

export type MotionBlurProps = {
  direction?: SlideDirection
  enterStyle?: React.CSSProperties
  exitStyle?: React.CSSProperties
}

// const MotionBlurPresentation: React.FC<TransitionPresentationComponentProps<MotionBlurProps>> = ({
//   children,
//   presentationDirection,
//   presentationProgress,
//   passedProps: { direction = 'from-right', enterStyle, exitStyle },
//   presentationDurationInFrames,
// }) => {
//   const isEntering = presentationDirection === 'entering'

//   const directionStyle = useMemo((): React.CSSProperties => {
//     // Overlay the two slides barely to avoid a white line between them
//     const presentationProgressWithEpsilonCorrection =
//       presentationProgress === 1 ? presentationProgress * 100 : presentationProgress * 100 - epsilon

//     const startX = direction === 'from-right' ? -100 : 100
//     const endX = 0

//     const translateX = interpolate(presentationProgress, [0, 1], isEntering ? [startX, endX] : [endX, startX])

//     const speedFactor = isEntering ? 1 : 0
//     const blurAmount = speedFactor * (1 - presentationProgress) * 100 // Adjusted blur intensity here

//     if (presentationDirection === 'exiting') {
//       switch (direction) {
//         case 'from-left':
//           return {
//             transform: `translateX(${presentationProgressWithEpsilonCorrection}%)`,
//             filter: `blur(${blurAmount}px)`, // Enabled blur here
//           }
//         case 'from-right':
//           return {
//             transform: `translateX(${-presentationProgress * 100}%)`,
//             // transform: `translateX(${translateX}%)`,
//             filter: `blur(${blurAmount}px)`,
//           }
//         case 'from-top':
//           return {
//             transform: `translateY(${presentationProgressWithEpsilonCorrection}%)`,
//             filter: `blur(${blurAmount}px)`,
//           }
//         case 'from-bottom':
//           return {
//             transform: `translateY(${-presentationProgress * 100}%)`,
//             filter: `blur(${blurAmount}px)`,
//           }
//         default:
//           throw new Error(`Invalid direction: ${direction}`)
//       }
//     }

//     switch (direction) {
//       case 'from-left':
//         return {
//           transform: `translateX(${-100 + presentationProgress * 100}%)`,
//           filter: `blur(${blurAmount}px)`,
//         }
//       case 'from-right':
//         return {
//           transform: `translateX(${100 - presentationProgressWithEpsilonCorrection}%)`,
//           // transform: `translateX(${translateX}%)`,
//           filter: `blur(${blurAmount}px)`,
//         }
//       case 'from-top':
//         return {
//           transform: `translateY(${-100 + presentationProgress * 100}%)`,
//           filter: `blur(${blurAmount}px)`,
//         }
//       case 'from-bottom':
//         return {
//           transform: `translateY(${100 - presentationProgressWithEpsilonCorrection}%)`,
//           filter: `blur(${blurAmount}px)`,
//         }
//       default:
//         throw new Error(`Invalid direction: ${direction}`)
//     }
//   }, [presentationProgress, direction, isEntering, presentationDirection])

//   const style: React.CSSProperties = useMemo(() => {
//     return {
//       width: '100%',
//       height: '100%',
//       opacity: isEntering ? presentationProgress : 1,
//       ...directionStyle,
//       ...(isEntering ? enterStyle : exitStyle),
//     }
//   }, [directionStyle, isEntering, enterStyle, exitStyle])

//   return <AbsoluteFill style={style}>{children}</AbsoluteFill>
// }

const MotionBlurPresentation: React.FC<TransitionPresentationComponentProps<MotionBlurProps>> = ({
  children,
  presentationDirection,
  presentationProgress,
  passedProps: { direction = 'from-right', enterStyle, exitStyle },
}) => {
  const isEntering = presentationDirection === 'entering'

  const directionStyle = useMemo((): React.CSSProperties => {
    // Overlay the two slides barely to avoid a white line between them

    const speedFactor = isEntering ? 1 : 0
    const blurAmount = speedFactor * (1 - presentationProgress) * 100 // Adjusted blur intensity here

    return {
      filter: `blur(${blurAmount}px)`,
    }
  }, [presentationProgress, isEntering])

  // Additional edge blur overlay
  const edgeBlurStyle: React.CSSProperties = useMemo(
    () => ({
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      pointerEvents: 'none',
      background: 'radial-gradient(rectangle, transparent, transparent 50%, black 100%)',
    }),
    []
  )

  // Combine styles
  const style: React.CSSProperties = useMemo(() => {
    return {
      width: '100%',
      height: '100%',
      opacity: isEntering ? presentationProgress : 1,
      ...directionStyle,
      ...edgeBlurStyle,
      ...(isEntering ? enterStyle : exitStyle),
    }
  }, [isEntering, presentationProgress, directionStyle, edgeBlurStyle, enterStyle, exitStyle])

  return <AbsoluteFill style={style}>{children}</AbsoluteFill>
}

/**
 * Provides a motion blur transition component with adjustable direction.
 * @param {MotionBlurProps} [props] Optional properties to define direction, enterStyle, and exitStyle.
 * @returns {TransitionPresentation<MotionBlurProps>} The transition presentation component setup.
 */
export const blurTransition = (props?: MotionBlurProps): TransitionPresentation<MotionBlurProps> => {
  return {
    component: MotionBlurPresentation,
    props: props ?? {},
  }
}
