export const resizeList = [
  // {
  //   value: '16:9',
  //   width: 704,
  //   height: 400,
  // },
  {
    value: '16:9',
    width: 1280,
    height: 720,
  },
  // {
  //   value: '4:3',
  //   width: 536,
  //   height: 400,
  // },
  {
    value: '4:3',
    width: 800,
    height: 600,
  },
  // {
  //   value: '1:1',
  //   width: 400,
  //   height: 400,
  // },
  {
    value: '1:1',
    width: 720,
    height: 720,
  },
  // {
  //   value: '4:5',
  //   width: 400,
  //   height: 500,
  // },
  {
    value: '4:5',
    width: 1080,
    height: 1350,
  },
  // {
  //   value: '9:16',
  //   width: 400,
  //   height: 704,
  // },
  {
    value: '9:16',
    width: 720,
    height: 1280,
  },
]
