import { qaBackendApiInstance } from "@src/config/config"

export async function saveClonedVoices(voice_name:string, voice_id:string){
    const token = localStorage.getItem('accessToken')
    
    const postBody={
      voice_name:voice_name,
      voice_id:voice_id
    }
    try{
      const response = await qaBackendApiInstance.post('/voice/save-clone-voice',
        postBody,
        {
          headers: {
              'Authorization': `Bearer ${token}`
          }
      }
  
      )
      return response.data
    }catch(error){
      return -1
    }
  }
  
  export async function getClonedVoices(){
    const token = localStorage.getItem('accessToken')
    try{
      
      const response = await qaBackendApiInstance.get(`/voice/get-clone-voice`, 
        {
        headers: {
          'Authorization': `Bearer ${token}`
          
        }
      });
    
      return response.data.cloned_voices;
    }catch(error){
    return -1
    }
  }
  
  export async function deleteClonedVoice(id:string){
    const token = localStorage.getItem('accessToken')
  
    try{
      const response = await qaBackendApiInstance.delete(`/voice/delete-clone-voice/${id}`,
        {
          headers: {
              'Authorization': `Bearer ${token}`
          }
      }
      )
      
        return response.data
    }catch(error){
      return -1
    }
  }