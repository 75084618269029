import axios, { AxiosInstance, InternalAxiosRequestConfig } from "axios";

let authAxios: AxiosInstance;

function onRequest(config: InternalAxiosRequestConfig) {
  const token = localStorage.getItem("accessToken");

  if (token && config.headers) {
    // Use the set method to set the Authorization header
    config.headers.set("Authorization", `Bearer ${token}`);
  }

  return config;
}

export function getProtectedAxios() {
  if (!authAxios) {
    authAxios = axios.create();
    authAxios.interceptors.request.use(onRequest);
  }
  

  return authAxios;
}
