import { observer } from 'mobx-react-lite'
import { Button, Tooltip } from '@blueprintjs/core'
import { useGlobalContext } from '../../../../context/globalContext'
import { handleCopyPage, handleDeletePage } from '../../../../shared/utils/polotno/pageOperations'
import { t } from 'polotno/utils/l10n'

export const PageControls = observer(({ store, page, xPadding, yPadding }: any) => {
  const {
    ScriptDetails: [scriptDetails, setScriptDetails],
    UndoStack: [undoStack, setUndoStack],
    RedoStack: [redoStack, setRedoStack],
    VideoStyle: [videoStyle],
    IsStoreUpdated: [isStoreUpdated, setIsStoreUpdated],
  } = useGlobalContext()

  const hasManyPages = store.pages.length > 1

  /* This will help us to get the index of each page, that we can then use in our delete and copy funtion. 
  Scrolling down in polotno will auto update the index value*/

  const index = store.pages.indexOf(page)

  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: yPadding - 40 + 'px',
          right: xPadding + 'px',
        }}
      >
        {/* This block of code will change the placement of the pages in polotno, depending on up and down arrow
        The logic of how to integrate changing the placement of pages with script data is yet to be implemented if needed in the future  */}

        {/* {hasManyPages && (
          <Tooltip content={t('workspace.moveUp')} disabled={index === 0}>
            <Button
              icon='chevron-up'
              minimal
              disabled={index === 0}
              onClick={() => {
                page.setZIndex(index - 1)
              }}
            ></Button>
          </Tooltip>
        )}
        {hasManyPages && (
          <Tooltip content={t('workspace.moveDown')} disabled={index === store.pages.length - 1}>
            <Button
              icon='chevron-down'
              minimal
              disabled={index === store.pages.length - 1}
              onClick={() => {
                const index = store.pages.indexOf(page)
                page.setZIndex(index + 1)
              }}
            ></Button>
          </Tooltip>
        )} */}
        <Tooltip content={t('workspace.duplicatePage')}>
          <Button
            icon='duplicate'
            minimal
            onClick={() => {
              handleCopyPage(
                store,
                index,
                scriptDetails,
                setScriptDetails,
                setUndoStack,
                setRedoStack,
                videoStyle,
                isStoreUpdated,
                setIsStoreUpdated
              )
            }}
          ></Button>
        </Tooltip>
        {hasManyPages && (
          <Tooltip content={t('workspace.removePage')}>
            <Button
              icon='trash'
              minimal
              onClick={() => {
                handleDeletePage(
                  store,
                  index,
                  scriptDetails,
                  setScriptDetails,
                  setUndoStack,
                  videoStyle,
                  isStoreUpdated,
                  setIsStoreUpdated,
                  false
                )
              }}
            ></Button>
          </Tooltip>
        )}
        {/* This will add a new blank page to the polotno, the logic of how to integrate a new script data with a new page is to be implemented if needed in the future*/}
        {/* <Tooltip content={t('workspace.addPage')}>
          <Button
            icon='insert'
            minimal
            onClick={() => {
              const newPage = store.addPage({
                bleed: store.activePage?.bleed || 0,
              })
              const index = store.pages.indexOf(page)
              newPage.setZIndex(index + 1)
            }}
          ></Button>
        </Tooltip> */}
      </div>
    </>
  )
})
