import { FC } from 'react'
import { Slider, Typography } from 'antd'

interface SliderProps {
  wordsPerLine: number
  onFinish: (value: number) => void
}

const WordSlider: FC<SliderProps> = ({ wordsPerLine, onFinish }) => {
  const handleChange = (value: number) => {
    onFinish(value)
  }

  return (
    <>
      <Slider
        min={1}
        max={10}
        onChange={handleChange}
        value={wordsPerLine}
        style={{
          margin: '0px 2px',
        }}
      />
    </>
  )
}

export default WordSlider
