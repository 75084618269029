import React, { useEffect, useState } from 'react';
import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Flex,
  message,
  Upload,
  GetProp,
  UploadProps,
  Image,
  Row,
  Button,
  Input,
  Switch,
} from 'antd';
import { useGlobalContext } from '@src/context/globalContext';
import {
  uploadFile,
  uploadImageFile,
} from '@src/shared/utils/VideoGeneration/uploadAsset';

// import type {  } from 'antd';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const getBase64 = (img: FileType, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const beforeUpload = (file: FileType) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

const UploadImage: React.FC = () => {
  const {
    ScriptDetails: [scriptDetails, setScriptDetails],
  } = useGlobalContext();
  const [textOver, setTextOver] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [isTextVisible, setIsTextVisible] = useState(
    scriptDetails?.thumbnailTextVisible || false
  );
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();

  const handleChange: UploadProps['onChange'] = async (info) => {
    // console.log(info.file.originFileObj)
    setUploadedFile(info.file.originFileObj || null);
  };

  const uploadButton = (
    // <button style={{ border: 0, background: 'none' }} type='button'>
    //   {loading ? <LoadingOutlined /> : <PlusOutlined />}
    //   <div style={{ marginTop: 8 }}>Upload</div>
    // </button>
    <Button style={{ width: '100%' }} icon={<UploadOutlined />}>
      Upload File
    </Button>
  );
  useEffect(() => {
    setPreviewImage(scriptDetails?.thumbnailImage || '');
  }, []);
  const uploadImage = async () => {
    if (uploadedFile) {
      try {
        setLoading(true);
        const response = await uploadImageFile(uploadedFile, '', '');
        setPreviewImage(response.image_url);
        setScriptDetails((prevScript: { data: any }) => {
          const updatedScript = { ...prevScript };
          return {
            ...updatedScript,
            thumbnailImage: response.image_url,
            thumbnailText: textOver,
            thumbnailTextVisible: isTextVisible,
          };
        });
        message.success('Changes saved')
      } catch {
        message.warning('Failed to upload set thumbnail image');
      } finally {
        setLoading(false);
      }
    } else {
      // message.warning('Please select file');
      setScriptDetails((prevScript: { data: any }) => {
        const updatedScript = { ...prevScript };
        return {
          ...updatedScript,
          //   thumbnailImage: response.image_url,
          thumbnailText: textOver.length > 0 ? textOver : textOver,
          thumbnailTextVisible: isTextVisible,
        };
      });
      message.success('Changes saved')
    }
  };
  return (
    <Row gutter={[0, 4]} style={{ flexDirection: 'column' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h3
          style={{
            color: 'black',
            fontSize: '16px',
            fontWeight: 500,
            fontFamily: 'DM Sans',
            letterSpacing: 'normal',
            marginBottom: '10px',
          }}
        >
          Thumbnail
        </h3>

        <Switch
          size='small'
          style={{ marginTop: '15px', marginLeft: '10px' }}
          onChange={(e) => {
            setScriptDetails((prevScript: { data: any }) => {
              const updatedScript = { ...prevScript };
              return {
                ...updatedScript,
                showThumbnail:e
              };
            });
          }}
          defaultChecked={scriptDetails?.showThumbnail}
          value={scriptDetails?.showThumbnail}
        />
      </div>

      <Image
        src={previewImage}
        style={{ borderRadius: '10px', marginBottom: '10px' }}
      />
      <div>
        <h3
          style={{
            color: 'black',
            fontSize: '14px',
            fontWeight: 500,
            fontFamily: 'DM Sans',
            letterSpacing: 'normal',
            // marginBottom: '10px',
          }}
        >
          File
        </h3>
        <Upload
          // name='avatar'
          // listType='picture-card'
          // className='avatar-uploader'
          showUploadList={false}
          // beforeUpload={beforeUpload}
          onChange={handleChange}
          accept='image/*'
        >
          {uploadButton}
        </Upload>
        {uploadedFile && <p>{uploadedFile.name}</p>}
      </div>
      <div>
        <div style={{ display: 'flex' }}>
          <h3
            style={{
              color: 'black',
              fontSize: '14px',
              fontWeight: 500,
              fontFamily: 'DM Sans',
              letterSpacing: 'normal',
              // marginBottom: '10px',
            }}
          >
            Enter Text Over
          </h3>
          <Switch
            size='small'
            style={{ marginTop: '15px', marginLeft: '10px' }}
            onChange={(e) => setIsTextVisible(e)}
            defaultChecked={scriptDetails?.thumbnailTextVisible || false}
            value={isTextVisible}
          />
        </div>

        <Input
          placeholder='Enter Text over'
          defaultValue={textOver}
          style={{
            // width: '100%',
            marginRight: '10px',
          }}
          onChange={(e) => setTextOver(e.target.value)}
          className='hoverable-text-box' // Apply the CSS class here
        />
      </div>

      {/* <Button icon={<UploadOutlined />} onClick={()=>uploadImage()}>Upload File</Button> */}

      <Button
        style={{
          background: 'var(--gradient-primary)',
          color: 'white',
          width: '100%',
          marginTop: '2px',
        }}
        onClick={() => uploadImage()}
        // disabled={backgroundFileList.length === 0}
        loading={loading}
      >
        Save Changes
      </Button>
    </Row>
  );
};

export default UploadImage;
