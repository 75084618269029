export function voiceFilter(aiVoice:any){

const accents = [...new Set(aiVoice.map((voice:any) => voice?.labels?.accent))];
const ages = [...new Set(aiVoice.map((voice:any) => voice?.labels?.age))];
const genders = [...new Set(aiVoice.map((voice:any) => voice?.labels?.gender))];
const languages = [...new Set(aiVoice.map((voice:any) => voice?.fine_tuning?.language))].filter(Boolean);
const usecases = [...new Set(aiVoice.map((voice:any) => voice?.labels?.use_case))].filter(Boolean);

const returnObjects={
    accents:accents,
    ages:ages,
    genders:genders,
    languages:languages,
    usecases:usecases
}
return returnObjects
}
