import { Select } from 'antd'
import { FC } from 'react'

interface FontTemplate {
  name: string
  url: string
}

interface ChooseFontTemplatesProps {
  templates: FontTemplate[]
  onSelect: (selectedName: string) => void
}

const { Option } = Select

const ChooseFontTemplatesRow: FC<ChooseFontTemplatesProps> = ({ templates, onSelect }) => {
  const handleChange = (value: any) => {
    onSelect(value)
  }

  return (
    <>
      <Select
        style={{ minWidth: '200px', width: '100%' }}
        placeholder='Select Caption Style'
        optionFilterProp='children'
        onChange={(value) => handleChange(value)}
        defaultValue={templates[0].name}
        dropdownRender={(menu) => {
          return <div>{menu}</div>
        }}
      >
        {templates.map((music, index) => (
          <Option key={index} value={music.name}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              {music.name}
              <img src={music.url} alt={music.name} style={{ width: '50px' }} />
            </div>
          </Option>
        ))}
      </Select>
    </>
  )
}

export default ChooseFontTemplatesRow
