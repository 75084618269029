import type { RenderPoster } from '@remotion/player';
import React, { useCallback } from 'react';
import { AbsoluteFill, Img, staticFile } from 'remotion';

const RenderPosterComponent: React.FC = ({height, width, isBuffering, thumbnailImage, thumbnailMessage}:any) => {
    // console.log('RenderPoster')
    
    if (isBuffering) {
        return (
          <AbsoluteFill style={{ justifyContent: 'center', alignItems: 'center' }}>
            <p>Buffering..</p>
          </AbsoluteFill>
        );
      }
    
      return (
        <AbsoluteFill>
        <Img
        src={thumbnailImage}
        width={width}
        height={height}
        />
        <div
        style={{
            position: 'absolute',
            top:'50%',
            left:'50%',
            transform: 'translate(-50%, -50%) rotate(5deg)',
            padding:'10px',
            border: '10px solid',
           borderImageSlice:'1',
           borderImageSource:'linear-gradient(90deg, #6a39ef 0%, #7734bd 100%)',
           borderRadius:'2px',
           background:'white'
        }}
        >
<div
style={{
    color:'black',
    fontSize:'24px',
    fontWeight:'bold',
    textAlign:'center',
    padding:'10px'
    // background:'black'
}}
>
{thumbnailMessage}
</div>
        </div>
      
        </AbsoluteFill>
      );
};

export default RenderPosterComponent;
