import { ScriptToCanvasBurst } from './BurstScriptToCanvas'
import { ScriptToCanvas } from './ScriptToCanvas'
import { RandomScriptToCanvas } from './RandomScriptToCanvas'
import { hexToRgb } from '../polotnoStore'
import { useGlobalContext } from '../../../context/globalContext'
import { PromptScriptToCanvas } from './PromptScriptToCanvas'
export const ScriptToCanvasFunc = async (
  setPageLoading: any,
  scriptDetails: any,
  firstRender: boolean,
  industry: string,
  setBackgroundMusic: any,
  setScriptDetails: any,
  setProgressBarPercent: any,
  icon: string,
  selectedSize: string,
  videoStyle: string,
  videoMediaType: string,
  store: any,
  color:any,
  userDetails:any,
  setShowTextOverlay:any
) => {
 console.log(industry)
  if (firstRender === true) {
    if(!userDetails.purpose?.promptToVideo){
      if (videoStyle === 'Simple') {
        setShowTextOverlay(true)
        await RandomScriptToCanvas(
          store,
          scriptDetails,
          industry,
          setBackgroundMusic,
          setScriptDetails,
          setProgressBarPercent,
          icon,
          selectedSize,
          videoMediaType,
          hexToRgb(color),
          userDetails
        )
        // await ScriptToCanvas(
        //   store,
        //   scriptDetails,
        //   industry,
        //   setBackgroundMusic,
        //   setScriptDetails,
        //   setProgressBarPercent,
        //   icon,
        //   selectedSize,
        //   videoMediaType
        // )
      } else {
          await ScriptToCanvasBurst(
            store,
            scriptDetails,
            industry,
            setBackgroundMusic,
            setScriptDetails,
            setProgressBarPercent,
            icon,
            selectedSize,
            videoStyle,
            videoMediaType,
            hexToRgb(color),
            userDetails
          )
        }
    }else{
      if(videoStyle === "Simple"){
        await PromptScriptToCanvas(
          store,
            scriptDetails,
            industry,
            setBackgroundMusic,
            setScriptDetails,
            setProgressBarPercent,
            icon,
            selectedSize,
            videoMediaType,
            hexToRgb(color),
            userDetails
        )
      }else{
        await ScriptToCanvasBurst(
          store,
          scriptDetails,
          industry,
          setBackgroundMusic,
          setScriptDetails,
          setProgressBarPercent,
          icon,
          selectedSize,
          videoStyle,
          videoMediaType,
          hexToRgb(color),
          userDetails
        )
      }
     
    }

    
    setPageLoading(false)
  }
}
