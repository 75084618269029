import axios from 'axios'
import crypto from 'crypto-js'

// Provided by Storyblocks
export const storyBlocksPublicKey = process.env.REACT_APP_STORYBLOCKS_PUBLIC_KEY || 'test123'
const privateKey = process.env.REACT_APP_STORYBLOCKS_PRIVATE_KEY || 'test123'

// url info
const baseUrl = 'https://api.videoblocks.com'
const resource = '/api/v2/videos/search'
export const storyBlocksUrlWithoutQueryParams = baseUrl + resource

// HMAC generation
export const storyBlocksExpires = Math.floor(Date.now() / 1000) + 100
const hmac = crypto.HmacSHA256(resource, privateKey + storyBlocksExpires)
export const storyBlocksHmac = hmac.toString(crypto.enc.Hex)
