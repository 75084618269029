const NODE_ENV = process.env.REACT_APP_NODE_ENV
const POLOTNO_KEY = process.env.REACT_APP_POLOTNO_KEY
const ELEVEN_LABS_KEY = process.env.REACT_APP_ELEVEN_LABS_KEY
const PIXABY_KEY = process.env.REACT_APP_PIXABY_KEY
const PEXELS_KEY = process.env.REACT_APP_PEXELS_KEY
const STORYBLOCKS_KEY = process.env.REACT_APP_STORYBLOCKS_KEY
const NODE_BACKEND_URL = process.env.REACT_APP_NODE_BACKEND_URL
const OPEN_AI_KEY = process.env.REACT_APP_OPEN_AI_KEY
const QUICKADS_REDIRECT_URL = process.env.REACT_APP_QA_REDIRECT_URL || 'https://dev-aivideos.quickads.ai/logout'
const REDIRECT_ON = process.env.REACT_APP_REDIRECT_ON
const IS_FREE_PLATFORM = process.env.REACT_APP_IS_FREE_PLATFORM ? process.env.REACT_APP_IS_FREE_PLATFORM : false
export {
  NODE_ENV,
  POLOTNO_KEY,
  ELEVEN_LABS_KEY,
  PIXABY_KEY,
  PEXELS_KEY,
  STORYBLOCKS_KEY,
  NODE_BACKEND_URL,
  OPEN_AI_KEY,
  QUICKADS_REDIRECT_URL,
  REDIRECT_ON,
  IS_FREE_PLATFORM,
}
