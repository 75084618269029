export const getVideoDurationInMilliseconds = (videoUrl: string): Promise<number> => {
    return new Promise((resolve, reject) => {
      const videoElement = document.createElement('video');
      videoElement.src = videoUrl;
  
      const handleLoadedMetadata = () => {
        const durationInMilliseconds = videoElement.duration * 1000;
        resolve(durationInMilliseconds);
      };
  
      const handleError = (error: Event) => {
        reject(new Error('Failed to load video metadata'));
      };
  
      videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
      videoElement.addEventListener('error', handleError);
    });
  };
  
  // Usage example
//   const videoUrl = 'https://www.example.com/video.mp4';
  
//   getVideoDurationInMilliseconds(videoUrl)
//     .then(duration => {
//       console.log(`Video Duration: ${duration} milliseconds`);
//     })
//     .catch(error => {
//       console.error(error);
//     });
  