import { observer } from 'mobx-react-lite';
import { useGlobalContext } from '../../../../src/context/globalContext';
import { SectionTab } from 'polotno/side-panel';
import { SwapOutlined } from '@ant-design/icons';
import VariationsButton from './variationsButton';
import { FrameworkNames } from './frameworkNames';
import { Col, Result, Row, Tooltip, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { ScriptButton } from './scriptModal';
import axios from 'axios'
import { NODE_BACKEND_URL, IS_FREE_PLATFORM } from '../../../../src/config'
import { useGetBrandDetails } from '../../../../src/api/useBrandDetails';
import { ArrowDataTransferHorizontalIcon } from '@src/features/polotno/component/hugeIcons/hugeIcons';

interface Child {
  id: string;
  type: string;
  text?: string;
}

interface PageItem {
  id: string;
  type: string;
  name: string;
  duration: number;
  children: Child[];
}
interface JsonData {
  width: number;
  height: number;
  fonts: any[];
  pages: PageItem[];
}
interface Framework {
  framework: string;
  name: string;
  icon: any;
}
const videoVariations = observer(({ store }: any) => {
  const [open, setOpen] = useState(false);
  const [selectedFramework, setSelectedFramework] = useState('');
  // const [alternateNewScripts, setAlternatNewScripts] = useState<any>([]);
  const [alternatNewSingleScript,setAlternatNewSingleScript]=useState<any>([]);
  const [loading, setLoading]=useState(false)
  const {
    ScriptDetails: [scriptDetails],
    UserDetail: [userDetails],
    IsLoading: [isLoading],
    VideoStyle: [videoStyle, setVideoStyle],
    ActiveSidePanel: [, setActiveSidePanel],


  } = useGlobalContext();
  useEffect(()=>{
    setActiveSidePanel(store.openedSidePanel)
  },[])
  const localEndPoint = axios.create({
    baseURL: `${NODE_BACKEND_URL}`,
  })
  const { data: brandDetails, error: brandError} = useGetBrandDetails(userDetails?.brand_id)
  const handleClick = (framework: any, extraPrompt:string) => {
    setSelectedFramework(framework);
    setLoading(true)
    const postBody = {
      brandName: brandDetails?.name || 'Check from the description provided or go without brand name',
      brandDescription: userDetails?.purpose?.brandDescription || scriptDetails.data.map((data: { voiceOver: string }) => data.voiceOver).join(' '),
      duration: userDetails?.purpose?.video_duration || '15-30 sec',
      language: userDetails?.purpose?.language,
      video_framework: framework,
      extraPrompt:extraPrompt
    };
    const mediaType = userDetails?.purpose.mediaType
    if(!userDetails?.purpose.promptToVideo){
      if (videoStyle === 'Simple') {
        if(mediaType === 'AiImages' || mediaType === 'Dalle'){
          return localEndPoint.post('/api/v1/openAi/ai-randomscript-generation', postBody)
          .then(response => {
            const result = response.data
            if(typeof(response.data) === 'string'){
              handleClick(framework, extraPrompt)
            }else{
              result.data.push({'framework': framework})
              setAlternatNewSingleScript(result.data)
              setOpen(true);
              setLoading(false)
            }
            })
          .catch(error => {
           console.log(error)
           handleClick(framework, extraPrompt)
  
          });
        }
        else{
          
          return localEndPoint.post('/api/v1/openAi/randomscript-generation', postBody)
          .then(response => {
            const result = response.data
            if(typeof(response.data) === 'string'){
              handleClick(framework, extraPrompt)
            }else{
              result.data.push({'framework': framework})
              setAlternatNewSingleScript(result.data)
              setOpen(true);
              setLoading(false)
            }
            })
          .catch(error => {
           console.log(error)
           handleClick(framework, extraPrompt)
  
          });
        }
        
      }
      if(videoStyle === 'Burst'){
        if(mediaType === 'AiImages' || mediaType === 'Dalle'){
          return localEndPoint.post('/api/v1/openAi/ai-burstscript-generation', postBody)
          .then(response => { 
            const result = response.data
            if(typeof(response.data) === 'string'){
              handleClick(framework, extraPrompt)
            }else{
              result.data.push({'framework': framework})
              setAlternatNewSingleScript(result.data)
              setOpen(true);
              setLoading(false)
            }
          })
          .catch(error => {
           console.log(error)
           handleClick(framework, extraPrompt)
    
          });
        }else{
          return localEndPoint.post('/api/v1/openAi/burstscript-generation', postBody)
          .then(response => { 
            const result = response.data
            if(typeof(response.data) === 'string'){
              handleClick(framework, extraPrompt)
            }else{
              result.data.push({'framework': framework})
              setAlternatNewSingleScript(result.data)
              setOpen(true);
              setLoading(false)
            }
          })
          .catch(error => {
           console.log(error)
           handleClick(framework, extraPrompt)
    
          });
        }
       
      }
    }else{
      
        const postBody = {
          prompt: userDetails?.purpose?.prompt || scriptDetails.data.map((data: { voiceOver: string }) => data.voiceOver).join(' '),
          duration: userDetails?.purpose?.video_duration || '15-30 sec',
          language: userDetails?.purpose?.language,
          video_framework: framework,
        };
        if(mediaType === 'AiImages' || mediaType === 'Dalle'){
          return localEndPoint.post('/api/v1/openAi/ai-prompt-script-generation-video-variations', postBody)
          .then(response => { 
            
            const result = response.data
            if(typeof(response.data) === 'string'){
              handleClick(framework, extraPrompt)
            }else{
              result.data.push({'framework': framework})
              setAlternatNewSingleScript(result.data)
              setOpen(true);
              setLoading(false)
            }
          
          })
          .catch(error => {
           console.log(error)
           handleClick(framework, extraPrompt)
    
          });
        }else{
          return localEndPoint.post('/api/v1/openAi/prompt-script-generation-video-variations', postBody)
          .then(response => { 
            
            const result = response.data
            if(typeof(response.data) === 'string'){
              handleClick(framework, extraPrompt)
            }else{
              result.data.push({'framework': framework})
              setAlternatNewSingleScript(result.data)
              setOpen(true);
              setLoading(false)
            }
          
          })
          .catch(error => {
           console.log(error)
           handleClick(framework, extraPrompt)
    
          });
        }
        
      // }
    }
   

    // setAlternatNewSingleScript(obj.slice(0, -1));
  };
  const style: React.CSSProperties = {
    position: 'relative',
    overflowY: loading ? 'hidden' : 'auto',
    overflowX: loading ? 'hidden' : 'auto',
    height: 'calc(100vh - 100px)',
    paddingTop: '5px',
  };
  return (
    <>
      <div
        style={style}
      >
         {loading && (
        <div style={{
          position: 'absolute', top: 0, left: 0, width: '100%', height: '100%',
          backgroundColor: 'rgba(226, 232, 240, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 10
        }}>
          <Spin size="large" />  
        </div>
      )}
        <Row style={{ marginTop: '20px' }} gutter={6}>
          {FrameworkNames.map((framework:any, index:any) => (
            <Col key={index} span={12} style={{ marginTop: '10px' }}>
              <VariationsButton
                store={store}
                key={index}
                text={framework.name}
                open={open}
                setOpen={setOpen}
                onClick={() => handleClick(framework.framework, framework.prompt)}
                previewIcon={framework.icon}
                toolTip={framework.tip}
              />
            </Col>
          ))}
          <ScriptButton
            store={store}
            alternateNewScript={alternatNewSingleScript}
            open={open}
            setOpen={setOpen}
            variants={true}
          />
        </Row>
    </div>
    </>
  );
});

export const customVideoVariations = {
  name: 'video-variations',
  Tab: (props: any) => (
    <SectionTab name='Video Variations' {...props}>
      <ArrowDataTransferHorizontalIcon />
    </SectionTab>
  ),
  Panel: videoVariations,
};
