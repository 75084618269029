import { Card, Input, Spin } from "antd";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useGlobalContext } from "@src/context/globalContext";
import VideoDurationSetting from "../../sceneSettings/videoTrimmerSetting";
import VideoTrimmer from "@src/features/polotno/videoTrimmer/videoTrimmer";
import { getVideoPreview } from "polotno/utils/video";
import { Pages } from "polotno/pages-timeline";
import {
  Message01Icon,
  ViewIcon,
  ViewOffSlashIcon,
} from "@src/features/polotno/component/hugeIcons/hugeIcons";

const CaptionsTab = ({ store }: any) => {
  
  const [activePageId, setActivePageId] = useState("");
  const [captionWords, setCaptionWords] = useState([]);
  const [textoverlay, setTextOverlay] = useState("");
  const [textOverlayId, setTextOverlayId] = useState("");
  const [videoSrc, setVideoSrc] = useState("");
  const [elementId, setElementId] = useState("");
  const [sceneElementType, setElementType] = useState("");
  const [newString, setNewString] = useState("");

  const [editableWordIndex, setEditableWordIndex] = useState(null);
  const [editedWord, setEditedWord] = useState("");

  const childs: any = [];

  const {
    ScriptDetails: [scriptDetails, setScriptDetails],
    ShowTextOverlay: [showTextOverlay, setShowTextOverlay],
    ActiveIndex: [activeIndex, setActiveIndex],
    ActiveElementId: [activeElementId, setActiveElementId],
    ActiveSearchQueries: [activeSearchQueries, setActiveSearchQueries],
  } = useGlobalContext();
  useEffect(() => {
 
    setActivePageId(store.activePage.id);
  }, [store, store.activePage]);

  useEffect(() => {
    const storeJson = store.toJSON();
    if (
      storeJson.pages.length > 0 &&
      scriptDetails &&
      scriptDetails.data[scriptDetails.data.length - 1].captions
    ) {
      store.setScale(0.25);
      setActivePageId(store.activePage.id);
      store.pages.map((page: any, index: any) => {
        if (page.id === store.activePage.id) {
          setActiveIndex(store.activePage.custom.scriptIndex || index);
          setActiveSearchQueries(
            scriptDetails.data[store.activePage.custom.scriptIndex || index]
              ?.searchQueries ||
              scriptDetails.data[store.activePage.custom.scriptIndex || index]
                ?.images[0].searchQueries
          );
      
          setCaptionWords(
            scriptDetails.data[store.activePage.custom.scriptIndex || index]
              ?.captions?.[0]?.words
          );
        }
      });

      setActiveElementId(store.activePage?.children[0].id);
      store.activePage?.children?.map((child: any, index: any) => {
        childs.push(child.type);
        if (child.name === "h1-notlinked") {
          setTextOverlay(child.text);
          setTextOverlayId(child.id);
        }
        if (child.type == "video") {
          setVideoSrc(child.src);
          setElementId(child.id);
          setElementType("video");
          setActiveElementId(child.id);
        }
      });
      if (!childs.includes("video")) {
        setVideoSrc("");
        setElementId("");
        setElementType("image");
      }
      setNewString(
        store.activePage?.custom?.captions?.[0]?.words
          .map((item: any) => item.word)
          .join(" ")
      );
    }
  }, [store.activePage, store, scriptDetails]);

  const handleToggleSubtitle = () => {
    if (scriptDetails.showSubtitles) {
      setScriptDetails((prevScript: { data: any }) => {
        const updatedScript = { ...prevScript };

        return { ...updatedScript, showSubtitles: false };
      });
      store.pages.map((page: any) => {
        const elementID = "captionText" + page.id;
        page.set({ custom: { ...page.custom, showSubtitles: false } });
        if (store.getElementById(elementID)) {
          store.getElementById(elementID).set({
            opacity: 0,
            backgroundEnabled: false,
          });
        }
      });
    } else {
      setScriptDetails((prevScript: { data: any }) => {
        const updatedScript = { ...prevScript };

        return { ...updatedScript, showSubtitles: true };
      });
      store.pages.map((page: any) => {
        const elementID = "captionText" + page.id;
        page.set({ custom: { ...page.custom, showSubtitles: true } });
        if (store.getElementById(elementID)) {
          store.getElementById(elementID).set({
            opacity: 1,
            backgroundEnabled: true,
          });
        }
      });
    }
  };
  const colStyle: React.CSSProperties = {
    backgroundColor: "var(--color-primary-100)",
    borderRadius: "10px",
    height: "100%", // Ensure the columns stretch to the row's height
  };
  const handleInputChange = (e: any) => {
    const updatedWords: any = [...captionWords];

    // Update the specific element at the given index
    updatedWords[editableWordIndex || 0] = {
      ...updatedWords[editableWordIndex || 0],
      word: e.target.value,
    };

    setCaptionWords(updatedWords);
    setEditedWord(e.target.value);
    store.activePage.set({
      custom: {
        ...store.activePage.custom,
        captions: [
          {
            text: store.activePage.custom.captions?.[0].text,
            words: updatedWords,
          },
        ],
      },
    });

    setScriptDetails((prevScript: { data: any }) => {
      const updatedScript = { ...prevScript };
      const updatedData = [...(prevScript.data ? prevScript.data : prevScript)];
      updatedData[activeIndex || 0] = {
        ...updatedData[activeIndex || 0],
        captions: scriptDetails.data[activeIndex || 0].captions?.[0]?.words
          ? [{ words: updatedWords, text: newString }]
          : [],
      };
      return { ...updatedScript, data: updatedData };
    });
  };
  const handleInputBlur = (index: any) => {
    setEditableWordIndex(null);
  };
  const handleEditClick = (index: any, word: any) => {
    setEditableWordIndex(index);
    setEditedWord(word);
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "5px", paddingTop: "15px" }}>
     
      <div>
     
       
         <div
            style={{
              ...colStyle,
              display: "flex",
              alignItems: "center",
              height: "100%", // Ensure the inner div also takes full height
              padding: "5px",
              overflowX: "auto",
            }}
            className="custom-scrollbar"
          >
            {captionWords?.map((data: any, index: any) => (
              <div
                key={index}
                style={{
                  marginRight: "10px",
                  backgroundColor: "var(--color-primary-200)",
                  borderRadius: "10px",
                  height: "100%",
                  display: "flex", // Use flexbox for centering
                  justifyContent: "center", // Horizontally center the text
                  alignItems: "center", // Vertically center the text
                }}
              >
                {editableWordIndex === index ? (
                  <Input
                    value={editedWord}
                    // onChange={handleInputChange}
                    onChange={(e) => handleInputChange(e)}
                    onBlur={() => handleInputBlur(index)}
                    // style={{ margin: 0, fontSize: "12px", padding: "5px" }}
                    style={{
                      margin: 0,
                      fontSize: "12px",
                      padding: "15px", // Match padding of h2
                      border: "none", // Remove border
                      outline: "none", // Remove outline on focus
                      backgroundColor: "transparent", // Match the background
                      borderRadius: "10px", // Match the rounded corners
                      width: "auto", // Auto width to fit text length
                      minWidth: "50px", // Optional: Minimum width to avoid collapsing
                    }}
                    autoFocus
                    // autoFocus
                  />
                ) : (
                  <h2
                    style={{
                      margin: 0,
                      fontSize: "12px",
                      padding: "15px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleEditClick(index, data?.word)} // Allow editing on click
                  >
                    {data?.word}
                  </h2>
                )}
              </div>
            ))}
          </div>
      </div>
    
    </div>
  );
};

export default observer(CaptionsTab);
