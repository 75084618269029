import { CheckSquareOutlined, PlusSquareOutlined, LoadingOutlined } from '@ant-design/icons'
import { orientationList } from '../../../data/orientation'
import { generateUniqueId } from '../../../shared/utils/core'

import { Button, Flex, Result, Select, message } from 'antd'
import { getImageSize } from 'polotno/utils/image'
import { getVideoSize } from 'polotno/utils/video'
import { useState } from 'react'
import { getVideoDurationInMilliseconds } from '../../../shared/utils/VideoGeneration/videoDuration'
import { animationFunc, animationFuncCustomeOne } from '@src/shared/utils/VideoGeneration/helperFunctions/animations'
import { useGlobalContext } from '@src/context/globalContext'


type videoSearchProps = {
  title: string
  transformedElementsArray: any
  isLoading: boolean
  store: any
  elementID?: string
  isGettyImage?: boolean
  dropDownValue?: string
  setSelectedValue?: any
  isNotPanel?: boolean
  index?: number 
}

const { Option } = Select

// Assuming you want to use the Remotion Player for previewing the video
export const VideoSearch = ({
  transformedElementsArray,
  isLoading,
  store,
  title,
  elementID,
  isGettyImage,
  dropDownValue,
  setSelectedValue,
  isNotPanel,
  index,
}: videoSearchProps) => {
  const [visible, setVisible] = useState(false)
  const [video, setVideo] = useState({})
  // console.log(transformedElementsArray)
  const handleOrientationChange = (value: string) => {
    setSelectedValue(value)
  }
  const {
  
    ActiveElementId: [activeElementId, setActiveElementId],
  
  } = useGlobalContext();
  // const reverseArray=transformedElementsArray.reverse()
  return (
    <>
      <div style={{ overflowY: 'auto', height: elementID ? 'calc(80vh - 100px)' : 'calc(90vh - 100px)' }}>
        <Flex vertical justify='space-around' style={{ gap: '10px' }}>
          {isGettyImage && (
            <Select
              style={{ width: '50%', border: '2px solid rgb(235, 233, 254)', borderRadius: '5px' }}
              placeholder='Select Orientation'
              optionFilterProp='children'
              onChange={handleOrientationChange}
              value={dropDownValue}
              dropdownRender={(menu) => <div>{menu}</div>}
            >
              {orientationList.map((data, index) => (
                <Option key={index} value={data.value}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {data.value}
                  </div>
                </Option>
              ))}
            </Select>
          )}
          {(transformedElementsArray?.length === 0 || !transformedElementsArray) && !isLoading && (
            <p>Could not Find any Videos / Images. Please Change your search query</p>
          )}
          <h2
            style={{
              color: 'var(--color-primary-600)',
              fontSize: '16px',
              fontWeight: 700,
              letterSpacing: 'normal',
              marginBottom: '10px',
            }}
          >
            {title}
          </h2>
          {transformedElementsArray && transformedElementsArray.length > 0 && (
            <Flex wrap='wrap' gap='small'>
              {transformedElementsArray.map((item: any) => {
               
                return (
                  <div style={{ width: '45%', position: 'relative' }} key={item.id}>
                  
                    {item.type === 'image' && (
                      <>
                      {item.image_url?.length>1 ? (
                            <img
                            src={item.image_url}
                            alt='video'
                            style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
                            height='auto'
                            width='100%'
                            />
                      ) : (
                        <LoadingOutlined style={{ fontSize: '25px' }} />
                      )}
                      </>
                    )}  
                    {(item.type === 'video' && item.video_url) && (
                      <video
                        key={item.id}
                        height='auto'
                        width='100%'
                        src={item.video_url}
                        style={{ objectFit: 'contain' }}
                        controls
                      />
                    )}
                    {isNotPanel === true && (
                      <>
                     {(item.image_url || item.video_url) && (
                            <CheckSquareOutlined
                            style={{
                              color: 'var(--color-primary-600)',
                              background: 'white',
                              marginLeft: '8px',
                              marginRight: '5px',
                              fontSize: '20px',
                              position: 'absolute',
                              padding: '10px',
                              borderRadius: '50%',
                              top: 0,
                              right: 0,
                              margin: '8px', // Adjust margin as per your design
                              transition: 'filter 0.3s ease', // Add transition for smooth effect
                            }}
                            title='Change Video'
                            onClick={async () => {
                              setVideo(item)
                              if (elementID) {
                                setVisible(true)
                                return
                              }
                              const video = item.video_url
                              const type = item.type
                              let { width, height } = await getVideoSize(video)
                              const scale = Math.min(store.width / width, store.height / height, 1)
                              width = width * scale
                              height = height * scale
                            }}
                            />
                     )}
                     
                       </>
                    )}
                    {!isNotPanel && (
                      <PlusSquareOutlined
                        style={{
                          color: 'var(--color-primary-600)',
                          background: 'white',
                          marginLeft: '8px',
                          marginRight: '5px',
                          fontSize: '20px',
                          position: 'absolute',
                          padding: '10px',
                          borderRadius: '50%',
                          top: 0,
                          right: 0,
                          margin: '8px', // Adjust margin as per your design
                          transition: 'filter 0.3s ease', // Add transition for smooth effect
                        }}
                        title='Change Video'
                        onClick={async () => {
                       
                          // const element = store.getElementById(elementID)
                          // const type = item.type
                          // if (item.type === 'image') {
                          //   element.set({
                          //     src: item.image_url,
                          //     type: 'image',
                          //   })
                          //   return
                          // }
                          // if (!item.video_url) {
                          //   message.error('Video not found')
                          //   setVisible(false)
                          //   return
                          // }
                          // let videoDuration = 0
                          // try{
                          //   videoDuration = await getVideoDurationInMilliseconds(item.video_url)
                          // }catch(error){
                          //   console.log(error)
                          // }
                          // console.log(videoDuration)
                          //  let { width, height } = await (type === 'image' ? getImageSize(item.image_url) : getVideoSize(item.video_url))
                         
                          // const scale = Math.min(store.width / width, store.height / height, 1)
                          // width = width * scale
                          // height = height * scale
                          // element.set({
                          //   src: item.video_url,
                          //   type: 'video',
                          //   // width: width,
                          //   // height: height,
                          //   startTime:0,
                          //   endTime:1,
                          //   duration:videoDuration == 0 ? 20000 : videoDuration,
                          //   custom: {gettyId:item.id, duration: videoDuration == 0 ? 20000 : videoDuration, startTime:0, endTime:1}
                          // })
                          // ------
                          const type = item.type
                          const video = type === 'video' ? item.video_url : item.image_url
                        
                          let videoDuration = 0
                          if(type ==='video' ){
                            try{
                              videoDuration = await getVideoDurationInMilliseconds(video)
                            }catch(error){
                              console.log('Error getting video duration')
                            }
                           
                          }
                        
                          let { width, height } = await (type === 'image' ? getImageSize(video) : getVideoSize(video))
                          const mediaId=generateUniqueId()
                          const scale = Math.min(store.width / width, store.height / height, 1)
                          width = width * scale
                          height = height * scale
                          store.activePage.addElement({
                            type: type,
                            src: type==='image' ? item.image_url : item.video_url,
                            x: -25,
                            y: -25,
                            width: store.width + 50,
                            height: store.height + 50,
                            id: mediaId,
                            custom: {gettyId:item.id, duration: videoDuration == 0 ? 20000 : videoDuration, startTime:0, endTime:1}
                          })
                          
                          setActiveElementId(mediaId)
                          store.deleteElements([elementID])
                          store.getElementById(mediaId).set({
                            animations: animationFuncCustomeOne((store.pages?.[index || 0] as { duration: number }).duration),
                          })
                          store.getElementById(mediaId).moveBottom()
                          setVisible(false);
                          // console.log(store.getElementById(elementID).toJSON())
                        }}
                      />
                    )}
                  </div>
                )
              })}
            </Flex>
          )}
        </Flex>
      </div>
      {visible && (
        <>
          {/* <div
            style={{
              background: 'rgba(0, 0, 0, 0.5)',
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 999,
            }}
          ></div> */}
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              translate: 'all 1s ease',
              zIndex: 1001,
            }}
          >
            <ResultModal visible={visible} setVisible={setVisible} video={video} store={store} elementID={elementID} index={index}/>
          </div>
        </>
      )}
    </>
  )
}

export const ResultModal = ({ visible, setVisible, video, store, elementID, index }: any) => {
  const {
  
    ActiveElementId: [, setActiveElementId],
  
  } = useGlobalContext();
  const handleReplace = async () => {
    const element = store.getElementById(elementID)
    let { width, height } = await (video.type === 'image' ? getImageSize(video.video_url) : getVideoSize(video.video_url))
    const scale = Math.min(store.width / width, store.height / height, 1)
    width = width * scale
    height = height * scale
 
    if (video.type === 'image') {
      const videoId = generateUniqueId()
      store.activePage.addElement({
        src: video.video_url,
        type: 'image',
        x: -25,
        y: -25,
        width: store.width + 50,
        height: store.height + 50,
        id: videoId,
        // duration:videoDuration == 0 ? 20000 : videoDuration,
        custom: {gettyId:video.id}
      })
      setActiveElementId(videoId)
      console.log(elementID)
      store.deleteElements([elementID])
      store.getElementById(videoId).set({
        animations: animationFuncCustomeOne(store.pages[index||0].duration),
      })
      store.getElementById(videoId).moveBottom()
      // console.log(store.getElementById(elementID).toJSON())
      setVisible(false)
      store.openSidePanel("none");
      store.openSidePanel("video-editor")
      console.log(store.toJSON())
      return
    }
    if (!video.video_url) {
      message.error('Video not found')
      setVisible(false)
      store.openSidePanel("none");
      store.openSidePanel("video-editor")
      return
    }
    let videoDuration = 0
    try{
      videoDuration = await getVideoDurationInMilliseconds(video.video_url)
    }catch(error){
      console.log(error)
    }
   console.log(video.id)
    const videoId = generateUniqueId()
    store.activePage.addElement({
      src: video.video_url,
      type: 'video',
      x: -25,
      y: -25,
      width: store.width + 50,
      height: store.height + 50,
      id: videoId,
      duration:videoDuration == 0 ? 20000 : videoDuration,
      custom: {gettyId:video.id, duration: videoDuration == 0 ? 20000 : videoDuration, startTime:0, endTime:1}
    })
    
    setActiveElementId(videoId)
    store.deleteElements([elementID])
    store.getElementById(videoId).set({
      animations: animationFuncCustomeOne(store.pages[index||0].duration),
    })
    store.getElementById(videoId).moveBottom()
    setVisible(false)
    // store.openSidePanel("none");
    // store.openSidePanel("video-timeline")
    const elementtwo = store.getElementById(videoId)
    // console.log(elementtwo.toJSON())
    // store.openSidePanel("none");

  }

  const handleStay = () => {
    setVisible(false)
  }

  return (
    <div
      style={{
        padding: '20px',
        maxWidth: '300px',
        width: '90%',
        background: '#fff',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        zIndex: 1001,
        translate: 'enter fade 1s ease',
      }}
    >
      <Result
        status='info'
        subTitle='Are you sure you want to replace the current visual?'
        style={{ color: 'var(--color-primary-600)', fontSize: '16px', fontWeight: 500, letterSpacing: 'normal', marginBottom: '10px'}}
        extra={[
          <Button
            style={{ minWidth: '120px', marginBottom: '10px' }}
            type='primary'
            key='goNewProject'
            onClick={handleReplace}
          >
            Replace
          </Button>,
          <Button style={{ minWidth: '120px' }} key='stay' onClick={handleStay}>
            Exit
          </Button>,
        ]}
      />
    </div>
  )
}
