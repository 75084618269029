/* eslint-disable react/jsx-no-undef */

import { Select } from 'antd';
import { useEffect, useState } from 'react';

import {
  animationFuncCustomeOne,
  animationFuncCustomeOne3D,
  animationFuncCustomeOneZoomOutMove,
  animationFuncMoveZoom,
  animationList,
} from '@src/shared/utils/VideoGeneration/helperFunctions/animations';

const { Option } = Select;

interface ResizeDropdownProps {
  store: any;
}

export const AnimationDropdown = ({ store }: ResizeDropdownProps) => {

  const [selectedValue, setSelectedValue] = useState(store?.pages[0]?.children[0]?.animations?.[0]?.name || 'Select Animation');

  const handleAnimationValueChange = async (value: string) => {
    setSelectedValue(value)
    store.pages.map((page: any) => {
      page.children.map((child: any) => {
        if (child.type === 'image' || child.type === 'video') {
          if (value === 'zoom' || value === 'move') {
            store.getElementById(child.id).set({
              animations: animationFuncMoveZoom(value, page.duration),
            });
          }
          if (value === '3d-perspective') {
            store.getElementById(child.id).set({
              animations: animationFuncCustomeOne3D(page.duration),
            });
          }
          if (value === 'move-zoom') {
            store.getElementById(child.id).set({
              animations: animationFuncCustomeOneZoomOutMove(
                value,
                page.duration
              ),
            });
          }
          if (value === 'zoomIn-out') {
            store.getElementById(child.id).set({
              animations: animationFuncCustomeOneZoomOutMove(
                value,
                page.duration
              ),
            });
          }
        }
      });
    });
  };
  useEffect(() => {
  
    if (store?.pages?.[0]?.children?.[0]?.animations?.[0]?.name) {
      setSelectedValue(store?.pages?.[0]?.children?.[0]?.animations?.[0]?.name);
    }
  }, [store]);
  return (
    <div
      style={{
        width: '20%',
        marginTop: '8px',
        marginLeft: '20px',
        marginBottom: '8px',
        // background: 'white',
      }}
    >
      <Select
        style={{
          width: '50%',
          border: '2px solid rgb(235, 233, 254)',
          borderRadius: '5px',
        }}
        placeholder='Select Animation'
        optionFilterProp='children'
        onChange={handleAnimationValueChange}
        value={selectedValue}
        dropdownRender={(menu) => <div>{menu}</div>}
      >
        <Option>Select Animation</Option>
        {animationList.map((data, index) => (
          <Option key={index} value={data.value}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {data.name}
            </div>
          </Option>
        ))}
      </Select>
    </div>
  );
};
