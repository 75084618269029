import { NODE_BACKEND_URL } from '../../config'
import axios from 'axios'
import { useQuery } from 'react-query'
import { storyBlocksExpires, storyBlocksHmac } from '../../config/storyblocks'

export const useGetStoryblocksVideos = (
  query = '', // Search query, default empty to fetch random videos
  perPage = 20, // Default number of videos per page
  page = 1 // Page number for pagination
) => {
  return useQuery(
    ['fetchStoryBlocksVideos', query, page], // Query key, includes dependencies
    async () => {
      const response = await axios.get(NODE_BACKEND_URL + 'api/v1/storyblocks/videoSearch', {
        params: {
          EXPIRES: storyBlocksExpires,
          HMAC: storyBlocksHmac,
          project_id: 1,
          user_id: 1,
          page: page,
          results_per_page: perPage,
          keywords: query.replace(/\s/g, ','), // Replace spaces with ',' for keyword encoding
          searchQuery: query,
        },
      })
      return response.data
    },
    {
      // Optional settings, for example:
      enabled: !!query, // Only run query if a search term is provided
      // keepPreviousData: true, // Keep previous data while fetching the next page for pagination
      // staleTime: 5 * 60 * 1000, // Data freshness duration
    }
  )
}
