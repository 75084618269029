import { Alignment, Button, Navbar } from '@blueprintjs/core'
import { useGlobalContext } from '../../../../../src/context/globalContext'
import { redoScript, undoScript } from '../../../../../src/shared/utils/polotno/pageOperations'
import { observer } from 'mobx-react-lite'
import { StoreType } from 'polotno/model/store'

/* 
  This component provides custom undo and redo functionality for the project using a global context and MobX for state management. 
  It integrates with the Polotno store to manage page operations and script adjustments, ensuring that all changes are synchronized 
  across the project's script. The buttons interact directly with the project's history, allowing users 
  to navigate through their actions efficiently. This customization enhances the default Polotno functionality to fit or specific needs.
*/

export const CustomHistoryButtons = observer(({ store }: { store: StoreType }) => {
  const {
    ScriptDetails: [scriptDetails, setScriptDetails],
    UndoStack: [undoStack, setUndoStack],
    RedoStack: [redoStack, setRedoStack],
    IsStoreUpdated: [isStoreUpdated, setIsStoreUpdated],
    VideoStyle: [videoStyle],
  } = useGlobalContext()

  return (
    <Navbar.Group align={Alignment.LEFT}>
      <Button
        icon='undo'
        minimal
        onClick={() => {
          setIsStoreUpdated(!isStoreUpdated)
          undoScript(
            undoStack,
            setScriptDetails,
            setUndoStack,
            setRedoStack,
            store.pages.length,
            videoStyle,
            scriptDetails
          )
          store.history.undo()
        }}
        disabled={!store.history.canUndo && undoStack.length === 0}
      />
      <Button
        icon='redo'
        minimal
        onClick={() => {
          setIsStoreUpdated(!isStoreUpdated)
          redoScript(
            undoStack,
            redoStack,
            setScriptDetails,
            setUndoStack,
            setRedoStack,
            store.pages.length,
            videoStyle,
            scriptDetails
          )
          store.history.redo()
        }}
        disabled={!store.history.canRedo && redoStack.length === 0}
      />
    </Navbar.Group>
  )
})
