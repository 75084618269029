import { LoadingOutlined, PlayCircleFilled, PlayCircleOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons'
import { getAudioDurationInSeconds } from '@remotion/media-utils'
import { getDomainID, getToken } from '../../../../../src/config/config'
import { openAIVoicesList, whisperAiVoiceList } from '../../../../../src/data/music'
import { prefetchAsset } from '../../../../../src/shared/utils/remotion'
import { Button, Col, Input, Row, Select, Tag, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useGlobalContext } from '../../../../context/globalContext'
import { aiTextToSpeech, generateCaptions, getPrevNextText } from '../../../../../src/shared/utils/VideoGeneration/audioGeneration'
import { IS_FREE_PLATFORM } from '../../../../../src/config'
import { correctSpelling } from '@src/shared/utils/captionCorrection/captionCorrection'
import { creditsDeduct, getUserDetails } from '@src/shared/utils/VideoGeneration/helperFunctions/mediaSearch'

interface AiVoiceRowProps {
  index: number
  aiVoice: any
  store: any
  voiceOverVolume: number
  globalVoice: any
  showPanel: boolean
}

const labelTypeColors: { [key: string]: string } = {
  accent: 'magenta',
  description: 'green',
  age: 'cyan',
  gender: 'volcano',
  'use case': 'blue',
}

export const AiVoiceRow = ({ index, aiVoice, store, voiceOverVolume, globalVoice, showPanel }: AiVoiceRowProps) => {
  const {
    ScriptDetails: [scriptDetails, setScriptDetails],
    VideoStyle: [videoStyle, setVideoStyle],
    CurrentUserData: [currentUserData, setCurrentUserData],
    UserDetail: [userDetail, setUserDetail]
  } = useGlobalContext()
  const [playingUrl, setPlayingUrl] = useState('')
  const [selectedVoiceName, setSelectedVoiceName] = useState(`${scriptDetails.data[index]?.name}`)
  const [isAiAudioLoading, setIsAiAudioLoading] = useState<boolean>(false)
  // const [aiVoiceIdSelected, setAiVoiceIdSelected] = useState(`${aiVoice?.voices[0].voice_id}`)a
  const [aiVoiceAudioUrl, setAiVoiceAudioUrl] = useState(`${scriptDetails.data[index]?.url}`)
  const [selectedVoicePreview, setSelectedVoicePreview] = useState(
    aiVoice?.voices?.find((voice: any) => voice.voice_id === scriptDetails.data[index]?.elevenLabsAiVoiceId)?.preview_url
  )

  const [textUpdated, setTextUpdated] = useState(false)
  const [isElevenLabsApi, setIsElevenLabsApi] = useState(scriptDetails.data[index]?.isElevenApi)
  const [elevenLabsAiVoiceID, setElevenLabsAiVoiceID] = useState(`${scriptDetails.data[index]?.elevenLabsAiVoiceId}`)

  // Global Context

  // Ref to the audio player
  const audioRef = React.createRef<HTMLAudioElement>()

  // Handler to play the audio
  const handlePlayAudio = (previewUrl: string) => {
    // If there's an audio element and it's already playing, pause it first.
    if (audioRef.current) {
      if (!audioRef.current.paused) {
        audioRef.current.pause()
      }
      audioRef.current.src = previewUrl // Set the new source for the audio element
      audioRef.current.play().catch((e) => console.error('Playback was interrupted:', e))
    }
  }

  const playAiVoice = (url: string) => {
    const audioPlayer = new Audio(url)
    audioPlayer
      .play()
      .then(() => {
        // Audio playback started
      })
      .catch((e) => {
        console.error('Error playing audio:', e)
      })
  }

  const handleAiVoiceDropDownChange = async (value: string) => {
    console.log('-hello')
    const getCredits = await getUserDetails();
    if (getCredits == -1) {
      message.warning('Something went wrong!');
      return;
    }
    if (getCredits?.voice_limit < 1) {
      message.warning(
        'Voice credits have been exhausted. Please upgrade the plan to use the voice.'
      );
     
      return;
    }
    const voice = aiVoice?.voices?.find((voice: any) => voice.voice_id === value)
    console.log(voice)
    if (voice) {
      setIsElevenLabsApi(true)
      setElevenLabsAiVoiceID(value)
      setSelectedVoiceName(voice.name)
      setSelectedVoicePreview(voice.preview_url)
      playVoiceSample(
        index < scriptDetails.data.length ? scriptDetails.data[index]?.voiceOver : '',
        voice.name,
        true,
        value
      )
    } else {
      setIsElevenLabsApi(false)
      setSelectedVoiceName(value)
      setSelectedVoicePreview('')
      playVoiceSample(
        index < scriptDetails.data.length ? scriptDetails.data[index]?.voiceOver : '',
        value,
        false,
        undefined
      )
    }
  }
  const handleAudioDownload = (audioUrl:string) => {
    // Create an anchor element
    const link = document.createElement("a");
    link.href = audioUrl; // The URL of the image you want to download
    link.download = 'Ai Voice'; // The name to save the file as
    link.click(); // Programmatically trigger the click
  };

  const playVoiceSample = async (
    text: string,
    aiVoiceName: string,
    isElevenApi: boolean,
    elevenlabsVoiceId: string | undefined
  ): Promise<any> => {
    try {
      videoStyle !== 'Burst' && store.selectPage(store.pages[index].id)

      setIsAiAudioLoading(true)

      let domainId
      let token

      if (IS_FREE_PLATFORM === 'false') {
        domainId = getDomainID()
        token = getToken()
      }
      const Prevnext=getPrevNextText(scriptDetails.data, index)
      const audioUrl = await aiTextToSpeech(text, elevenlabsVoiceId, domainId, aiVoiceName, isElevenApi, token, Prevnext.prevText, Prevnext.nextText, 50, 75)
      const duration = await getAudioDurationInSeconds(audioUrl) // Assuming `src` is the URL to your audio file
      const voiceOverTextArr=text.split(' ')
      let newDuration
      if (videoStyle !== 'Burst') {
        newDuration =
          duration * 1000 > store.pages[index].duration ? duration * 1000 + 500 : store.pages[index].duration + 500

        if (index === store.pages.length - 1) {
          newDuration = newDuration + 1000
        }
        store.activePage.set({ duration: newDuration })
      }

      let captionsResult: any
      if(text.length>0){
        try {
          captionsResult = await generateCaptions(audioUrl, userDetail?.purpose?.language || '')
          console.log(captionsResult)
        } catch (e) {
          message.info('Could not generate captions for the audio')
        }
      }else{
        console.log('empty url')
      }
    
      // const correctedCaptionResponse = correctSpelling(captionsResult.data.words, voiceOverTextArr);
      // console.log(correctedCaptionResponse)
      await setScriptDetails((prevScript: { data: any }) => {
        const updatedScript = { ...prevScript }

        const updatedData = [...prevScript.data]

        updatedData[index] = {
          ...updatedData[index],
          name: aiVoiceName,
          elevenLabsAiVoiceId: elevenlabsVoiceId,
          url: audioUrl,
          isElevenApi: isElevenApi,
          aiVoiceVolume: voiceOverVolume / 100,
          sounds: [{ url: audioUrl, audioDuration: duration }],
          captions: [{ text: text, words: captionsResult?.data?.words }],
        }

        return { ...updatedScript, data: updatedData }
      })

      if (videoStyle !== 'Burst') {
        store.pages[index].set({
          duration: newDuration,
          custom: {
            ...store.pages[index].custom,
            aiVoiceVolume: voiceOverVolume / 100,
            sounds: [{ url: audioUrl, audioDuration: duration }],
            captions: [{ text: text, words: captionsResult.data.words }],
          },
        })
      }

      setAiVoiceAudioUrl(audioUrl)

      await prefetchAsset(audioUrl)

      setIsAiAudioLoading(false)
    } catch (error) {
      console.error('Error making text-to-speech request:', error)
      setIsAiAudioLoading(false)
    }
  }
  const aiVoiceDropDown = [
    {
      label: 'AI Voices',
      options: whisperAiVoiceList.map((aiVoice) => ({
        label: <span>{aiVoice.label}</span>,
        value: aiVoice.value,
      })),
    },

  ]
  const subscriptionPlan = currentUserData?.subscription_plan
  
  // if(currentUserData?.subscription_plan !== 'free' || currentUserData?.subscription_plan !== 'quickads_tier2' || currentUserData?.subscription_plan !== 'quickads_tier1'){
    if (
      subscriptionPlan === 'free' ||
      subscriptionPlan === 'quickads_tier2' ||
      subscriptionPlan === 'quickads_tier1' 
    ) { 
    console.log()
  }else{

    aiVoiceDropDown.push( {
      label: 'Premium AI Voices',
      options: aiVoice?.voices
      ?.filter((voice: any) => voice.category !== 'cloned') // Filter voices based on category
      .map((voice: any) => ({
        label: (
          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>{voice.name}</span>
              <Button
                type='text'
                icon={<PlayCircleOutlined />}
                onClick={(e) => {
                  e.stopPropagation() // Prevent the select dropdown from closing
                  handlePlayAudio(voice.preview_url)
                }}
              />
            </div>
            <div className='tag-container'>
              {Object.entries(voice.labels).map(([key, value]) => (
                <Tag color={labelTypeColors[key] || 'default'} key={key}>
                  {`${value}`}
                </Tag>
              ))}
            </div>
          </div>
        ),
        value: voice.voice_id, // Value for each option
      })),
    },)
  }
const updateVoiceOver= async ()=>{
  const isOpenAIVoice = openAIVoicesList.includes(scriptDetails.data[index]?.name)
  console.log(scriptDetails.data[index]?.name)
  console.log(isOpenAIVoice)
  const getCredits = await getUserDetails();
  if (getCredits == -1) {
    message.warning('Something went wrong!');
    return;
  }
  if (getCredits?.voice_limit < 1) {
    message.warning(
      'Voice credits have been exhausted. Please upgrade the plan to use the voice.'
    );
   
    return;
  }
  playVoiceSample(
    scriptDetails.data[index]?.voiceOver,
    selectedVoiceName,
    !isOpenAIVoice,
    elevenLabsAiVoiceID
  )
  setTextUpdated(false)
  try{

    await creditsDeduct('voice_credits', 1)

  }catch(error){

    console.log('Something went wrong')
  }
}
  return (
    <>
      <Row gutter={[0, 0]} style={{ flexDirection: 'column' }}>
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <div>
            <h3 style={{ fontSize: '14px', fontWeight: 'normal' }}>Scene {index + 1}</h3>
          </div>

          {isAiAudioLoading ? (
            <LoadingOutlined
              style={{
                marginLeft: '10px',
                color: 'var(--color-primary-600)',
              }}
            />
          ) : (
            <div style={{display:'flex', justifyContent:'space-between', gap:'4px'}}>
           
            <PlayCircleFilled
              onClick={() => {
                // playVoiceSample(index < scriptDetails.data.length ? scriptDetails.data[index].voiceOver : '', )
                playAiVoice(aiVoiceAudioUrl)
              }}
              style={{
                marginLeft: '10px',
                color: 'var(--color-primary-600)',
                opacity: 1,
              }}
              className='icon-hover-effect'
            />
            {/* <VerticalAlignBottomOutlined 
            style={{cursor:'pointer'}}
            onClick={()=>handleAudioDownload(aiVoiceAudioUrl)}
             /> */}
             </div>
          )}
        </Col>
      </Row>
      <Row gutter={[0, 0]} style={{ flexDirection: 'column' }}>
        <Input
          placeholder='Type your own voice over'
          style={{
            width: '100%',
            marginRight: '10px',
          }}
          defaultValue={scriptDetails.data ? scriptDetails.data[index]?.voiceOver : ''}
          onChange={(e) => {
            if (scriptDetails.data[index]?.voiceOver !== e.target.value) {
              scriptDetails.data[index].voiceOver = e.target.value
              setTextUpdated(true)
            }
          }}
          className='hoverable-text-box' // Apply the CSS class here
        />
        {textUpdated && (
          <Button
            type='primary'
            style={{ marginBottom: '5px', borderRadius: '5px', width: '70%', fontSize: '12px', font: 'DM Sans' }}
            size='small'
            onClick={updateVoiceOver}
          >
            Update Voiceover
          </Button>
        )}
        <Col
          span={24}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Select
            style={{ width: '70%' }}
            placeholder='Select a voice'
            optionFilterProp='children'
            onChange={handleAiVoiceDropDownChange}
            value={selectedVoiceName}
            defaultValue={globalVoice}
            options={aiVoiceDropDown}
            dropdownRender={(menu) => (
              <div>
                {menu}
                <audio ref={audioRef} src={playingUrl} style={{ display: 'none' }} />
              </div>
            )}
          />
          {selectedVoicePreview ? (
            <div>
              <Button
                type='text'
                icon={<PlayCircleOutlined />}
                style={{ outline: 'None', marginLeft: '2px' }}
                onClick={(e) => {
                  e.stopPropagation() // Prevent the select dropdown from closing
                  handlePlayAudio(selectedVoicePreview)
                }}
              />
              <audio ref={audioRef} src={playingUrl} style={{ display: 'none' }} />
            </div>
          ) : (
            ''
          )}
        </Col>
      </Row>
    </>
  )
}
