import { ConsoleMessage } from "@remotion/renderer/dist/browser/ConsoleMessage";
import {
    TransitionPresentation,
    TransitionPresentationComponentProps,
  } from "@remotion/transitions";
  import { Audio } from "remotion";
  
  export function addSound<T extends Record<string, unknown>>(
    transition: TransitionPresentation<T>,
    src: string,
  ): TransitionPresentation<T> {
    const { component: Component, ...other } = transition;
  
    const C = Component as React.FC<TransitionPresentationComponentProps<T>>;
  
    const NewComponent: React.FC<TransitionPresentationComponentProps<T>> = (
      p,
    ) => {
      console.log(src)
      return (
        <>
          {p.presentationDirection === "entering" ? <Audio src={src} volume={1.0} playbackRate={2}/> : null}
          <C {...p} />
        </>
      );
    };
  
    return {
      component: NewComponent,
      ...other,
    };
  }