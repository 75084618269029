import JSZip from "jszip";
import { saveAs } from "file-saver";

type FileData = {
  name: string;
  url: string; // Direct file URL
};

const getExtensionFromContentType = (contentType: string | null): string => {
  if (!contentType) return "";
  const extensionMap: { [key: string]: string } = {
    "video/mp4": "mp4",
    "audio/mpeg": "mp3",
    "image/jpeg": "jpg",
    "image/png": "png",
    "image/svg": "svg",
  };
  return extensionMap[contentType] || "";
};

const fetchFileAsBlobWithContentType = async (url: string) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Failed to fetch ${url}`);
  }
  const contentType = response.headers.get("Content-Type");
  const blob = await response.blob();
  return { blob, contentType };
};
// Helper function to extract file extension from a URL
const getFileExtension = (url: string): string => {
  const parts = url.split(".");
  if (parts.length > 1) {
    return parts[parts.length - 1].split("?")[0]; // Handle query parameters if present
  }
  return ""; // Default to empty string if no extension is found
};

export const downloadFilesAsZip = async (
  audioFiles: FileData[],
  imageFiles: FileData[],
  videoFiles: FileData[]
) => {
  const zip = new JSZip();

  // Helper function to fetch file content from direct URL
  const fetchFileAsBlob = async (fileUrl: string): Promise<Blob> => {
    const response = await fetch(fileUrl);
    if (!response.ok) {
      throw new Error(`Failed to fetch file: ${fileUrl}`);
    }
    return await response.blob();
  };

  try {
    // Add audio files to the ZIP
    const audioFolder = zip.folder("audio")!;
    for (const file of audioFiles) {
      const fileBlob = await fetchFileAsBlob(file.url);
      const extension = getFileExtension(file.url);
      const fileName = `${file.name}.${extension}`;
      audioFolder.file(fileName, fileBlob);
    }

    // Add image files to the ZIP
    const imageFolder = zip.folder("images")!;
    for (const file of imageFiles) {
      const {
        blob: fileBlob,
        contentType,
      } = await fetchFileAsBlobWithContentType(file.url);
      //   const extension = getFileExtension(file.url);
      const extension = getExtensionFromContentType(contentType);
      const fileName = `${file.name}.${extension}`;
      imageFolder.file(fileName, fileBlob);
    }

    // Add video files to the ZIP
    const videoFolder = zip.folder("videos")!;
    for (const file of videoFiles) {
      //   const fileBlob = await fetchFileAsBlob(file.url);
      const {
        blob: fileBlob,
        contentType,
      } = await fetchFileAsBlobWithContentType(file.url);
      // First, try to get the extension from the URL
      let extension = getFileExtension(file.url);

      // If the extension isn't available in the URL, fall back to Content-Type
      if (!extension) {
        extension = getExtensionFromContentType(contentType);
      }

      // If we still don't have an extension, use a generic one or throw an error
      if (!extension) {
        console.warn(`Could not determine file extension for ${file.url}`);
        extension = "unknown"; // Or skip this file, depending on your needs
      }
      const fileName = `${file.name}.mp4`;
      videoFolder.file(fileName, fileBlob);
    }

    // Generate the ZIP file and download it
    const zipBlob = await zip.generateAsync({ type: "blob" });
    saveAs(zipBlob, "media_files.zip");
  } catch (error) {
    console.error("Error zipping and downloading files:", error);
  }
};

// Helper function to extract file extension from Content-Type
