import { AppstoreAddOutlined } from '@ant-design/icons'
import { QA_BACKEND_URL } from '../../../../config/config'
import { useGlobalContext } from '../../../../context/globalContext'
import { generateUniqueId } from '../../../../shared/utils/core'
import { addDefaultAnimations, updateTextElements } from '../../../../shared/utils/polotnoStore'
import { Flex, Select, Switch, type InputRef } from 'antd'
import { observer } from 'mobx-react-lite'
import { forEveryChild } from 'polotno/model/group-model'
import { ImagesGrid, SectionTab } from 'polotno/side-panel'

// import { useInfiniteAPI } from 'polotno/utils/use-api'
import React, { useEffect, useRef, useState } from 'react'

// this hook is similar to regular useEffect
// but it will be not called on initial mount
const useChange = (func: any, deps: any) => {
  const firstRun = useRef(true)
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false
      return
    }
    func()
  }, deps)
}

const Option = Select.Option

export const CustomTemplatesPanel = observer(({ store }: any) => {
  const {
    UserDetail: [userDetails, setUserDetails],
    UpdatedHeadings: [updatedHeadings, setUpdatedHeadings],
  } = useGlobalContext()

  const inputRef = useRef<InputRef>(null)
  const [replaceText, setReplaceText] = useState(true)
  const [addAnimations, setAddAnimations] = useState(true)

  const sharedProps = {
    style: { width: '100%' },
    ref: inputRef,
  }

  const {
    setQuery: setQuery,
    loadMore: loadMore,
    hasMore: hasMore,
    data: data,
    isLoading: isLoading,
    reset: reset,
    error: error,
  } = useInfiniteAPI({
    getAPI: ({ page, query }) =>
      // `${QA_BACKEND_URL}smp/get-json-templates?page=${page}&page_size=30&industry=${query}&size=${userDetails.purpose.size.width}x${userDetails.purpose.size.height}`,
      `${QA_BACKEND_URL}smp/get-json-templates?page=${page}&page_size=30&industry=${query}&size=1080x1920`,
    getSize: (res) => res.total,
  })

  useEffect(() => {
    setQuery('fashion')
  }, [userDetails.purpose.industry])

  const setQueryValue = (value: string) => {
    setQuery(value)
  }

  return (
    <Flex vertical justify='space-around' style={{ gap: '20px', marginTop: '10px' }}>
      <Flex style={{ gap: '20px' }}>
        <Select
          placeholder={'Others'}
          showSearch={true}
          allowClear={false}
          maxTagCount={'responsive'}
          defaultValue={'fashion'}
          style={{ width: '350px' }}
          onChange={(value) => {
            setQueryValue(value)
          }}
        >
          {optionsIndustry?.map((item: any, index: number) => (
            <Option key={item.key} value={item.value} label={item.label} data-testid={`${index}-option`}>
              {item.desc ? item.desc : item.label}
            </Option>
          ))}
        </Select>
      </Flex>
      <Flex style={{ gap: '20px', paddingLeft: '2px' }}>
        Replace Text
        <Switch
          value={replaceText}
          onChange={(checked) => {
            setReplaceText(checked)
          }}
        />
        Add Default Animations
        <Switch
          value={addAnimations}
          onChange={(checked) => {
            setAddAnimations(checked)
            if (checked) {
              addDefaultAnimations(store)
            }
          }}
        />
      </Flex>
      <div style={{ overflowY: 'auto', height: 'calc(90vh - 100px)' }}>
        <ImagesGrid
          shadowEnabled={false}
          images={data?.map((item) => item.data).flat()}
          getPreview={(item) => item?.image_url}
          isLoading={isLoading}
          onSelect={async (image) => {
            const result = image?.json_data
            setUserDetails({ ...userDetails, json_url: image.json })

            if (replaceText)
              updateTextElements(
                result.pages[0].children,
                updatedHeadings.heading,
                updatedHeadings.subHeading,
                updatedHeadings.thirdHeading,
                userDetails.font
              )
            // if (addAnimations) addDefaultAnimations(updatedTemplate.pages[0].children)

            // updateImageElement(result.pages[0].children, userDetails.image)
            // updateSvgElements(result.pages[0].children, userDetails.colors)

            if (store.pages.length <= 1) {
              store.loadJSON(result, true)
            } else {
              const oldJSON = JSON.parse(JSON.stringify(store.toJSON()))
              if (oldJSON.width !== result.width || oldJSON.height !== result.height) {
                result.pages.forEach((page: any) => {
                  page.width = page.width || result.width
                  page.height = page.height || result.height
                })
              }
              forEveryChild({ children: result.pages }, (item: any) => {
                item.id = generateUniqueId()
              })
              const index = store.pages.indexOf(store.activePage)
              oldJSON.pages.splice(index, 1, ...result.pages)
              store.loadJSON(oldJSON, true)
            }
          }}
          loadMore={hasMore && loadMore}
          error={error}
          rowsNumber={2}
        />
      </div>{' '}
    </Flex>
  )
})

export const CustomTemplatesSection = {
  name: 'custom-tempelates',
  Tab: (props: any) => (
    <SectionTab name='Ad Design' {...props}>
      <AppstoreAddOutlined />
    </SectionTab>
  ),
  Panel: CustomTemplatesPanel,
}

export const optionsIndustry = [
  {
    value: 'fashion',
    label: 'Fashion',
  },
  {
    value: 'e-commerce',
    label: 'E-commerce',
  },
  {
    value: 'travel',
    label: 'Travel & Tourism',
  },
  {
    value: 'real-estate',
    label: 'Real Estate',
  },
  {
    value: 'consumer-goods',
    label: 'Consumer Goods',
  },
  {
    value: 'food',
    label: 'Food & Restaurants',
  },
  {
    value: 'professional-services',
    label: 'Professional Services',
  },
  {
    value: 'software-it',
    label: 'Software & IT Industry',
  },
  {
    value: 'others',
    label: 'Other',
  },
]

import useSWRInfinite from 'swr/infinite'

export const fetcher = (url: RequestInfo | URL) => fetch(url).then((r) => r.json())

type GET_FUNC = (params: { query: string; page: number }) => string

export const useInfiniteAPI = ({
  defaultQuery = '',
  timeout = 100,
  getAPI,
  getSize = (firstResult) => firstResult.total_pages,
}: {
  defaultQuery?: string
  timeout?: number
  getAPI: GET_FUNC
  getSize?: (firstResult: any) => number
}) => {
  const lastQuery = React.useRef(defaultQuery)
  const requestTimeout = React.useRef<any>()

  const { data, error, size, setSize, mutate } = useSWRInfinite(
    (index) => getAPI({ query: lastQuery.current, page: index + 1 }),
    fetcher,
    { revalidateAll: false }
  )

  const isLoadingInitialData = !data && !error
  const isLoading = isLoadingInitialData || !!(size > 0 && data && typeof data[size - 1] === 'undefined')
  const isEmpty = data?.[0]?.length === 0
  const isReachingEnd = isEmpty || (data && data[0] && getSize(data[0]) === size)

  const loadMore = () => {
    if (!isReachingEnd) {
      setSize(size + 1)
    }
  }

  const setQuery = React.useCallback(
    (query: string) => {
      lastQuery.current = query
      clearTimeout(requestTimeout.current)
      requestTimeout.current = setTimeout(() => {
        mutate()
      }, timeout)
    },
    [timeout]
  )

  return {
    setQuery,
    isLoading,
    loadMore,
    isReachingEnd,
    data: data?.filter((d) => d),
    items: data?.reduce((acc, val) => acc.concat(val), []),
    hasMore: !isReachingEnd,
    reset: mutate,
    error,
  }
}
