export const logo = {
  type: 'image',
  y: 50,
  rotation: 0,
  locked: false, // deprecated
  blurEnabled: false,
  blurRadius: 10,
  brightnessEnabled: false,
  brightness: 0,
  shadowEnabled: false,
  shadowBlur: 5,
  shadowOffsetX: 0,
  shadowOffsetY: 0,
  shadowColor: 'black',
  shadowOpacity: 1,
  name: 'logo-image', // name of element, can be used to find element in the store
  // url path to image
  keepRatio: true, // can we change aspect ratio of image

  // url path to svg or image that will be used to clip image
  // cab be used for image framing
  clipSrc: '',
  // width: 100,
  // height: 100,ITS
  cropX: 0, // 0-1 : % from original image width
  cropY: 0, // 0-1 : % from original image height
  cropWidth: 1, // 0-1 : % from original image width
  cropHeight: 1, // 0-1 : % from original image height
  cornerRadius: 0,
  borderColor: 'black',
  borderSize: 0,
  flipX: false,
  flipY: false,

  // can user select element?
  // if false, element will be "invisible" for user clicks
  selectable: true,
  // use for absolute positing of element
  alwaysOnTop: true,
  // also we can hide some elements from the export
  showInExport: true,
  // can element be moved and rotated
  draggable: true,
  // can we change content of element?
  contentEditable: true,

  // can we remove element from UI with button or keyboard?
  removable: true,
  // can we resize element?
  resizable: true,
}

export const logoCTA={
  type: 'image',
  // x: 0,
  // y: 0,
  rotation: 0,
  locked: false, // deprecated
  blurEnabled: false,
  blurRadius: 10,
  brightnessEnabled: false,
  brightness: 0,
  shadowEnabled: false,
  shadowBlur: 5,
  shadowOffsetX: 0,
  shadowOffsetY: 0,
  shadowColor: 'black',
  shadowOpacity: 1,
  name: '', // name of element, can be used to find element in the store
  // url path to image
  // src: 'https://example.com/image.png',
  keepRatio: false, // can we change aspect ratio of image

  // url path to svg or image that will be used to clip image
  // cab be used for image framing
  clipSrc: '',
  width: 100,
  height: 100,
  cropX: 0, // 0-1 : % from original image width
  cropY: 0, // 0-1 : % from original image height
  cropWidth: 1, // 0-1 : % from original image width
  cropHeight: 1, // 0-1 : % from original image height
  cornerRadius: 0,
  borderColor: 'black',
  borderSize: 0,
  flipX: false,
  flipY: false,

  // can user select element?
  // if false, element will be "invisible" for user clicks
  selectable: true,
  // use for absolute positing of element
  alwaysOnTop: false,
  // also we can hide some elements from the export
  showInExport: true,
  // can element be moved and rotated
  draggable: true,
  // can we change content of element?
  contentEditable: true,

  // can we remove element from UI with button or keyboard?
  removable: true,
  // can we resize element?
  resizable: true,
}

// export const logoCTA = {
//   type: 'image',
//   // y: 50,
//   rotation: 0,
//   locked: false, // deprecated
//   blurEnabled: false,
//   blurRadius: 10,
//   brightnessEnabled: false,
//   brightness: 0,
//   shadowEnabled: false,
//   shadowBlur: 5,
//   shadowOffsetX: 0,
//   shadowOffsetY: 0,
//   shadowColor: 'black',
//   shadowOpacity: 1,
//   name: 'logo-imagee', // name of element, can be used to find element in the store
//   // url path to image
//   // keepRatio: true, // can we change aspect ratio of image

//   // url path to svg or image that will be used to clip image
//   // cab be used for image framing
//   clipSrc: '',
//   // width: 100,
//   // height: 100,ITS
//   // cropX: 0, // 0-1 : % from original image width
//   // cropY: 0, // 0-1 : % from original image height
//   // cropWidth: 1, // 0-1 : % from original image width
//   // cropHeight: 1, // 0-1 : % from original image height
//   // cornerRadius: 0,
//   borderColor: 'black',
//   borderSize: 0,
//   flipX: false,
//   flipY: false,

//   // can user select element?
//   // if false, element will be "invisible" for user clicks
//   selectable: true,
//   // use for absolute positing of element
//   // alwaysOnTop: true,
//   // also we can hide some elements from the export
//   showInExport: true,
//   // can element be moved and rotated
//   draggable: true,
//   // can we change content of element?
//   contentEditable: true,

//   // can we remove element from UI with button or keyboard?
//   removable: true,
//   // can we resize element?
//   resizable: true,
// }
