export const animations = {
  heading: [
    {
      delay: 0,
      duration: 817,
      enabled: true,
      type: 'enter',
      name: 'fade',
      data: {},
    },
    {
      delay: 0,
      duration: 817,
      enabled: false,
      type: 'exit',
      name: 'fade',
      data: {},
    },
  ],
  subHeading: [
    {
      delay: 400,
      duration: 817,
      enabled: true,
      type: 'enter',
      name: 'fade',
      data: {},
    },
    {
      delay: 0,
      duration: 817,
      enabled: false,
      type: 'exit',
      name: 'fade',
      data: {},
    },
  ],
  thirdHeading: [
    {
      delay: 800,
      duration: 820,
      enabled: true,
      type: 'enter',
      name: 'fade',
      data: {},
    },
    {
      delay: 0,
      duration: 820,
      enabled: false,
      type: 'exit',
      name: 'fade',
      data: {},
    },
  ],
  text: [
    {
      delay: 1000,
      duration: 840,
      enabled: true,
      type: 'enter',
      name: 'fade',
      data: {},
    },
    {
      delay: 0,
      duration: 840,
      enabled: false,
      type: 'exit',
      name: 'fade',
      data: {},
    },
  ],
  svg: [
    {
      delay: 0,
      duration: 236,
      enabled: true,
      type: 'enter',
      name: 'fade',
      data: {},
    },
    {
      delay: 0,
      duration: 236,
      enabled: false,
      type: 'exit',
      name: 'fade',
      data: {},
    },
  ],
}
