import Values from 'values.js'

export const generatePrimaryShades = (color: string) => {
  const values = new Values(color).all(17)
  let key = 0
  const shades: Record<number, string> = {}
  values
    .map((item: any) => rgbToHex(`rgb(${item.rgb.join(',')})`))
    .forEach((item:any, i:number) => {
      key += i === 0 || i === 1 ? 25 : i === 2 ? 50 : 100
      shades[key] = item
    })
  shades[50] = shades[25]
  shades[500] = color
  return shades
}

function rgbToHex(rgb: string): string {
  const rgbValues = rgb.substring(4, rgb.length - 1).split(',')
  const red = parseInt(rgbValues[0].trim(), 10).toString(16).padStart(2, '0')
  const green = parseInt(rgbValues[1].trim(), 10).toString(16).padStart(2, '0')
  const blue = parseInt(rgbValues[2].trim(), 10).toString(16).padStart(2, '0')
  const hexColor = `#${red}${green}${blue}`
  return hexColor
}
