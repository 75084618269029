import { getDomainID, qaBackendApiInstance } from "@src/config/config"
import { message } from "antd"

export async function uploadFile(file: File, asset_name:string, sub_type:string): Promise<any> {
    const type = getType(file)

    const formData = new FormData()
    formData.append('file', file)
    formData.append('asset_type', type)

    try {
      const response = await qaBackendApiInstance.post(
        `/asset/upload-bg-music?asset_type=${type}&domain_id=${getDomainID()}&sub_type=${sub_type}&asset_name=${asset_name}`,
        formData
      )
      if (response.status === 200) {
        console.log('File uploaded successfully', response.data)
      } else {
        message.error('Upload failed with status: ' + response.status)
      }
    } catch (error: any) {
      if (
        error.code === 504 ||
        error.code === 'ECONNABORTED' ||
        error.code === 'ECONNRESET' ||
        error.code === 'ETIMEDOUT' ||
        error.code === 'ERR_NETWORK'
      ) {
        console.log('File Uploaded successfully.')
      }
      console.error('Error uploading file:', error)
      message.error('Upload failed.')
    } finally {
    //   type === 'image' && refetchImages()
    //   type === 'video' && refetchVideos()
    //   setUploading(false)
    }
  }

  export async function uploadImageFile(file: File, asset_name:string, sub_type:string): Promise<any> {
    const type = getType(file)

    const formData = new FormData()
    formData.append('file', file)
    formData.append('asset_type', type)

    try {
      const response = await qaBackendApiInstance.post(
        `/asset/upload?asset_type=${type}&domain_id=${getDomainID()}&sub_type=${sub_type}&asset_name=${asset_name}`,
        formData
      )
      if (response.status === 200) {
        console.log('File uploaded successfully', response.data)
        return response.data
      } else {
        message.error('Upload failed with status: ' + response.status)
      }
    } catch (error: any) {
      if (
        error.code === 504 ||
        error.code === 'ECONNABORTED' ||
        error.code === 'ECONNRESET' ||
        error.code === 'ETIMEDOUT' ||
        error.code === 'ERR_NETWORK'
      ) {
        console.log('File Uploaded successfully.')
      }
      console.error('Error uploading file:', error)
      message.error('Upload failed.')
    } finally {
    //   type === 'image' && refetchImages()
    //   type === 'video' && refetchVideos()
    //   setUploading(false)
    }
  }

  export function getType(file: any) {
    const { type } = file
    if (type.indexOf('svg') >= 0) {
      return 'svg'
    }
    if (type.indexOf('image') >= 0) {
      return 'image'
    }
    if (type.indexOf('video') >= 0) {
      return 'video'
    }
    if (type.indexOf('audio') >= 0) {
      return 'audio'
    }
    return 'image'
  }