import { FC } from 'react'
import { Slider, Typography } from 'antd'

interface SliderProps {
  xAxis?: number
  setXAxis?: (value: number) => void
  yAxis: number
  setYAxis: (value: number) => void
  maxYAxis?: number
}

const PositionSliderRow: FC<SliderProps> = ({ xAxis, setXAxis, yAxis, setYAxis, maxYAxis }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Typography.Text style={{ fontSize: '14px' }}>Y Axis</Typography.Text>
        <Slider
          min={1}
          max={maxYAxis}
          onChange={(val) => setYAxis(val)}
          value={yAxis}
          style={{
            margin: '0px 2px',
            width: '100%',
          }}
        />
      </div>
    </div>
  )
}

export default PositionSliderRow
