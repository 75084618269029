import React, { useEffect, useRef, useState } from "react";
import { Slider, Switch, Button, Popover, Row, Col, InputNumber } from "antd";
import type { SliderSingleProps } from "antd/es/slider"; // Adjusting to the correct path for type
import { observer } from "mobx-react-lite";

type VideoDurationSettingProps = {
  store: any; // Replace 'YourStoreType' with the actual type for 'store'
  pageId?: string; // Assuming 'pageId' is a string, otherwise adjust accordingly
};
// import {getVideoDurationInSeconds } from 'get-video-duration'
// import { Button, Popover } from 'antd';
const VideoDurationSetting: React.FC<VideoDurationSettingProps> = ({
  store,
  pageId,
}: VideoDurationSettingProps) => {
  const [pageDurtation, setPageDuration] = useState(0);
  const pageIdBackup = store.activePage.id;
  const formatter: NonNullable<SliderSingleProps["tooltip"]>["formatter"] = (
    value
  ) => `${value} sec`;
  useEffect(()=>{
    // setPageDuration((store?.activePage?.duration/1000).toFixed(2))
    setPageDuration(Number((store?.activePage?.duration / 1000).toFixed(2)));

  
  },[store?.activePage?.duration])
  function normalize(value: number, min: number, max: number) {
    const clampedValue = Math.max(min, Math.min(value, max)); // Clamping the value
    return (clampedValue - min) / (max - min);
  }

  function denormalize(normalizedValue: any, min: number, max: number) {
    return normalizedValue * (max - min) + min;
  }

  function secondsToMilliseconds(seconds: number) {
    return seconds * 1000;
  }
  const onChange = (value: any) => {
    const durationAfterTrimming = secondsToMilliseconds(value);
    setPageDuration(value);
    const page = store.getElementById(pageId || pageIdBackup);
    page.set({
      duration: durationAfterTrimming,
    });
  };
  useEffect(() => {
    if (
      store.pages.length > 0 &&
      store.getElementById(pageId || pageIdBackup)
    ) {
      const page = store.getElementById(pageId || pageIdBackup);
     
    setPageDuration(Number((page.duration / 1000).toFixed(2)));

    }
  }, [store, pageId, pageIdBackup]);

  return (
    <>
      {/* <Slider step={0.1}  min={0} max={videoLength} range defaultValue={[savedStartTime || 0, savedEndTime || videoLength|| 0]}  disabled={disabled} onChange={onChange} tooltip={{ formatter }} />
        <video src={videoUrl} ref={videoEl} onLoadedMetadata={handleLoadedMetadata} style={{display:'none'}}/> */}
      <Row style={{paddingTop:'15px'}}>
        <Col span={18}>
          <Slider
            min={1}
            max={30}
            step={0.1}
            onChange={onChange}
            value={typeof pageDurtation === "number" ? pageDurtation : 0}
            tooltip={{ formatter }}
          />
        </Col>
        <Col span={4}>
          <InputNumber
            min={1}
            max={30}
            style={{ margin: "0 16px" }}
            value={pageDurtation}
            onChange={onChange}
            readOnly
          />
        </Col>
      </Row>
    </>
  );
};

export default observer(VideoDurationSetting);
