import { useGlobalContext } from '@src/context/globalContext';
import { Row } from 'antd';
import React, { useEffect, useState } from 'react';
import UploadImage from '../thumbnailComponent/uploadImage';
import { SectionTab } from 'polotno/side-panel';
import {
  PictureOutlined
} from '@ant-design/icons'
import { CarouselHorizontalIcon } from '@src/features/polotno/component/hugeIcons/hugeIcons';

interface ThumbnailProp {
    store: any;
  }
  
export const ThumbnailPanel: React.FC<ThumbnailProp> = React.memo(
    ({ store }: ThumbnailProp) => {
        const {
            ScriptDetails: [scriptDetails, setScriptDetails],
            ActiveSidePanel: [, setActiveSidePanel],

        } = useGlobalContext()
         
  useEffect(()=>{
    setActiveSidePanel(store.openedSidePanel)
  },[])
        const baseStyle: React.CSSProperties = {
          height: '100%',
          overflow: 'auto',
          backgroundColor: 'var(--color-primary-50)',
      
        }
    return(
        <div style={baseStyle}>
        <Row gutter={[0, 4]} style={{ flexDirection: 'column' }}>
        <h3
                style={{
                  fontSize: '14px',
                  fontWeight: 'normal',
                  marginRight: '10px',
                }}
              >
                <UploadImage/>
              </h3>
        </Row>
        </div>
    )
})

ThumbnailPanel.displayName = 'ThumbnailPanel';

export const CustomThumbnailPolotno = {
  name: 'thumbnail-panel',
  Tab: (props:any) =>(
    <SectionTab name='Thumbnail' {...props}>
      <CarouselHorizontalIcon className='sidebar-panel-tab-icon' />
    </SectionTab>
  ),
  Panel: ThumbnailPanel,
}
