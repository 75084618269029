import { useQuery } from 'react-query'
import axios from 'axios'

export const useGetPixabayVideos = (
  apiKey: string | undefined, // Your Pixabay API key
  query = '', // Search query, default empty to fetch random videos
  perPage = 20, // Default number of videos per page
  page = 1 // Page number for pagination
) => {
  return useQuery(
    ['fetchPixabayVideos', query, page], // Query key, includes dependencies
    async () => {
      const response = await axios.get('https://pixabay.com/api/videos/', {
        params: {
          key: apiKey,
          q: query.replace(/\s/g, '+'), // Replace spaces with '+' for URL encoding
          per_page: perPage,
          page,
        },
      })
      return response.data
    },
    {
      // Optional settings, for example:
      enabled: !!query, // Only run query if a search term is provided
      // keepPreviousData: true, // Keep previous data while fetching the next page for pagination
      // staleTime: 5 * 60 * 1000, // Data freshness duration
    }
  )
}
