import { useQuery } from 'react-query'
import axios from 'axios'

export const useGetPexelsVideos = (
  apiKey: string | undefined, // Your Pexels API key
  query = '', // Default search query
  orientation: string | undefined,
  size: string | undefined
) => {
  return useQuery(
    ['fetchPexelsVideos', query],
    async () => {
      const response = await axios.get('https://api.pexels.com/videos/search', {
        headers: {
          Authorization: apiKey,
        },
        params: {
          query,
          orientation,
          size,
        },
      })
      return response.data
    },
    {
      enabled: !!query, // Only run the query if a search term is provided
    }
  )
}
