import React, { FC, useEffect, useState } from 'react'
import { Typography, Select, InputNumber } from 'antd'

interface FontPickerProps {
  defaultFont: string
  onFontChange: (font: string) => void
  fontSize: number
  onFontSizeChange: (fontSize: number) => void
}

const FontPickerRow: FC<FontPickerProps> = ({ defaultFont, onFontChange, fontSize, onFontSizeChange }) => {
  const { Option } = Select
  const [currentFont, setCurrentFont] = useState(defaultFont)
  const [currentFontSize, setFontSize] = useState(20)

  const handleFontChange = (font: string) => {
    setCurrentFont(font)
    onFontChange(font)
  }
  const handleFontSizeChange = (fontSize: number) => {
    setFontSize(fontSize)
    onFontSizeChange(fontSize)
  }

  useEffect(() => {
    setCurrentFont(defaultFont)
    setFontSize(fontSize)
  }, [defaultFont])

  // List of some commonly used web-safe fonts
  const fonts = [
    'Konga next',
    'Bangers',
    'Arial',
    'Comic Neue',
    'Inter',
    'Lato',
    'Merriweather',
    'Montserrat',
    'Mulish',
    'Open Sans',
    'Oswald',
    'Poppins',
    'Roboto',
    'Source Sans 3',
    'Varela Round',
    
  ]

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
      <Select value={currentFont} style={{ width: 180 }} onChange={handleFontChange}>
        {fonts.map((font) => (
          <Option key={font} value={font}>
            {font}
          </Option>
        ))}
      </Select>
      <InputNumber
        size='small'
        min={5}
        step={1}
        value={fontSize}
        onChange={(val) => handleFontSizeChange(val as number)}
        style={{
          width: '50px',
          textAlign: 'center',
          color: '#ffffff', // Optional: Change the text color to white for better contrast
          borderColor: '#b3b3b3', // Ensure the text inside InputNumber is centered, might not be necessary depending on your UI Kit
        }}
      />
    </div>
  )
}

export default FontPickerRow
