import { useQuery } from 'react-query'
import axios from 'axios'
import { ELEVEN_LABS_KEY } from '../../config'

export const useGetVoices = () => {
  return useQuery(['GetVoices'], async () => {
    const response = await axios.get('https://api.elevenlabs.io/v1/voices', {
      headers: {
        'xi-api-key': ELEVEN_LABS_KEY, // Use the apiKey argument for the API key
      },
    })
    return response?.data
  })
}
