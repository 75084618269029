import { qaBackendApiInstance } from '../config/config'
import { useQuery } from 'react-query'

// for staff names
export const useGetUserDeails = (accessToken?: string | undefined | null) => {
  return useQuery(
    ['useGetUserDeails', accessToken],
    async () => {
      const userData = await qaBackendApiInstance.get(`/user/details`).catch((error) => {
        throw error
      })
      return userData?.data
    },
    { enabled: !!accessToken, retry: false }
  )
}
