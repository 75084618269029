export function animationFunc(duration: any) {
  interface Animation {
    delay: number
    duration: number
    enabled: boolean
    name: string
    type: 'enter' | 'exit' | string
    data: Record<string, any>
  }

  const randomNumber = Math.random()
  const randomBinary = Math.round(randomNumber) // 0 or 1
  const nameArr = ['zoom', 'move'] // Move
  const randomNumberDirection = Math.floor(Math.random() * 6)
  const directionArr = ['up', 'down', 'top-right', 'top-left', 'bottom-right', 'bottom-left']
  const animations: Animation[] = [
    {
      delay: 0.01,
      duration: duration,
      enabled: true,
      name: nameArr[randomBinary],
      type: 'enter',
      data: {
        direction: nameArr[randomBinary] === 'move' ? directionArr[randomNumberDirection] : '',
      },
    },
    {
      delay: 0,
      duration: duration,
      enabled: false,
      name: nameArr[randomBinary],
      type: 'exit',
      data: {},
    },
  ]
  return animations
}
export function animationFuncMoveZoom(animtionType:string,duration: any) {
  
  interface Animation {
    delay: number
    duration: number
    enabled: boolean
    name: string
    type: 'enter' | 'exit' | string
    data: Record<string, any>
  }

  const randomNumber = Math.random()
  const randomBinary = Math.round(randomNumber) // 0 or 1
  const nameArr = [animtionType] // Move
  const randomNumberDirection = Math.floor(Math.random() * 6)
  const directionArr = ['up', 'down', 'top-right', 'top-left', 'bottom-right', 'bottom-left']
  const animations: Animation[] = [
    {
      delay: 0.01,
      duration: duration,
      enabled: true,
      name: nameArr[randomBinary],
      type: 'enter',
      data: {
        direction: nameArr[randomBinary] === 'move' ? directionArr[randomNumberDirection] : '',
      },
    },
    {
      delay: 0,
      duration: duration,
      enabled: false,
      name: nameArr[randomBinary],
      type: 'exit',
      data: {},
    },
  ]
  return animations
}
export function animationFuncCustomeOne3D(duration: any) {
  interface Animation {
    delay: number
    duration: number
    enabled: boolean
    name: string
    type: 'enter' | 'exit' | string
    data: Record<string, any>
  }

  const randomNumber = Math.random()
  const randomBinary = Math.round(randomNumber) // 0 or 1
  const nameArr = ['zoom', 'move'] // Move
  const randomNumberDirection = Math.floor(Math.random())
  // const directionArr = ['move-zoom', 'zoomIn-out']
  const directionArr = ['3d-perspective', '3d-perspective']
  const animations: Animation[] = [
    {
      delay: 0.01,
      duration: duration,
      enabled: true,
      name: 'move',
      type: 'enter',
      data: {
        direction:directionArr[randomNumberDirection],
        // direction:'zoomIn-out'
      },
    },
    {
      delay: 0,
      duration: duration,
      enabled: false,
      name: 'move',
      type: 'exit',
      data: {},
    },

    // {
    //   delay: 0.01,
    //   duration: 200,
    //   enabled: true,
    //   name: 'move',
    //   type: 'enter',
    //   data: {
    //     direction:'top-left',
    //   },
    // },
    // {
    //   delay: 0,
    //   duration: 200,
    //   enabled: false,
    //   name: 'move',
    //   type: 'exit',
    //   data: {},
    // },
    // {
    //   delay: 0.01,
    //   duration: 400,
    //   enabled: true,
    //   name: 'zoom',
    //   type: 'enter',
    //   data: {
    //     direction:'up',
    //   },
    // },
    // {
    //   delay: 0,
    //   duration: 200,
    //   enabled: false,
    //   name: 'zoom',
    //   type: 'exit',
    //   data: {},
    // },
   
  ]
  return animations
}

export function animationFuncCustomeOne(duration: any) {
  interface Animation {
    delay: number
    duration: number
    enabled: boolean
    name: string
    type: 'enter' | 'exit' | string
    data: Record<string, any>
  }

  const randomNumber = Math.random()
  const randomBinary = Math.round(randomNumber) // 0 or 1
  const nameArr = ['zoom', 'move'] // Move
  const randomNumberDirection = Math.floor(Math.random())
  const directionArr = ['move-zoom', 'zoomIn-out']
  // const directionArr = ['3d-perspective', '3d-perspective']
  const animations: Animation[] = [
    {
      delay: 0.01,
      duration: duration,
      enabled: true,
      name: 'move',
      type: 'enter',
      data: {
        direction:directionArr[randomNumberDirection],
        // direction:'zoomIn-out'
      },
    },
    {
      delay: 0,
      duration: duration,
      enabled: false,
      name: 'move',
      type: 'exit',
      data: {},
    },

    // {
    //   delay: 0.01,
    //   duration: 200,
    //   enabled: true,
    //   name: 'move',
    //   type: 'enter',
    //   data: {
    //     direction:'top-left',
    //   },
    // },
    // {
    //   delay: 0,
    //   duration: 200,
    //   enabled: false,
    //   name: 'move',
    //   type: 'exit',
    //   data: {},
    // },
    // {
    //   delay: 0.01,
    //   duration: 400,
    //   enabled: true,
    //   name: 'zoom',
    //   type: 'enter',
    //   data: {
    //     direction:'up',
    //   },
    // },
    // {
    //   delay: 0,
    //   duration: 200,
    //   enabled: false,
    //   name: 'zoom',
    //   type: 'exit',
    //   data: {},
    // },
   
  ]
  return animations
}


export function animationFuncCustomeOneZoomOutMove(animationType:any,duration: any) {
  interface Animation {
    delay: number
    duration: number
    enabled: boolean
    name: string
    type: 'enter' | 'exit' | string
    data: Record<string, any>
  }

  const randomNumber = Math.random()
  const randomBinary = Math.round(randomNumber) // 0 or 1
  const nameArr = ['zoom', 'move'] // Move
  const randomNumberDirection = Math.floor(Math.random())
  const directionArr = [animationType]
  // const directionArr = ['3d-perspective', '3d-perspective']
  const animations: Animation[] = [
    {
      delay: 0.01,
      duration: duration,
      enabled: true,
      name: 'move',
      type: 'enter',
      data: {
        direction:directionArr[randomNumberDirection],
        // direction:'zoomIn-out'
      },
    },
    {
      delay: 0,
      duration: duration,
      enabled: false,
      name: 'move',
      type: 'exit',
      data: {},
    },

    // {
    //   delay: 0.01,
    //   duration: 200,
    //   enabled: true,
    //   name: 'move',
    //   type: 'enter',
    //   data: {
    //     direction:'top-left',
    //   },
    // },
    // {
    //   delay: 0,
    //   duration: 200,
    //   enabled: false,
    //   name: 'move',
    //   type: 'exit',
    //   data: {},
    // },
    // {
    //   delay: 0.01,
    //   duration: 400,
    //   enabled: true,
    //   name: 'zoom',
    //   type: 'enter',
    //   data: {
    //     direction:'up',
    //   },
    // },
    // {
    //   delay: 0,
    //   duration: 200,
    //   enabled: false,
    //   name: 'zoom',
    //   type: 'exit',
    //   data: {},
    // },
   
  ]
  return animations
}
export const animationList = [
  {
    value: 'move-zoom',
    name: 'Bounce & Zoom'
  },
  {
    value: 'zoomIn-out',
    name: 'Zoom In & Out'
  },
  {
    value: 'zoom',
    name: 'Simple Zoom'
  },
  {
    value: 'move',
    name: 'Simple Move'
  },
  {
    value: '3d-perspective',
    name: '3D Motion'
  }
];
