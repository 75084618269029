import { logo } from '../../../../data/logo'
import store from 'polotno/model/store'
import { getImageSize } from 'polotno/utils/image'
import { prefetch } from 'remotion'

async function addLogo(icon: any, index: number, selectedSize: string, store: any, visible:boolean) {
  const { waitUntilDone } = prefetch(icon, {
    method: 'blob-url',
    contentType: 'image/png',
  })
  await waitUntilDone()

  if (icon) {
    let { width, height } = await getImageSize(icon)

    const scaleFactor = selectedSize === '9:16' ? 0.4 : 0.2
    let scale = Math.min(store.width / width, store.height / height, scaleFactor)

    // Limit width to 250 pixels
    if (width * scale > 100) {
      scale *= 100 / (width * scale) // Adjust scale to fit within 250 width
    }

    // Ensure height is scaled proportionally
    height *= scale
    width *= scale

    store.pages[index].addElement({
      ...logo,
      id: 'quickadsLogo' + index.toString(),
      src: `${icon}`,
      x: store.width - width - 25,
      width: width,
      height: height,
      visible:visible
    })
    const element = store.getElementById('quickadsLogo' + index.toString())
    element.set({ x: store.width - width - 25 })
  }
}

export default addLogo
