import mixpanel, { Callback, Dict, RequestOptions } from 'mixpanel-browser';

let lastTrackedTime = 0;

export function trackEvent(
  event_name: string,
  properties?: Dict | undefined,
  optionsOrCallback?: RequestOptions | Callback | undefined,
  callback?: Callback | undefined
) {
  const currentTime = new Date().getTime();
  const timeSinceLastTrack = currentTime - lastTrackedTime;
  const throttleTime = 5000; // 5 seconds in milliseconds

  if (timeSinceLastTrack < throttleTime) {
    // console.warn(`Event throttled: ${event_name}`);
    return;
  }

  try {
    mixpanel.track(event_name, properties, optionsOrCallback, callback);
    lastTrackedTime = currentTime;
  } catch (err) {
    console.error(err);
  }
}
