/* Undoes an action in the project based on the video style, using a stack-based history approach. 
 It manages state transitions by moving the last state from the undo stack to the redo stack. 
 Depending on the video style ('Simple' or 'Burst'), the function compares the length of content 
 to determine if an undo operation should proceed. For 'Simple' style, it checks if the data length matches, 
 and for 'Burst' style, it calculates the total images across all pages. 
 If conditions are met, it updates the script details and modifies the stacks accordingly.*/

export const undoScript = (
  undoStack: any[],
  setScriptDetails: any,
  setUndoStack: any,
  setRedoStack: any,
  length: number,
  videoStyle: string,
  scriptDetails: any
) => {
  if (undoStack.length > 0) {
    const lastState = undoStack[undoStack.length - 1]
    let shouldUndo = false

    if (videoStyle === 'Simple') {
      shouldUndo = lastState.data.length !== length
    } else if (videoStyle === 'Burst') {
      const imagePagesLength = lastState.data.reduce((acc: number, curr: any) => {
        return acc + curr.images.length
      }, 0)
      console.log(imagePagesLength, length)
      shouldUndo = imagePagesLength !== length
    }

    if (shouldUndo) {
      // const lastState = undoStack.pop()
      const deepCopyScriptDetails = JSON.parse(JSON.stringify(scriptDetails))
      setRedoStack((prevStack: any[]) => {
        return [...prevStack, deepCopyScriptDetails]
      })
      setScriptDetails(lastState)
      setUndoStack(undoStack.splice(0, undoStack.length))
    } else {
      setUndoStack([])
      setScriptDetails(lastState)
    }
  }
}

/*Redoes an action in the project based on the video style, using a stack-based history approach.
 It manages state transitions by moving the next state from the redo stack to the undo stack.
 Depending on the video style ('Simple' or 'Burst'), the function compares the content length
 to determine if a redo operation should proceed. For 'Simple' style, it checks if the data length matches,
 and for 'Burst' style, it calculates the total images across all pages.
 If conditions are met, it updates the script details and modifies the stacks accordingly.*/

export const redoScript = (
  undoStack: any[],
  redoStack: any[],
  setScriptDetails: any,
  setUndoStack: any,
  setRedoStack: any,
  length: number,
  videoStyle: string,
  scriptDetails: any
) => {
  if (redoStack.length > 0) {
    const nextState = redoStack[redoStack.length - 1]

    let shouldUndo = false

    if (videoStyle === 'Simple') {
      shouldUndo = nextState.data.length !== length
    } else if (videoStyle === 'Burst') {
      const imagePagesLength = nextState.data.reduce((acc: number, curr: any) => {
        return acc + curr.images.length
      }, 0)

      shouldUndo = imagePagesLength !== length
    }

    if (shouldUndo) {
      // const nextState = redoStack.pop()
      const deepCopyScriptDetails = JSON.parse(JSON.stringify(scriptDetails))
      setUndoStack((prevStack: any[]) => {
        return [...prevStack, deepCopyScriptDetails]
      })
      setScriptDetails(nextState)
      setRedoStack(redoStack.splice(0, undoStack.length))
    } else {
      setRedoStack([])
      setScriptDetails(nextState)
    }
  }
}

// This function handles copying a page in a project, including updating script details and managing undo/redo history.
export const handleCopyPage = async (
  store: any,
  index: number,
  scriptDetails: any,
  setScriptDetails: any,
  setUndoStack: any,
  setRedoStack: any,
  videoStyle: string,
  isStoreUpdated: any,
  setIsStoreUpdated: any
) => {
  // Selects the current page based on the index.
  store.selectPage(store.pages[index].id)

  // Temporarily sets the opacity of the caption text to 0 to avoid visual duplication during the copy.
  store.pages.map((page: any) => {
    const elementID = 'captionText' + page.id
    if (store.getElementById(elementID)) {
      store.getElementById(elementID).set({ opacity: 0 })
    }
  })

  // Clones the active page.
  store.activePage?.clone()

  // Restores the opacity of the caption text after the clone operation.
  store.pages.map((page: any) => {
    const elementID = 'captionText' + page.id
    if (store.getElementById(elementID)) {
      store.getElementById(elementID).set({ opacity: 1 })
    }
  })

  const deepCopyScriptDetails = JSON.parse(JSON.stringify(scriptDetails))
  await setUndoStack((prevStack: any[]) => [...prevStack, deepCopyScriptDetails])

  // Resets the redo stack and updates the pages again.
  setRedoStack([])

  // Handles script detail modifications based on the video style.
  let newScriptDetails

  // this is donw to trigger the useEffect in videoTimeline component so that the timeline updates accordingly
  setIsStoreUpdated(!isStoreUpdated)

  if (videoStyle === 'Simple') {
    // For 'Simple' style, duplicates the specific script detail and inserts it.
    newScriptDetails = { ...scriptDetails }
    const clonedData = { ...newScriptDetails.data[index] }
    newScriptDetails.data.splice(index + 1, 0, clonedData)
    setScriptDetails(newScriptDetails)
  } else if (videoStyle === 'Burst') {
    const imageIndex = store.pages[index].custom.imageIndexPSC
    const scriptIndex = store.pages[index].custom.scriptIndex
    newScriptDetails = { ...scriptDetails }
    const clonedData = { ...newScriptDetails.data[scriptIndex][imageIndex] }
    newScriptDetails.data[scriptIndex].images.splice(imageIndex + 1, 0, clonedData)

    // Updates other pages in the scriptIndex to adjust their imageIndex.
    store.pages.map((page: any, idx: number) => {
      if (page.custom.scriptIndex === scriptIndex) {
        if (page.custom.imageIndex > imageIndex) {
          page.set({
            custom: {
              ...page.custom,
              imageIndex: page.custom.imageIndex + 1,
            },
          })
        }
      }
    })

    // Specifically updates the newly created page's image index.
    store.pages[index + 1].set({
      custom: {
        ...store.pages[index + 1].custom,
        imageIndex: imageIndex + 1,
      },
    })

    setScriptDetails(newScriptDetails)
  }
}

/*Deletes a page from the project and manages the associated script details based on the video style.
 The function selects the page based on the provided index, deletes it, and updates the project's JSON structure.
 It then updates the undo stack with a deep copy of the current script details before modifying them.
 For the 'Simple' video style, it simply removes the corresponding script detail.
 For the 'Burst' style, it adjusts image indices within the script details to account for the deletion,
 ensuring that remaining pages and script indices are correctly updated.*/

export const handleDeletePage = async (
  store: any,
  index: number,
  scriptDetails: any,
  setScriptDetails: any,
  setUndoStack: any,
  videoStyle: string,
  isStoreUpdated: any,
  setIsStoreUpdated: any,
  isBurstSceneDelete:any
) => {
  // Selects and deletes the page based on the provided index.
  // store.selectPage(store.pages[index].id)
  // store.deletePages([store.activePage.id])

  // this is done to trigger the useEffect in videoTimeline component so that the timeline updates accordingly
  // setIsStoreUpdated(!isStoreUpdated)

  // Creates a deep copy of the script details for the undo stack.
  const deepCopyScriptDetails = JSON.parse(JSON.stringify(scriptDetails))
  await setUndoStack((prevStack: any[]) => [...prevStack, deepCopyScriptDetails])

  // Handles the script details based on the video style.
  if (scriptDetails && scriptDetails.data) {
    if (videoStyle === 'Simple') {
       // Selects and deletes the page based on the provided index.
        await store.selectPage(store.pages[index].id)
        await store.deletePages([store.activePage.id])

        // this is done to trigger the useEffect in videoTimeline component so that the timeline updates accordingly
        setIsStoreUpdated(!isStoreUpdated)
      // For 'Simple' style, removes the corresponding data entry from script details.
      const updatedScriptDetails = { ...scriptDetails }
      updatedScriptDetails.data.splice(index, 1)
      setScriptDetails(updatedScriptDetails)
    } else if (videoStyle === 'Burst') {
      if(isBurstSceneDelete){
        const updatedScriptDetails = { ...scriptDetails }
        const selectedSceneAssets = scriptDetails.data[index].images.length
        const startIteration = ((index*selectedSceneAssets+selectedSceneAssets)-1)
        const noOfIterations = selectedSceneAssets
        for (let i = startIteration; i > startIteration-noOfIterations; i--) {
          // console.log(i)
          // console.log(store.pages.toJSON())
          const imageIndex = store.pages[i].custom.imageIndex
          const scriptIndex = store.pages[i].custom.scriptIndex
          store.pages.map((page: any, idx: number) => {
            if (page.custom.scriptIndex === scriptIndex && page.custom.imageIndex >= imageIndex) {
              page.set({
                custom: {
                  ...page.custom,
                  imageIndex: page.custom.imageIndex - 1,
                },
              })
            }
          })
          store.selectPage(store.pages[i].id)
          store.deletePages([store.activePage.id])
          // this is done to trigger the useEffect in videoTimeline component so that the timeline updates accordingly
          setIsStoreUpdated(!isStoreUpdated)
          // Your loop logic here
      }
        // console.log(scriptDetailsLength)
         updatedScriptDetails.data.splice(index, 1)
        setScriptDetails(updatedScriptDetails)
      }
      else{
         // Selects and deletes the page based on the provided index.
        
       // For 'Simple' style, removes the corresponding data entry from script details.
      // For 'Burst' style, adjusts image indices and script indices after deletion.
      const imageIndex = store.pages[index].custom.imageIndex
      const scriptIndex = store.pages[index].custom.scriptIndex

      const updatedScriptDetails = { ...scriptDetails }
      updatedScriptDetails.data[scriptIndex].images.splice(imageIndex, 1)

      setScriptDetails(updatedScriptDetails)

      // Updates image indices for remaining pages in the same script index.
      store.pages.map((page: any, idx: number) => {
        if (page.custom.scriptIndex === scriptIndex && page.custom.imageIndex >= imageIndex) {
          page.set({
            custom: {
              ...page.custom,
              imageIndex: page.custom.imageIndex - 1,
            },
          })
        }
      })
      store.selectPage(store.pages[index].id)
      store.deletePages([store.activePage.id])

      // this is done to trigger the useEffect in videoTimeline component so that the timeline updates accordingly
      setIsStoreUpdated(!isStoreUpdated)
    }
    }
  }
}


export const handleEmptyPage = async (
  store: any,
  index: number,
  scriptDetails: any,
  setScriptDetails: any,
  setUndoStack: any,
  setRedoStack: any,
  videoStyle: string,
  isStoreUpdated: any,
  setIsStoreUpdated: any,
  currentPage:any
) => {
  // Selects the current page based on the index.
  // store.selectPage(store.pages[index].id)

  // // Temporarily sets the opacity of the caption text to 0 to avoid visual duplication during the copy.
  // store.pages.map((page: any) => {
  //   const elementID = 'captionText' + page.id
  //   if (store.getElementById(elementID)) {
  //     store.getElementById(elementID).set({ opacity: 0 })
  //   }
  // })

  // // Clones the active page.
  // store.activePage?.clone()

  // // Restores the opacity of the caption text after the clone operation.
  // store.pages.map((page: any) => {
  //   const elementID = 'captionText' + page.id
  //   if (store.getElementById(elementID)) {
  //     store.getElementById(elementID).set({ opacity: 1 })
  //   }
  // })

  const deepCopyScriptDetails = JSON.parse(JSON.stringify(scriptDetails))
  await setUndoStack((prevStack: any[]) => [...prevStack, deepCopyScriptDetails])

  // Resets the redo stack and updates the pages again.
  setRedoStack([])

  // Handles script detail modifications based on the video style.
  let newScriptDetails

  // this is donw to trigger the useEffect in videoTimeline component so that the timeline updates accordingly
  setIsStoreUpdated(!isStoreUpdated)

  if (videoStyle === 'Simple') {
    // For 'Simple' style, duplicates the specific script detail and inserts it.
    newScriptDetails = { ...scriptDetails }
    const clonedData = { ...newScriptDetails.data[index] }
    newScriptDetails.data.splice(index + 1, 0, clonedData)
    setScriptDetails(newScriptDetails)
  } else if (videoStyle === 'Burst') {
    const imageIndex = store.pages[index].custom.imageIndexPSC
    const scriptIndex = store.pages[index].custom.scriptIndex
    newScriptDetails = { ...scriptDetails }
    const clonedData = { ...newScriptDetails.data[scriptIndex][imageIndex] }
    newScriptDetails.data[scriptIndex].images.splice(imageIndex + 1, 0, clonedData)

    // Updates other pages in the scriptIndex to adjust their imageIndex.
    store.pages.map((page: any, idx: number) => {
      if (page.custom.scriptIndex === scriptIndex) {
        if (page.custom.imageIndex > imageIndex) {
          page.set({
            custom: {
              ...page.custom,
              imageIndex: page.custom.imageIndex + 1,
            },
          })
        }
      }
    })

    // Specifically updates the newly created page's image index.
    currentPage.set({
      custom: {
        ...store.pages[index + 1].custom,
        imageIndex: imageIndex + 1,
      },
    })

    setScriptDetails(newScriptDetails)
  }
}