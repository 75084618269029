import React, { useMemo } from 'react'
import { AbsoluteFill, interpolate } from 'remotion'
import type { TransitionPresentation, TransitionPresentationComponentProps } from '../../types.js'

export type SlideDirection = 'from-left' | 'from-top' | 'from-right' | 'from-bottom'

const epsilon = 0.01

export type GlitchProps = {
  intensity?: number // Control the glitch intensity
  direction?: SlideDirection
  enterStyle?: React.CSSProperties
  exitStyle?: React.CSSProperties
}

const GlitchPresentation: React.FC<TransitionPresentationComponentProps<GlitchProps>> = ({
  children,
  presentationDirection,
  presentationProgress,
  passedProps: { intensity = 100, direction = 'from-right', enterStyle, exitStyle },
}) => {
  const isEntering = presentationDirection === 'entering'
  const zoomScale = 1
  const duration = 30

  const directionStyle = useMemo(() => {
    const glitchTranslateX = (Math.random() - presentationProgress) * 2 * intensity
    const glitchTranslateY = (Math.random() - presentationProgress) * 2 * intensity
    const scale = interpolate(presentationProgress, [0, 1], [1, zoomScale])
    const phase = interpolate(presentationProgress, [0, 1], [0, Math.PI * 2 * (duration / 30)])
    const shakeEffect = Math.sin(phase) * 10 * presentationProgress
    const hueRotate = 360 * intensity * presentationProgress

    return {
      // transform: `translate(${glitchTranslateX}px, ${glitchTranslateY}px)`,
      transform: `translateX(${shakeEffect}px) translateY(${shakeEffect}px) scale(${scale})`,
      // transform: `translate(${shakeEffect}px) scale(${scale})`,
      filter: `saturate(1) hue-rotate(${hueRotate}deg)`,
    }
  }, [presentationProgress, intensity])

  const style: React.CSSProperties = useMemo(() => {
    return {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      opacity: isEntering ? presentationProgress : 1,
      backfaceVisibility: 'hidden',
      ...directionStyle,
      ...(isEntering ? enterStyle : exitStyle),
    }
  }, [isEntering, presentationProgress, directionStyle, enterStyle, exitStyle])

  return <AbsoluteFill style={style}>{children}</AbsoluteFill>
}

export const glitch = (props?: GlitchProps): TransitionPresentation<GlitchProps> => {
  return {
    component: GlitchPresentation,
    props: props ?? {},
  }
}
