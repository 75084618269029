import { qaBackendApiInstance } from '../config/config'
import { useQuery } from 'react-query'

// for staff names
export const useGetProject = (projectID?: string | undefined) => {
  return useQuery(
    ['useGetProject', projectID],
    async () => {
      const assets = await qaBackendApiInstance.get(`/smp/${projectID}`).catch((error) => {
        throw error
      })
      return assets?.data
    },
    { enabled: !!projectID, retry: false }
  )
}
