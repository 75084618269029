import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.less'
import { App } from './App'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import GlobalContext from './context/globalContext'
import { registerRoot } from 'remotion'
import { ConfigProvider } from 'antd'
import Mixpanel from './thirdPartyComponents/Mixpanel'
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
})


root.render(
  // <React.StrictMode>
  <ConfigProvider
    theme={{
      token: {
        // Seed Token
        colorPrimary: '#7535C3',
        colorInfo: '#7036d3',
      },
    }}
  >
    <QueryClientProvider client={queryClient}>
      <GlobalContext>
        <Router>
          <Routes>
            <Route path={'*'} element={<App />} />
          </Routes>
        </Router>
        <Mixpanel />
      </GlobalContext>
    </QueryClientProvider>
  </ConfigProvider>
  // </React.StrictMode>
)
