// types.ts
export interface Image {
    searchQueries: string[];
    duration: number;
  }
  
  export interface AdBlock {
    adBlockType: string;
    onScreenVisual: string;
    voiceOver?: string;
    images: Image[];
    textOverlay: string;
    searchQueries: string[];
  }
  
  export interface AdData {
    data: AdBlock[];
  }
  

export const compareJsonAndReturnUpdated = (oldJson: any, newJson: any): AdData => {
    const result: any = { data: [] };

  const oldJsonVoiceOverMap = new Map<string, AdBlock>();

  // Create a map for oldJson with voiceOver as the key
  oldJson.data.forEach((adBlock:any) => {
    if (adBlock.voiceOver) {
      oldJsonVoiceOverMap.set(adBlock.voiceOver, adBlock);
    }
  });

  // Iterate over newJson and add matching adBlocks from oldJson if they exist
  newJson.data.forEach((adBlock:any) => {
    if (adBlock.voiceOver && oldJsonVoiceOverMap.has(adBlock.voiceOver)) {
      result.data.push(oldJsonVoiceOverMap.get(adBlock.voiceOver) as AdBlock);
    } else {
      result.data.push(adBlock);
    }
  });
  
    return result;
  };
  
//   // Example usage
//   const oldJson: AdData = {
//     // ... your old JSON data
//   };
  
//   const newJson: AdData = {
//     // ... your new JSON data
//   };
  
//   const updatedJson = compareJsonAndReturnUpdated(oldJson, newJson);
//   console.log(updatedJson);
  