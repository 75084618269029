import React, { useMemo } from 'react'
import { AbsoluteFill, interpolate } from 'remotion'
import type { TransitionPresentation, TransitionPresentationComponentProps } from '../../types.js'

export type ShakeTransitionProps = {
  intensity?: number // Control the shake intensity
  zoomScale?: number // Maximum scale factor
  duration?: number // Duration of the shake in frames
  enterStyle?: React.CSSProperties
  exitStyle?: React.CSSProperties
}

const ShakePresentation: React.FC<TransitionPresentationComponentProps<ShakeTransitionProps>> = ({
  children,
  presentationDirection,
  presentationProgress,
  passedProps: { intensity = 100, zoomScale = 1, duration = 30, enterStyle, exitStyle },
}) => {
  const isEntering = presentationDirection === 'entering'

  const style: React.CSSProperties = useMemo(() => {
    const scale = interpolate(presentationProgress, [0, 1], [1, zoomScale])
    const phase = interpolate(presentationProgress, [0, 1], [0, Math.PI * 2 * (duration / 30)])
    const shakeEffect = Math.sin(phase) * intensity * (1 - presentationProgress)

    return {
      transform: `translateX(${shakeEffect}px) translateY(${shakeEffect}px) scale(${scale})`,
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      backfaceVisibility: 'hidden',
      opacity: isEntering ? presentationProgress : 1,
      ...(isEntering ? enterStyle : exitStyle),
    }
  }, [presentationProgress, intensity, zoomScale, duration, enterStyle, exitStyle, isEntering])

  return <AbsoluteFill style={style}>{children}</AbsoluteFill>
}

export const shake = (props?: ShakeTransitionProps): TransitionPresentation<ShakeTransitionProps> => {
  return {
    component: ShakePresentation,
    props: props ?? {},
  }
}
