import { NODE_BACKEND_URL } from '../../config'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

export const useGetGettyImages = (query = '', orientation: string, isFirstRequest: boolean, page_size:number, initialPage:number ) => {
  const [page, setPage] = useState(initialPage)
  const { data, error, isLoading, isSuccess } = useQuery(
    ['fetchGettyImageVideos', query, orientation, page_size, page],
    async () => {
      try {
        const res = await axios
          .get(`${NODE_BACKEND_URL}api/v1/gettyImage/imageSearch`, {
            params: {
              searchQuery: query,
              isFirstRequest: isFirstRequest,
              orientations: orientation,
              relatedSearch: true,
              page_size:page_size,
              page:page
            },
          })
          .then((response) => response.data)
          .catch((error) => {
            throw new Error(error)
          })
         
        return res
      } catch (error) {
        console.error(error)
      }
    },
    {
      enabled: !!query,
    }
  )
   // Reset page to initialPage when query changes
   useEffect(() => {
    setPage(initialPage);
  }, [query, orientation, initialPage]);
  return {
    data,
    error,
    isLoading,
    isSuccess,
    page, 
    setPage
  };
}
