import {
  CatchyPath,
  AIDA,
  PS,
  US,
  BAB,
  EMPATH,
  PAPA,
  PAS,
  SEP,
  USP,
  AIDA2,
  AIDA3,
  FAB2,
  FOMO,
  PAS2,
  PAS3
} from '../../../assets';

interface Framework {
  framework: string;
  name: string;
  icon: any;
  tip: string;
  prompt: string;
}
export const FrameworkNames: Framework[] = [
  {
    framework: 'AIDA (Attention, Interest, Desire, Action)',
    name: 'Review Master',
    icon: AIDA2,
    tip: 'Create engaging product reviews that captivate and convert.',
    prompt: `Using the AIDA framework (Attention-Interest-Desire-Action), create a product-review style advertisement script for a [product/service]. 

Highlight its key features and benefits, such as [feature 1], [feature 2], [feature 3], and [feature 4]. 

Emphasize that it is perfect for [target audience] looking to achieve [desired outcome] quickly and effectively.
`,
  },
  {
    framework: 'AIDA (Attention, Interest, Desire, Action)',
    name: 'Speak to Audience',
    icon: AIDA3,
    tip: 'Craft targeted ads that speak directly to your ideal customers.',
    prompt: `Write a targeted advertisement script for a [product/service] using the AIDA (Attention-Interest-Desire-Action) framework, aimed at [specific target audience, e.g., marketing professionals, business owners]. Highlight that [product/service] offers [key features]. Mention benefits like [benefit 1], [benefit 2], and [benefit 3] tailored to the audience's needs. Emphasize how [product/service] makes achieving [desired outcome] easier and more effective.`,
  },
  {
    framework: 'AIDA (Attention, Interest, Desire, Action)',
    name: 'Scroll Stopper',
    icon: AIDA,
    tip: 'Grab attention instantly with high-impact, engaging ads.',
    prompt: `Write an attention-grabbing advertisement script for a [product/service] using the AIDA (Attention-Interest-Desire-Action) framework. 

Start with a strong hook to capture the audience's attention. Highlight that [product/service] offers [key features] and can be used for [specific platforms or tasks]. Mention the benefits like [benefit 1], [benefit 2], and [benefit 3] tailored to the user's needs.

Encourage businesses or individuals looking to improve their [task/process] to check out [product/service].
`,
  },

  {
    framework: 'PAS (Pain, Agitate, Solve)',
    name: 'Fix The Pain',
    icon: PAS,
    tip:
      'Address pain points and present your product as the perfect solution.',
    prompt: `Create a problem-solution style advertisement script using the PAS (Problem-Agitate-Solution) framework for a [product/service].

Start by addressing a common problem faced by [target audience]. Agitate the problem by discussing its negative impact. Introduce [product/service] as the solution, highlighting key features like [feature 1], [feature 2], and [feature 3]. 

Emphasize how it simplifies the [process/task] and drives better results.
`,
  },
  {
    framework: 'FOMO (Fear of missing out)',
    name: `Don't Miss it`,
    icon: FOMO,
    tip: 'Create buzz and urgency with FOMO-driven advertisements.',
    prompt: `Create an advertisement script for a [product/service] using the FOMO (Fear of Missing Out) framework. Highlight that [product/service] is a trending solution revolutionizing [industry/task]. Emphasize the ease and effectiveness of using it, especially for [specific tasks]. Mention that it leverages [key features] to deliver exceptional results. Encourage the audience to check out [product/service] to avoid missing out on [specific benefit or outcome]`,
  },
  {
    framework: '3Bs (3-Benefits Why)',
    name: 'Triple Benefits',
    icon: PAS3,
    tip: 'Highlight the top 3 reasons your audience needs this product',
    prompt: `Write a script using the '3-Benefits Why' framework that highlights three key benefits of using a [product/service]. Mention that it offers [benefit 1], [benefit 2], and [benefit 3]. 

Ensure the benefits are tailored to the needs of [target audience]. 

Encourage the audience to try 
`,
  },

  {
    framework: 'FAB (Features, Advantages, Benefits)',
    name: 'Feature Focus',
    icon: FAB2,
    tip: 'Showcase key features, advantages, and benefits.',
    prompt: '',
  },
  {
    framework: 'USP (Unique Selling Proposition)',
    name: 'Uniquely Yours',
    icon: USP,
    tip: 'Highlight what makes your product stand out.',
    prompt: '',
  },
  {
    framework: 'EMPATH (Engage, Motivate, Promote, Acknowledge, Tailor...)',
    name: 'Empathy Engine',
    icon: EMPATH,
    tip: 'Connect deeply with your audience to inspire action.',
    prompt: '',
  },
  {
    framework: 'SEP (Story, Emotion, Point)',
    name: 'Story Teller',
    icon: SEP,
    tip: 'Engage with compelling stories and emotional appeal',
    prompt: '',
  },
  {
    framework: '4Ps (Picture, Promise, Proof, Push)',
    name: 'Power 4P',
    icon: PS,
    tip: 'Visualize, promise, prove, and persuade in one go.',
    prompt: '',
  },
  {
    framework: 'BAB (Before-After-Bridge)',
    name: 'Transformation Talk',
    icon: BAB,
    tip: 'Show transformation from problem to solution.',
    prompt: '',
  },
  {
    framework: 'PAPA (Problem, Agitate, Persuade, Asks)',
    name: 'Persuasion Pro',
    icon: PAPA,
    tip: 'Identify problems, agitate them, and persuade with a solution',
    prompt: '',
  },
  {
    framework: '4Us (Useful, Urgent, Unique, Ultra-specific)',
    name: '4U Impact',
    icon: US,
    tip: 'Craft ads that are useful, urgent, unique, and specific',
    prompt: '',
  },
  {
    framework: 'EPIC (Educate, Promote, Inform, Convert)',
    name: 'Smart Shift',
    icon: PAS2,
    tip: 'Educate and convert with informative promotion',
    prompt: '',
  },
  // {'framework': 'PSB (Problem-Solution-Benefit)', 'name': 'Fix-it Formula', 'icon':PAS, 'tip':'Create engaging product reviews that captivate and convert'}
];
