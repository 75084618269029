import { useMutation, useQueryClient } from "react-query";
import { AxiosError } from "axios";
import { getProtectedAxios } from "../auth";
import { QA_BACKEND_URL } from "@src/config/config";

async function deleteBrand(assetId: string) {
    const axios = getProtectedAxios();
    const endpoint = `${QA_BACKEND_URL}/asset/${assetId}`;
    return axios.delete(endpoint);
}

export default function useDeleteAsset() {
    const queryClient = useQueryClient();

    const mutation = useMutation(
        (assetId: string) => deleteBrand(assetId),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("get-all-assets");
            },
            onError: (err: AxiosError) => {
                console.error('Error uploading file:', err)
            },
        }
    );

    return {
        mutate: mutation.mutate,
        isLoading: mutation.isLoading,
    };
}
