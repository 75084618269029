import React from 'react';
import { Tabs } from 'antd';
import StoryInput from './story';
import ScriptTab from './script';
import { ScriptContainer } from '../scriptContainer';
interface ScriptContainerProps {
    store: any
    setUpdatedScriptDetails: any
    setRegeneratedScriptDetails: any
    setConfirmLoading: any
    setNewSceneAdded:any
    setStoryChanged: any
    setTabKey: any
    setNewContext: any
    defaultActivetab: any
  }
  const ModalTabs: React.FC<ScriptContainerProps> = ({ 
    store,
  setUpdatedScriptDetails,
  setRegeneratedScriptDetails,
  setConfirmLoading,
  setNewSceneAdded,
  setStoryChanged,
  setTabKey,
  setNewContext,
  defaultActivetab
   }) => {
 
  const onChange = (key: string) => {
    console.log(key);
    setTabKey(key)
  };

  const tabItems = [
    {
      label: 'Story',
      key: '1',
      children: <StoryInput story='passed story' setStoryChanged={setStoryChanged} setNewContext={setNewContext}/>, 
    
    },
    {
      label: 'Story Board',
      key: '2',
      children:  <ScriptContainer
      store={store}
      setUpdatedScriptDetails={setUpdatedScriptDetails}
      setRegeneratedScriptDetails={setRegeneratedScriptDetails}
      setConfirmLoading={setConfirmLoading}
      setNewSceneAdded={setNewSceneAdded}
    />, 
    },
  ];

  return (
    <Tabs onChange={onChange} type="card" items={tabItems} defaultActiveKey={defaultActivetab}/>
  );
};

export default ModalTabs;
