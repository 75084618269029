import { polotnoBackendApiInstance } from '../../config/config'
import axios from 'axios'
import { useQuery } from 'react-query'

// for staff names
export const useGetPolotnoFonts = (key: string) => {
  return useQuery(['GetPolotnoFonts'], async () => {
    const res = await axios.get('https://api.polotno.com/api/get-text-templates', {
      params: {
        KEY: key,
      },
    })
    return res?.data
  })
}
