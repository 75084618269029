import React, { useMemo } from 'react'
import { AbsoluteFill, interpolate } from 'remotion'
import type { TransitionPresentation, TransitionPresentationComponentProps } from '../../types.js'

export type RotateTransitionProps = {
  direction?: 'left-to-right' | 'right-to-left' // Direction can be expanded or used to reverse the rotation
  enterStyle?: React.CSSProperties
  exitStyle?: React.CSSProperties
}

const RotatePresentation: React.FC<TransitionPresentationComponentProps<RotateTransitionProps>> = ({
  children,
  presentationDirection,
  presentationProgress,
  passedProps,
}) => {
  // Determine if the component is entering or exiting
  const isEntering = presentationDirection === 'entering'

  // Calculate rotation angle based on presentation progress
  const angle = useMemo(() => {
    const startRotation = isEntering ? -180 : 0 // Start at -180 for entering, 0 for exiting
    const endRotation = isEntering ? 0 : 180 // End at 0 for entering, 180 for exiting
    return interpolate(presentationProgress, [0, 1], [startRotation, endRotation])
  }, [isEntering, presentationProgress])

  // Apply CSS for rotation combined with enter/exit styles
  const style: React.CSSProperties = useMemo(() => {
    return {
      width: '100%',
      height: '100%',
      transform: `rotate(${angle}deg)`,
      opacity: isEntering ? presentationProgress : 1,
      transformOrigin: 'center',
      backfaceVisibility: 'hidden', // Necessary to prevent flickering and visible back side of the component
      ...(isEntering ? passedProps.enterStyle : passedProps.exitStyle),
    }
  }, [angle, isEntering, passedProps.enterStyle, passedProps.exitStyle, presentationProgress])

  return <AbsoluteFill style={style}>{children}</AbsoluteFill>
}

export const rotateTransition = (props?: RotateTransitionProps): TransitionPresentation<RotateTransitionProps> => {
  return {
    component: RotatePresentation,
    props: props ?? {},
  }
}
