import { qaBackendApiInstance } from '@src/config/config'
import { useQuery } from 'react-query'

export const useGetDomainDetails = () => {
  let domain = window.location.hostname
  domain = domain.replace(/^www\./i, '')
  domain = (domain === 'localhost') ? 'dev-aivideos.quickads.ai' : domain
  // domain = (domain === 'localhost') ? 't3.virtualshoots.ai' : domain
  return useQuery(['get-domain-details'], async () => {
    const response = await qaBackendApiInstance
      .get(`/domain/get-domain-settings?domain=${domain}&panel_type=video`)
      .catch((error) => {
        throw error
      })
    localStorage.setItem('domainId', response?.data?.domain_id)
    return response?.data
  })
}
