/* eslint-disable react/prop-types */
import { burstImageTransitionFunctions } from '../../../../src/data/transitions'
import { VideoPlayer } from '../../../../src/features/video/player/videoPlayer'
import { millisecondsToFrames, prefetchAsset } from '../../../../src/shared/utils/remotion'
import { Alert, Button, Modal } from 'antd'
import { useEffect, useRef, useState } from 'react'
import Draggable from 'react-draggable'
import { useGlobalContext } from '../../../context/globalContext'
import './customPolotno/customActionControlContainer.less'
import { extractPagesFromHtml, addIdsToHtmlFromJson } from '../../../../src/shared/utils/remotion/htmlProcessing'
import { ScriptButton } from './script/newScriptModal'
import { renderThumbnail } from '@src/features/video/player/renderStill'
import {
  LoadingOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';

// a previw modal which plays the video
interface VideoPreviewProps {
  store: any // Ideally, replace `any` with a more specific type
  previewOpen: boolean
  setPreviewOpen: (val: boolean) => void
}

export const PreviewButton: React.FC<VideoPreviewProps> = ({ store, previewOpen, setPreviewOpen }) => {
 
  const draggleRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const {
    TransitionAnimation: [transitionAnimation],
    ScriptDetails: [scriptDetails],
    VideoStyle: [videoStyle],
    ShowTextOverlay: [showTextOverlay, setShowTextOverlay],
    ProgressPercent: [progressBarPercent, setProgressBarPercent],
    PreviewLoader: [isPreviewLoading, setIsPreviewLoading]
  } = useGlobalContext()
  // loading for when gif is rendering
  const [isGifRendering, setIsGifRendering] = useState<boolean>(false)

  // opens up the preview modal
  const showModal = () => {
    setIsGifRendering(true)
    setPreviewOpen(true)
  }

  // closes the preview modal
  const handleOk = () => {
    setPreviewOpen(false)
    setPreviewOpen(false)

    // shows the captions on polotno for user to see where the captions will be placed in the video
    store.history.ignore(async () => {
      store.pages.map((page: any) => {
        if (page?.custom?.showSubtitles === true) {
          const elementID = 'captionText' + page.id
          if (store.getElementById(elementID)) {
            store.getElementById(elementID).set({
              opacity: 1,
            })
          }
        }
      })
    })
  }

  // same concept as handle ok
  const handleCancel = () => {
    setPreviewOpen(false)
    setPreviewOpen(false)
    store.history.ignore(async () => {

      store.pages.map((page: any) => {
        if (page.custom.showSubtitles === true) {
          const elementID = 'captionText' + page.id
          if (store.getElementById(elementID)) {
            store.getElementById(elementID).set({
              opacity: 1,
            })
          }
        }
      })
    })
  }

  const [width, setWidth] = useState<number>(500)
  const [height, setHeight] = useState<number>(600)
  const [duration, setDuration] = useState<number>(1)
  const [htmlCode, setHtmlCode] = useState([])
  const [burstImageAnimations, setBurstImageAnimations] = useState<any>([])

  // below are functions to set the new values
  const changeHtmlCode = (newHtmlCode: any) => {
    setHtmlCode(newHtmlCode)
  }

  const changeDuration = (newDuration: number) => {
    setDuration(newDuration)
  }

  const changeWidth = (newWidth: any) => {
    let adjustedWidth = 704
    if(newWidth == 1280){
      adjustedWidth=704
    }else if(newWidth == 720){
      adjustedWidth=400
    }else if(newWidth == 800){
      adjustedWidth=536
    }else{
      adjustedWidth = 704
    }
 
    setWidth(adjustedWidth)

    // const modalBody = document.querySelector('.ant-modal-body');
    // if (modalBody && modalBody.firstChild) {
    //   const firstChild = modalBody.firstChild as HTMLElement; // Casting to HTMLElement
    //   firstChild.style.width = `${adjustedWidth}px`;
    //   // firstChild.style.height = `${dynamicHeight}px`;
    //   // firstChild.style.backgroundColor = 'lightblue'; // Optional for visibility
    // }
  }

  const changeHeight = (newHeight: any) => {
    setHeight(newHeight)
  }

  // preview modal title
  const modalTitle = (
    <>
    <div
      style={{ width: '100%', cursor: 'move' }}
      className='draggable-modal-title' // This class will be used as a handle
    >
      Video Preview
    </div>
      <Alert 
      message='Any watermark on the video will be removed in the download.' 
      type="info"
      style={{
        fontSize: '12px',
      }}
      />
      </>
  )

  // will open up the modal
  const handlePreviewClick = async () => {
    showModal()
    // await renderThumbnail()
  }

  // a use effect to load all the props that are required for previewing a video
  useEffect(() => {
    // console.log(previewOpen)
    async function prefetchAndRender() {
      setIsGifRendering(true)

      // getting the json data from polotno
      const jsonData = store.toJSON()

      // if the video style is burst we make an array of random transition functions
      const burstImagesTransitionAnimationArray = []

      if (videoStyle === 'Burst') {
        for (let i = 0; i < scriptDetails.data.length; i++) {
          const innerArray = []

          for (let j = 0; j < scriptDetails.data[i].images.length; j++) {
            const randomFunction =
              burstImageTransitionFunctions[Math.floor(Math.random() * burstImageTransitionFunctions.length)]
            // innerArray.push(randomFunction.value)
            innerArray.push(transitionAnimation[0].animation)
          }
          burstImagesTransitionAnimationArray.push(innerArray)
        }

        setBurstImageAnimations(burstImagesTransitionAnimationArray)
      }

      // prefetching all the video or image url in browser to make sure that the video playing is not glitchy
      const promises =  await Promise.all(
        jsonData.pages.map(async (page: any) => {
          // await prefetchAsset(page?.custom?.sounds[0]?.url)
          await prefetchAsset(Array.isArray(page?.custom?.sounds) && page.custom.sounds.length > 0? page.custom.sounds[0].url: undefined)
          // Prefetch for all children in parallel
         await Promise.all(
            page.children.map((child: any) =>
              (child.type === 'video' || child.type === 'image') && child.src
                ? prefetchAsset(child.src)
                : Promise.resolve()
            )
          )
        
        })
      )
      // prefetchAsset(child.src, child.type === 'image' ? 'image/png' : 'video/mp4')
      // console.log(promises)

      // prefetching the background music so it plays without lag
      await prefetchAsset(scriptDetails?.backgroundMusic?.url)
      await prefetchAsset(scriptDetails?.customTransitionSound ||  'https://d2tqfxp1334laf.cloudfront.net/image_projects/da764da2-2982-49be-88bd-eca414c4f5d7/assets/audio/e4cdb276-d1ad-4d81-91ae-3182d55c4099/audio.mp3')
      // converting the duration thats in store which is in miliseconds into duration that remotion can understand. Fps is 30, can be changed in the furture
      changeDuration(millisecondsToFrames(store.duration, 30))

      /* this is done so that the caption text on polotno does not show up in the preview video.
    the text shown on polotno when captions is enabled is only shown visually for the user*/
      store.pages.map((page: any) => {
        const elementID = 'captionText' + page.id
        if (store.getElementById(elementID)) {
          store.getElementById(elementID).set({
            opacity: 0,
          })
        }
      })

      await store.pages.map((page: any) => {
        page.children.map((child:any)=>{
          if(child.name === 'h1-notlinked'){
            const elementID=child.id
            if (store.getElementById(elementID)) {
              store.getElementById(elementID).set({
                visible: showTextOverlay,
                opacity: showTextOverlay ? 1 : 0,
                // custom.custom: 
              })
            }
          }
        })
      })
      // console.log(store.toJSON())
      // getting the html code
      const result = await store.toHTML()
      // console.log(result)
      // converting the html code into different pages so it reflects each page in json
      const htmlCode = extractPagesFromHtml(result)

      // adding tags into that htmlCode array so that we can show animations
      const htmlCodeWithTags = addIdsToHtmlFromJson(htmlCode, jsonData)
      await prefetchAsset(scriptDetails?.customTransitionSoundurl)
      changeHtmlCode(htmlCodeWithTags)
      changeWidth(store.width)
      changeHeight(store.height)
      setIsGifRendering(false)
    }

    if (previewOpen) {
      // calling the function
      prefetchAndRender()
    }
    
  }, [previewOpen])


  return (
    <>
      <Button
        onClick={async () => {
          handlePreviewClick()
        }}
        style={{
          background: 'transparent',
          borderColor: 'var(--color-primary-500)',
          // marginBottom: '10px',
          color: 'var(--color-primary-500)',
        }}
        icon={isPreviewLoading ? <LoadingOutlined /> : <PlayCircleOutlined />}
        disabled={isPreviewLoading}
      >
        Preview
      </Button>
      <Modal
        title={modalTitle}
        open={previewOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={width} // Set the width of the Modal to match the GifPlayer's width
        style={{
          top: '50%',
          transform: 'translateY(-50%)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // maxWidth:width
        }}
        okButtonProps={{ style: { background: 'var(--gradient-primary)' } }}
        footer={(_, {OkBtn, CancelBtn }) => (
          <>
          <ScriptButton store={store} open={open} setOpen={setOpen} setProgressBarPercentage={setProgressBarPercent} defaultActivetab='1' buttonName='Story'/>
          <ScriptButton store={store} open={open} setOpen={setOpen} setProgressBarPercentage={setProgressBarPercent} defaultActivetab='2' buttonName='Quick Video Edit'/>
          <Button
        onClick={() => {
          handleOk()
        }}
        style={{ background: 'transparent', borderColor: 'var(--color-primary-500)', marginBottom: '10px', color: 'var(--color-primary-500)' }}
       
      >
        Advanced Video Editor
      </Button>
           <OkBtn 
          // style={{
          //   background: 'var(--gradient-primary)',
          //   borderColor: 'transparent',
          //   color: 'white',
          //   marginRight: '20px',
          // }}
           
           />
           {/* <CancelBtn /> */}
          </>
         
        )}
        modalRender={(modal) => (
          <Draggable nodeRef={draggleRef} bounds='parent' handle='.draggable-modal-title'>
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
        destroyOnClose={true}
      >
        {isGifRendering ? (
          <div
            // center elements vertically
            style={{
              width: store.width,
              height: store.height,
              display: 'flex',
              justifyContent: 'center',
              justifyItems: 'center',
              textAlign: 'center',
              marginTop: 'auto',
              flexDirection: 'column',
            }}
          >
            <div>
              <img
                src='https://d19ep7nqjvgj4d.cloudfront.net/adgen-fe/ai-loader.gif'
                style={{
                  width: '130px',
                  height: '130px',
                  // margin: 'auto',
                }}
              />
              <p>Loading Preview...</p>
            </div>
          </div>
        ) : (
          <VideoPlayer
            htmlData={htmlCode}
            duration={duration}
            scriptDetails={scriptDetails}
            jsonData={store.toJSON()}
            transitionDurationInFrames={transitionAnimation[0].duration}
            transitionAnimationProp={transitionAnimation[0].animation}
            isDownload={false}
            burstImagesTransitionAnimationArray={burstImageAnimations}
            videoStyle={videoStyle}
          />
        )}
      </Modal>
    </>
  )
}
