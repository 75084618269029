import React, { useMemo } from 'react'
import { AbsoluteFill, interpolate } from 'remotion'
import type { TransitionPresentation, TransitionPresentationComponentProps } from '../../types.js'

export type ClockWiseRotateTransitionProps = {
  direction?: 'clockwise' | 'counterclockwise' // New directions for clockwise/counterclockwise
  enterStyle?: React.CSSProperties
  exitStyle?: React.CSSProperties
  spinTimes?: number
}

const ClockWiseRotatePresentation: React.FC<TransitionPresentationComponentProps<ClockWiseRotateTransitionProps>> = ({
  children,
  presentationDirection,
  presentationProgress,
  passedProps,
}) => {
  const spins = passedProps.spinTimes || 1

  // Calculate rotation angle based on presentation progress
  const angle = useMemo(() => {
    const totalDegrees = 360 * spins // Total degrees to spin
    return interpolate(presentationProgress, [0, 1], [0, totalDegrees])
  }, [presentationProgress, spins])

  // Apply CSS for continuous rotation
  const style: React.CSSProperties = useMemo(() => {
    return {
      width: '100%',
      height: '100%',
      transform: `rotateX(${angle}deg)`,
      transformOrigin: 'center',
      backfaceVisibility: 'hidden', // Necessary to prevent flickering and visible back side of the component
      ...(presentationDirection === 'entering' ? passedProps.enterStyle : passedProps.exitStyle),
    }
  }, [angle, presentationDirection, passedProps.enterStyle, passedProps.exitStyle])

  return <AbsoluteFill style={style}>{children}</AbsoluteFill>
}

export const clockRotation = (
  props?: ClockWiseRotateTransitionProps
): TransitionPresentation<ClockWiseRotateTransitionProps> => {
  return {
    component: ClockWiseRotatePresentation,
    props: props ?? {},
  }
}
