import { IS_FREE_PLATFORM } from '../../../../../src/config'
import { Table, TableProps } from 'antd'
import React, { useState } from 'react'

interface RegeneratedScriptContainerProps {
  store: any
  regeneratedScript: any
}

interface DataType {
  key: number
  adBlockType: string
  onScreenVisual: string
  voiceOver: string
  textOverlay: string
  index: number
}

// this component shows the new script that has been generated in a table form
export const RegneratedScriptContainer: React.FC<RegeneratedScriptContainerProps> = ({ store, regeneratedScript }) => {
  const initialData: DataType[] = regeneratedScript?.data?.map(
    (script: { adBlockType: any; onScreenVisual: any; voiceOver: any; textOverlay: any }, index: number) => ({
      key: index,
      adBlockType: script.adBlockType,
      onScreenVisual: script.onScreenVisual,
      voiceOver: script.voiceOver,
      textOverlay: script.textOverlay,
      index: index,
    })
  )

  const [dataSource, setDataSource] = useState<DataType[]>(initialData)

  const columns: TableProps<DataType>['columns'] = [
   
    {
      title: 'Scenes',
      dataIndex: 'voiceOver',
      width: '100%',
      fixed: 'left',
    },
  ]

  return <Table columns={columns} dataSource={dataSource} pagination={false} scroll={{ x: 1000, y: 500 }} />
}
