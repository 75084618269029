export function toTitleCase(str: string | undefined) {
  if (str && acronyms.includes(str.toLowerCase())) return str.toUpperCase()
  if (str)
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })

  return ''
}

export function toFixedLength(str: string, length = 2) {
  if (!+str) return str

  if (!str.includes('.')) {
    return str
  } else {
    if (parseInt(str.split('.')[1]) == 0) {
      return str.split('.')[0]
    }
  }

  return (+str).toFixed(length)
}

export function toNewFormat(str: string, separator = '_', newSeparator = ' '): string {
  return str.split(separator).join(newSeparator)
}

export function formatPercentage(val: string | number): number {
  const preDecimal = +val.toString().split('.')[0]
  return preDecimal === 100 ? preDecimal : +(+val).toFixed(2)
}

export function titleCase(str: string) {
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(' ')
}

const acronyms = ['uc', 'ucmo', 'aic', 'pid', 'imei', 'kpk']

export function formatAcronym(text: string): string {
  if (!text || !text?.toString || !text?.toString()) return text
  text = text?.toString()

  if (text.split(' ').length > 1) {
    const splitStrings = text.split(' ')
    const transformedStrings = splitStrings.map((str: string) => formatAcronym(str))
    return transformedStrings.join(' ')
  }

  if (acronyms.includes(text.toLowerCase())) return text.toUpperCase()
  return text
}

export const sortStringsArray = (arr: string[]) => arr.sort((a, b) => a?.toString()?.localeCompare(b))

export function fromCamelToTitleCase(str: string | undefined) {
  if (str) {
    return str.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/\b\w/g, (firstLetter) => firstLetter.toUpperCase())
  }

  return ''
}

// "<p><span style=\"color: rgb(255, 0, 0);\">Hello</span> testing</p>"

export function addSpanTagToText(text: string, color: string, highlightTextBackground:string, font:string) {
  return `<span style="color: ${color}; text-transform: uppercase; background:${highlightTextBackground}; border-radius:10px; padding: 10px; font-family:${font}; font-weight:bolder; ">${text.split(' ')[0]}</span> ${text.split(' ').splice(1).join(' ')}`
}
